import gql from 'graphql-tag';

export const SAVE_ROUTE_STOPS = gql`
mutation saveRouteStops($route_stops_id: Int!, $operator_id: Int!) {
        saveRouteStops(route_stops_id: $route_stops_id, operator_id: $operator_id) {
            status
            message
        }
    }
`;

export const DEL_SAVED_STOP = gql`
mutation delSavedStop($route_stops_id: Int!, $operator_id: Int!) {
        delSavedStop(route_stops_id: $route_stops_id, operator_id: $operator_id) {
            status
            message
        }
    }
`;

export const SAVE_USER_TRACK_ROUTE = gql`
    mutation saveUserTrackRoute($routeJsonId: String) {
        saveUserTrackRoute(routeJsonId: $routeJsonId) {
            status
            message
        }
    }
`;

export const DEL_ROUTE_TRACK = gql`
mutation deleteRouteTrack($operator_id: Int!, $route_id: Int!) {
    deleteRouteTrack(operator_id: $operator_id, route_id: $route_id) {
        status
        message
    }
}
`;






