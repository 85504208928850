<template>
    <ion-page>
        <simple-header-toolbar
            :titleName="$t('transactions') + (smartcardNumber ? ' (' + smartcardNumber + ')' : '')"
            :backFunction="goBack"
            ref="headerBar"
        ></simple-header-toolbar>

        <div>
            <ion-grid>
                <ion-row>
                    <ion-col size="6">
                        <ion-label class="text-bold">{{ $t('date-from') }}</ion-label>
                        <div class="date-cont">
                            <input
                                type="date"
                                id="from-picker"
                                name="date-from-picker"
                                :value="dateFrom"
                                min="2019-01-01"
                                class="date-time-input"
                                max="2099-12-31"
                                @change="selectDateFrom($event.target.value)"
                            />
                        </div>
                    </ion-col>
                    <ion-col size="6">
                        <ion-label class="text-bold">{{ $t('date-to') }}</ion-label>
                        <div class="date-cont">
                            <input
                                type="date"
                                id="from-picker"
                                name="date-from-picker"
                                class="date-time-input"
                                :value="dateTo"
                                min="2019-01-01"
                                max="2099-12-31"
                                @change="selectDateTo($event.target.value)"
                            />
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row class="top-space">
                    <ion-col>
                        <div class="balance-cont open">
                            <ion-label class="text-padding text-bold">{{ $t('open-balance') }}</ion-label>
                            <ion-label>{{ initialBalance }}</ion-label>
                        </div>
                    </ion-col>
                    <ion-col>
                        <div class="balance-cont close">
                            <ion-label class="text-padding text-bold">{{ $t('close-balance') }}</ion-label>
                            <ion-label>{{ closingBalance }}</ion-label>
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row class="no-zoom-el top-space">
                    <ion-col size="4">
                        <ion-label class="text-bold">{{ $t('date-time') }}</ion-label>
                    </ion-col>
                    <ion-col size="5">
                        <ion-label class="text-bold">{{ $t('des') }}</ion-label>
                    </ion-col>
                    <ion-col size="3" class="align-end">
                        <ion-label class="text-bold">{{ $t('amount') }}</ion-label>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <ion-content>
            <ion-list>
                <div
                    v-for="trans in transactions"
                    v-bind:key="trans.id"
                    class="list-item"
                    @click="openTransactionNote(trans)"
                >
                    <transaction-type-payment
                        v-if="trans.transaction_type == TransactionTypeApi.PAYMENT"
                        :transaction="trans"
                    ></transaction-type-payment>
                    <transaction-type-deduction
                        v-else-if="trans.transaction_type == TransactionTypeApi.DEDUCTION"
                        :transaction="trans"
                    ></transaction-type-deduction>
                    <transaction-type-manual
                        v-else-if="trans.transaction_type == TransactionTypeApi.MANUAL_CREDIT || trans.transaction_type == TransactionTypeApi.MANUAL_DEBIT"
                        :transaction="trans"
                    ></transaction-type-manual>
                    <transaction-type v-else :transaction="trans"></transaction-type>
                </div>
            </ion-list>

            <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="transactions.length > 0">
                <ion-fab-button @click="download">
                    <ion-icon :src="downloadIcon" class="icon-outline" aria-label="download smartcard transactions"></ion-icon>
                </ion-fab-button>
            </ion-fab>
        </ion-content>
    </ion-page>
</template>

<script>
import SimpleHeaderToolbar from '../../components/toolbar/SimpleHeaderToolbar'
import { Capacitor } from '@capacitor/core'
import { FileOpener } from '@ionic-native/file-opener'
import { TransactionTypeApi, TransactionTypeLayOut } from '../../util/constant'
import TransactionTypePayment from '../../components/transaction-types-row/TransactionTypePayment'
import TransactionTypeDeduction from '../../components/transaction-types-row/TransactionTypeDeduction'
import TransactionTypeManual from '../../components/transaction-types-row/TransactionTypeManual'
import TransactionType from '../../components/transaction-types-row/TransactionType'
import moment from 'moment'
import _ from 'lodash'
import smartcardApi from '../../util/apis/smartcard'
import momentTimezone from 'moment-timezone'
import appService from '../../util/services/appService'
import useNavigator from '../../composables/useNavigator'
import { informationCircleOutline } from 'ionicons/icons'
import { Filesystem, Directory } from '@capacitor/filesystem'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
    name: 'SmartCardTransactions',
    components: {
        SimpleHeaderToolbar,
        TransactionTypePayment,
        TransactionTypeDeduction,
        TransactionTypeManual,
        TransactionType,
    },
    ionViewDidEnter: function() {
      this.$refs.headerBar.setDefaultFocus()
    },  
    data: function () {
        return {
            TransactionTypeApi,
            TransactionTypeLayOut,
            informationCircleOutline: informationCircleOutline,
            routeImg: require('../../assets/location_arrow.png'),
            boardingImg: require('../../assets/bus_stop.png'),
            deboardingImg: require('../../assets/bus_ac_wc.png'),
            transactionImg: require('../../assets/imoney-bag-48.png'),
            downloadIcon: require('../../assets/download-outline.svg'),
            transactions: [],
            NullConstant: 'N/A',
            dateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            dateTo: moment().format('YYYY-MM-DD'),
            pdfObj: null,
        }
    },
    mounted: function () {
        this.timezone = this.$route.params.timezone
        this.getTransactions()
    },
    computed: {
        initialBalance: function () {
            return this.transactions.length > 0
                ? this.transactions[this.transactions.length - 1].initial_balance
                : this.NullConstant
        },
        closingBalance: function () {
            return this.transactions.length > 0 ? this.transactions[0].ending_balance : this.NullConstant
        },
        smartcardNumber() {
            return this.$route.params.cardNumber
        },
        smartcardPassenger() {
            return this.$route.params.passenger
        },
    },
    methods: {
        async openTransactionNote(trans) {
            if (trans.transaction_note) {
                let note = trans.transaction_note
                if (trans.route_name) {
                    note = `<b>Note:</b> ${note}<br><b>Route:</b> ${trans.route_name}`
                }
                if (trans.boarding_name) {
                    note = `${note}<br><b>Boarding Stop:</b> ${trans.boarding_name}`
                }
                if (trans.deboarding_name) {
                    note = `${note}<br><b>Deboarding Stop:</b> ${trans.deboarding_name}`
                }

                const transactionDatetime = this.getDateTime(trans.transaction_date)
                await appService.showAlert(note, `${transactionDatetime}`, 'text-left')
            }
        },
        goBack() {
            useNavigator.goToSmartcard()
        },
        async validateSelectedDate(from, to) {
            const momentTo = moment(to)
            const momentFrom = moment(from)
            const diffFromTo = momentTo.diff(momentFrom, 'months', true)
            if (diffFromTo > 3) {
                await appService.showAlert('Please select Maximum 3 months', 'Invalid timeframe')
                return false
            }
            if (momentTo.isBefore(momentFrom)) {
                await appService.showAlert('Date From must be less than or equal to Date To', 'Invalid timeframe')
                return false
            }
            return true
        },
        async getTransactions() {
            try {
                console.log(this.dateFrom, this.dateTo)
                if (!(await this.validateSelectedDate(this.dateFrom, this.dateTo))) {
                    return
                }

                await appService.presentLoading()
                const args = {
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                    cardNumber: this.smartcardNumber,
                }
                const transactionRes = await smartcardApi.getSmartCardTransaction(args)
                if (transactionRes && transactionRes.data) {
                    this.transactions = _.reverse(transactionRes.data.smartCardTransactions)

                    this.transactions.forEach((item) => {
                        item.displayDate = this.getDate(
                            item.boarding_time ? item.boarding_time : item.transaction_date,
                            'DD MMM YY'
                        )
                        item.displayTime = this.getHour(item.boarding_time ? item.boarding_time : item.transaction_date)
                        item.displayDeboardingTime = item.deboarding_time ? this.getHour(item.deboarding_time) : ''
                        item.displayAmount = this.getAmount(item.transaction_type, item.transaction_amount)
                        item.transactionTime = this.getHour(item.transaction_date)
                    })
                }
            } finally {
                await appService.dismissLoading()
            }
        },
        getDateTime(dateTime) {
            return dateTime ? momentTimezone(dateTime).tz(this.timezone).format('DD/MM/YY hh:mm a') : this.NullConstant
        },
        getDate(dateTime, formatString) {
            return dateTime ? momentTimezone(dateTime).tz(this.timezone).format(formatString) : this.NullConstant
        },
        getDateLabel(dateTime, formatString) {
            return dateTime ? momentTimezone(dateTime).tz(this.timezone).format(formatString) : this.NullConstant
        },
        getHour(dateTime) {
            return dateTime ? momentTimezone(dateTime).tz(this.timezone).format('hh:mm a') : this.NullConstant
        },
        getAmount(tranType, amount) {
            const plus = '+ $'
            const minus = '- $'
            if (
                tranType === this.TransactionTypeApi.PAYMENT ||
                tranType === this.TransactionTypeApi.DEDUCTION ||
                tranType === this.TransactionTypeApi.TRANSFER_FROM ||
                tranType === this.TransactionTypeApi.MANUAL_DEBIT
            ) {
                return minus + amount
            } else {
                return plus + amount
            }
        },
        selectDateFrom(date) {
            const newDate = date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            if (newDate != this.dateFrom) {
                this.dateFrom = newDate
                this.$nextTick(() => {
                    this.getTransactions()
                })
            }
        },
        selectDateTo(date) {
            const newDate = date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            if (newDate != this.dateTo) {
                this.dateTo = newDate
                this.$nextTick(() => {
                    this.getTransactions()
                })
            }
        },
        download() {
            const data = this.transactions.map((trans) => {
                let result
                if (trans.transaction_type == TransactionTypeApi.PAYMENT) {
                    result = [
                        `${trans.displayDate}\n\n-> ${trans.displayTime}\n<- ${trans.displayDeboardingTime}`,
                        `TRAVEL PAYMENT\nRoute: ${trans.route_name}\nBoarding Stop: ${trans.boarding_name}\nDeboarding Stop: ${trans.deboarding_name}`,
                        trans.displayAmount,
                        trans.ending_balance,
                    ]
                } else if (trans.transaction_type == TransactionTypeApi.DEDUCTION) {
                    result = [
                        `${trans.displayDate}\n\n-> ${trans.displayTime}\n<- ${trans.transactionTime}`,
                        `MAX FARE DEDUCTION\nRoute: ${trans.route_name}\nBoarding Stop: ${trans.boarding_name}\nNo Tap Off`,
                        trans.displayAmount,
                        trans.ending_balance,
                    ]
                } else {
                    result = [
                        `${trans.displayDate}\n${trans.displayTime}`,
                        `${TransactionTypeLayOut[trans.transaction_type]}`,
                        trans.displayAmount,
                        trans.ending_balance,
                    ]
                }
                return result
            })
            const header = [
                { text: 'DateTime', style: 'tableHeader' },
                { text: 'Description', style: 'tableHeader' },
                { text: 'Amount', style: 'tableHeader' },
                { text: 'Balance', style: 'tableHeader' },
            ]

            const docDefinition = {
                content: [
                    {
                        text: 'Smartcard Transactions',
                        style: 'subheader',
                    },
                    {
                        style: 'tableExample',
                        table: {
                            headerRows: 1,
                            widths: [250, 250],
                            body: [
                                [
                                    { text: 'Passenger', style: 'tableHeader' },
                                    { text: 'Card number', style: 'tableHeader' },
                                ],
                                [this.smartcardPassenger, this.smartcardNumber],
                            ],
                        },
                        layout: 'noBorders',
                    },
                    {
                        style: 'tableExample',
                        table: {
                            headerRows: 1,
                            widths: [250, 250],
                            body: [
                                [
                                    { text: 'From', style: 'tableHeader' },
                                    { text: 'To', style: 'tableHeader' },
                                ],
                                [this.getDate(this.dateFrom, 'DD/MM/YYYY'), this.getDate(this.dateTo, 'DD/MM/YYYY')],
                            ],
                        },
                        layout: 'noBorders',
                    },
                    {
                        style: 'tableExample',
                        table: {
                            widths: [250, 250],
                            headerRows: 1,
                            body: [
                                [
                                    { text: 'Opening Balance', style: 'tableHeader' },
                                    { text: 'Closing Balance', style: 'tableHeader' },
                                ],
                                [this.initialBalance, this.closingBalance],
                            ],
                        },
                        layout: 'noBorders',
                    },
                    {
                        style: 'tableExample',
                        table: {
                            widths: [100, 160, 80, 80],
                            heights: 20,
                            headerRows: 1,
                            body: [[...header], ...data],
                        },
                    },
                ],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5],
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15],
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        height: 20,
                        color: 'black',
                    },
                },
            }
            console.log(docDefinition)
            this.pdfObj = pdfMake.createPdf(docDefinition)
            const plt = Capacitor.getPlatform()
            if (plt == 'web') {
                this.pdfObj.download()
            } else {
                this.pdfObj.getBase64(async (data) => {
                    try {
                        let path = `pdf/transaction_${Date.now()}.pdf`
                        const result = await Filesystem.writeFile({
                            path,
                            data: data,
                            directory: Directory.Documents,
                            recursive: true,
                        })
                        FileOpener.open(`${result.uri}`, 'application/pdf')
                    } catch (e) {
                        console.log('Unable to write file', e)
                    }
                })
            }
        },
    },
}
</script>

<style scoped>
.col-row-cont {
    display: flex;
    flex-direction: row;
}
.date-cont {
    border: 1px solid #dfdfdf;
    align-self: center;
    text-align: center;
    padding: 0 12px;
    border-radius: 4px;
    margin-top: 10px;
}
.balance-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    width: 100%;
    color: white;
}
.list-item {
    border-bottom: 1px solid #dfdfdf;
}
.align-end {
   text-align: right;
}
.align-center {
    justify-content: center;
    display: flex;
}
ion-img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.balance-cont.open {
    background-color: var(--ion-color-tertiary-tint);
}
.balance-cont.close {
    background-color: var(--ion-color-danger-tint);
}
.text-padding {
    margin-bottom: 10px;
}
.icon-desc-info {
    min-width: 20px;
}
.icon-info {
    font-size: 20px;
    margin-right: 5px;
}
.icon-outline {
    font-size: 24px;
    color: white;
}
.date-time-input {
    padding: 12px;
    background-color: transparent;
    border: none;
}
.top-space {
    margin-top: 10px;
}
</style>
