import apolloClient from "../../apolloClient";
import {AUTO_TOP_UP, DEL_AUTO_TOP_UP, DEL_SMARTCARD_AUTO_CONFIG } from "../../graphql/mutations/smartcard";
import { TOGGLE_AUTO_TOPUP, TURN_OFF_ALL_CARD_AUTO_TOPUP, TURN_ON_ALL_CARD_AUTO_TOPUP, ENABLE_CARD_AUTO_TOPUP_CONFIG } from "../../graphql/mutations/autoTopup";

class AutoTopupApi {

    constructor() { }
    
    deleteSmartcardAutoTopUpConfig(user_consumer_id) {
        return apolloClient.mutate({
            mutation: DEL_SMARTCARD_AUTO_CONFIG,
            variables: {
                user_consumer_id: user_consumer_id
            }
        });
    }

    enableCardAutoTopupConfig(args) {
        return apolloClient.mutate({
            mutation: ENABLE_CARD_AUTO_TOPUP_CONFIG,
            fetchPolicy: "no-cache",
            variables: {
                user_consumer_id: args.user_consumer_id
            }
        });
    }

    saveAutoTopUpSmartcard(config) {
        console.log('###autoTopUpAllSmartcard', config);
        return apolloClient.mutate({
            mutation: AUTO_TOP_UP,
            variables: {
                amount: parseFloat(config.amount),
                is_all: config.is_all,
                trigger_balance: parseFloat(config.trigger_balance),
                user_consumer_id: config.is_all ? undefined : config.user_consumer_id,
            }
        });
    }

    turnOnAllCardAutoTopup() {
        return apolloClient.mutate({
            mutation: TURN_ON_ALL_CARD_AUTO_TOPUP,
        });
    }
 

    turnOffAllCardAutoTopup() {
        return apolloClient.mutate({
            mutation: TURN_OFF_ALL_CARD_AUTO_TOPUP,
        });
    }

    delAutoTopUpSmartcard() {
        return apolloClient.mutate({
            mutation: DEL_AUTO_TOP_UP,
        });
    }

    toggleAutoTopup(params) {
        return apolloClient.mutate({
            mutation: TOGGLE_AUTO_TOPUP,
            fetchPolicy: "no-cache",
            variables: {
                is_all: params.is_all,
                isAutoTopup: params.isAutoTopup
            }
        });
    }
    
}


const srv = new AutoTopupApi();
export default srv;