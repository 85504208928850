<template>
    <ion-page>
        <div class="header-container">
            <ion-label class="modal-title">Reset Apple Sign In</ion-label>
        </div>
        <ion-content class="ion-padding">
            <ion-grid>
                <ion-row v-if="!showInstructionOnly">
                    <ion-col>
                        <ion-text class="text-body2-style">
                            You are using Apple Sign In and unfortunately we are unable to save your contact email address.<br><br>Please follow this instruction to verify your email address:
                        </ion-text>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-text class="text-body2-style"> 1. Reset Apple ID for TransportMe </ion-text>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-text class="text-body2-style">
                            <ul class="note">
                                <li>Open <b>iOS Settings</b> > <b>Apple ID</b> > <b>Password &amp; Security</b></li>
                                <li>Select <b>Apps using Apple ID</b> > <b>TransportMe</b></li>
                                <li>Tap <b>Stop using Apple ID</b></li>
                            </ul>
                        </ion-text>
                    </ion-col>
                </ion-row>

                <ion-row>
                    <ion-col>
                        <ion-text class="text-body2-style">
                            2. <b>Restart</b> TransportMe app and <b>Sign In with Apple</b>
                        </ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-button expand="block" color="primary" class="ion-margin-top" @click="close">Ok</ion-button>
        </ion-content>
    </ion-page>
</template>

<script>
import { modalController } from '@ionic/vue'
import authSrv from '@/util/services/authService'
export default {
    name: 'AppleLoginMessageModal',
    props: {
        showInstructionOnly: Boolean,
    },
    data: function () {
        return {}
    },
    methods: {
        async close() {
            modalController.dismiss()
            const isLoggedIn = await authSrv.hasCurrentLoggedInUser()
            if (isLoggedIn) {
                authSrv.signOut()
            }
        },
    },
}
</script>
<style scoped>
.no-scroll {
    --overflow: hidden;
}
ion-button::part(native) {
    color: white;
}
.note {
    margin-top: 0px;
    margin-bottom: 0px;
}
.modal-title {
    color: white;
}
</style>
