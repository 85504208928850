import apolloClient from "../../apolloClient";
import { SAVE_ROUTE_FAVORITE, DEL_ROUTE_FAVORITE, DEL_USER_FAV, NOTI_FROM_FAV_OPERATOR,UPDATE_OPERATOR_FAVORITE, SAVE_USER_PRIVACY } from "../../graphql/mutations/user";
import { COUNT_USER_CHECKED_PRIVACY } from "../../graphql/queries/user"
// import store from "../../store";

class FavApi {
    constructor() { }
    // queries 
    
    // mutations
    delAllUserFav() {
        return apolloClient.mutate({
            mutation: DEL_USER_FAV,
            fetchPolicy: "no-cache",
            variables: {
            }
        });
    }

    deleteRouteFavorite(params) {
        return apolloClient.mutate({
            mutation: DEL_ROUTE_FAVORITE,
            fetchPolicy: "no-cache",
            variables: {
                ...params
            }
        });
    }

    // saveOperatorFavorite(params) {
    //     return apolloClient.mutate({
    //         mutation: SAVE_OPERATOR_FAVORITE,
    //         fetchPolicy: "no-cache",
    //         variables: {
    //             data: {
    //                 ...params
    //             }
    //         }
    //     });
    // }

    saveRouteFavorite(params) {
        return apolloClient.mutate({
            mutation: SAVE_ROUTE_FAVORITE,
            fetchPolicy: "no-cache",
            variables: {
                ...params
            }
        });
    }

    notificationFromFav(params) {
        return apolloClient.mutate({
            mutation: NOTI_FROM_FAV_OPERATOR,
            fetchPolicy: "no-cache",
            variables: {
                notification_from_fav: params.notification_from_fav
            }
        });
    }

    updateOperatorFavorite(operator_id) {
        return apolloClient.mutate({
            mutation: UPDATE_OPERATOR_FAVORITE,
            fetchPolicy: "no-cache",
            variables: {
                operator_id
            }
        });
    }

    countUserCheckedPrivacy(operator_id) {
        return apolloClient.query({
            query: COUNT_USER_CHECKED_PRIVACY,
            fetchPolicy: "no-cache",
            variables: {
                operator_id: operator_id
            }
        });
    }

    saveUserPrivacy(operator_id) {
        return apolloClient.mutate({
            mutation: SAVE_USER_PRIVACY,
            fetchPolicy: "no-cache",
            variables: {
                operator_id: operator_id
            }
        });
    }
}

const srv = new FavApi();
export default srv;
