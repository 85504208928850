import authApi from '../../util/apis/auth'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import localStorageSrv from '../services/storageService'
import appSrv from './appService'
import alertSrv from './alertService'
import notificationSrv from './notificationService'
import { messaging } from '../../firebase'

class FcmService {
    constructor() {}

    initPush() {
        if (Capacitor.getPlatform() !== 'web') {
            try {
                if (this.registering) {
                    return
                }
                this.registering = true
                //console.log('========> Addding listener')
                PushNotifications.removeAllListeners()
                PushNotifications.addListener('registration', async fbToken => {
                    console.log('My token: ' + JSON.stringify(fbToken))
                    // get and save apns token

                    const storedDeviceFbToken = await localStorageSrv.getItem('deviceFbToken')
                    // get and save fb token
                    if (fbToken && fbToken.value !== storedDeviceFbToken) {
                        //console.log("===========> updateDeviceInfo from Push Registration", fbToken, storedDeviceFbToken)
                        //console.trace()
                        await authApi.updateDeviceInfo(fbToken.value)
                        await localStorageSrv.setItem('deviceFbToken', fbToken.value)
                    }
                })

                PushNotifications.addListener('registrationError', error => {
                    console.log('Error: ' + JSON.stringify(error))
                })

                PushNotifications.addListener('pushNotificationReceived', async notification => {
                    if (Capacitor.getPlatform() == 'android') {
                        if (notification.data && notification.data.data) {
                            const payLoad = JSON.parse(notification.data.data)
                            const role = await alertSrv.toastNotification(
                                `${notification.title} <br>${notification.body}`
                            )
                            if (role && role == 'cancel') {
                                notificationSrv.onNotificationTapped(payLoad)
                            }
                        } else {
                            alertSrv.toastNotification(`${notification.title} <br>${notification.body}`)
                        }
                    }
                })

                PushNotifications.addListener('pushNotificationActionPerformed', async notification => {
                    // move to smartcard track when action performed with card number
                    console.log('Action performed: ' + JSON.stringify(notification.notification))
                    const actionPerformed = notification.notification
                    if (actionPerformed.data && actionPerformed.data.data) {
                        const payLoad = JSON.parse(actionPerformed.data.data)
                        notificationSrv.onNotificationTapped(payLoad)
                    }
                })
            } finally {
                this.registering = false
            }
            this.registerPush()
        }
        
        if (Capacitor.getPlatform() == 'web') {
            const vapidKey = process.env.VUE_APP_FIREBASE_VAPID_KEY
            messaging.getToken({
                vapidKey,
            })
                .then(async (currentToken) => {
                    if (currentToken) {
                        console.log('client token', currentToken)
                        await authApi.updateDeviceInfo(currentToken)
                        await localStorageSrv.setItem('deviceFbToken', currentToken)
                    } else {
                        console.log('No registration token available. Request permission to generate one.')
                    }
                })
                .catch((err) => {
                    console.log('An error occurred while retrieving token. ', err)
                })

            messaging.onMessage(messaging, (data) => {
                console.log('Message received. ', data);
                alertSrv.toastNotification(`${data.notification.title} <br>${data.notification.body}`)
            })
        }
    }

    async registerPush(showInstruction) {
        if (Capacitor.getPlatform() == 'web') {
            return true
        }

        return PushNotifications.requestPermissions().then(async permission => {
            if (permission.receive == 'granted') {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register()
                return true
            } else {
                // No permission for push granted
                if (showInstruction) {
                    // show mess
                    await appSrv.presentAlert({
                        header: 'Notification disabled',
                        message: 'Please enable notification from your phone Settings to use this feature.',
                        buttons: [
                            {
                                text: 'OK',
                                role: 'ok',
                            },
                        ],
                    })
                }
                return false
            }
        })
    }

    async isNotificationEnable() {
        if (Capacitor.getPlatform() == 'web') {
            return true
        }
        return (await PushNotifications.checkPermissions()).receive == 'granted'
    }
}

const srv = new FcmService()
export default srv
