<template>
    <ion-card button @click="goToAddSmartCard">
        <ion-card-content class="card-content">
            <ion-icon :icon="add"></ion-icon>
            <ion-label class="text-headline6-style label-color">{{ $t('add-first-smartcard') }}</ion-label>
        </ion-card-content>
    </ion-card>
</template>
<script>
import { add } from 'ionicons/icons'
import { Capacitor } from '@capacitor/core'
export default {
    name: 'AddSmartCard',
    data: function () {
        return {
            add,
            platform: Capacitor.getPlatform(),
        }
    },
    computed: {
        isWebPlatform() {
            return this.platform == 'web'
        },
    },
    methods: {
        goToAddSmartCard() {
            const localUser = this.$store.getters.user
            const defaultOperatorId = (localUser && localUser.fav_operator_id)? localUser.fav_operator_id: null;
            this.$store.dispatch('setCompanyXId', defaultOperatorId)

            if (this.isWebPlatform) {
                this.$router.push({
                    name: 'smartCardAddNew',
                    params: {
                        openMode: 'normal',
                        qrText: null,
                    }
                })
            } else {
                this.$router.push({ name: 'smartCardLink' })
            }
        },
    },
}
</script>
<style scoped>
.card-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100px;
}

ion-icon {
    font-size: 25px;
    color: var(--ion-color-tertiary);
    margin-right: 15px;
}
.label-color {
    color: #1073ba;
}
</style>
