<template>
    <div class="operator-container" @click="openFilterList(list, selectedOption)" role="button" :aria-label="'selected operator:' + selectedPlaceholder">
         <ion-label
                id="operator-label"
                :class="
                    platform && platform === 'ios'
                        ? 'ion-text-capitalize label-absolute-top'
                        : 'ion-text-capitalize label-android-absolute-top'
                "
                position="floating"
                >{{ $t('operator') }}</ion-label
            >
        <ion-text>{{ selectedPlaceholder }}</ion-text>
        <ion-icon aria-hidden="true" class="down-icon" :src="chevronDownOutline"></ion-icon>
    </div>
</template>

<script>
import FilterListModal from '../../views/modal/FilterListModal'
import { Capacitor } from '@capacitor/core'
import { modalController } from '@ionic/vue'
import { chevronDownOutline } from 'ionicons/icons'
import helperSrv from '../../util/helper'

export default {
    name: 'ButtonSingleSelect',
    props: {
        list: Array,
        title: String,
        selectItem: Function,
        selectedItem: [String, Number],
        defaultPlaceholder: String,
        description: String,
    },
    data: function () {
        return {
            selectedOption: '',
            chevronDownOutline,
        }
    },
    mounted: function () {
        this.$nextTick(() => {
            this.selectedOption = this.selectedItem
            // when reopen the screen the selected option will be reseted to fav operator
            // So have to reset companyXId in store to fav operator
            if (this.selectedItem !== this.$store.getters.companyXId) {
                this.selectItem(this.selectedItem)
            }
        })
    },
    computed: {
        selectedPlaceholder() {
            const operator = this.list.find((item) => item.value == this.selectedOption)
            return operator ? operator.name : this.defaultPlaceholder
        },
        platform() {
            return Capacitor.getPlatform()
        },
    },
    methods: {
        modalCloseHandler(selectedData) {
            this.selectedOption = selectedData.value
            this.selectItem(selectedData.value)
        },
        async openFilterList(dataList, selectedItem) {
            let modal = await modalController.create({
                component: FilterListModal,
                cssClass: 'filter-list-modal',
                componentProps: {
                    dataList,
                    selectedItem,
                },
            })

            // Disable screen reader access previous page
            helperSrv.setElementAttribute("qrCode-header", "aria-hidden", true);            

            // show the modal
            await modal.present()

            // wait to see if i get a response
            let modalResponse = await modal.onDidDismiss()

            // Enable screen reader again
            helperSrv.removeElementAttribute("qrCode-header", "aria-hidden");

            // when dismissed by clicking outside of modal,
            // data is undefined so we do not handle it
            modalResponse.data && this.modalCloseHandler({ ...modalResponse.data })
        },
    },
}
</script>
<style scoped>
.operator-container {
    display: flex;
    flex-direction: row;
    height: 60px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #dfdfdf;
    justify-content: flex-start;
    align-items: center;
    padding: 0 12px;
    margin-top: 30px;
    cursor: pointer;
}
.down-icon {
    font-size: 24px;
    position: absolute;
    top: 20px;
    right: 12px;
}
</style>