<template>
    <div class="psw-rule">
        <p>
            Password must contain the following:
            <ul>
                <li> a lowercase letter</li> 
                <li> a capital (uppercase) letter</li>
                <li> a number</li> 
                <li> minimum 8 characters</li>
            </ul>
            
        </p>
    </div>
</template>

<script>
export default {
    name: 'PasswordRule',
    data: function () {
        return {}
    },
}
</script>
<style scoped>
.psw-rule {
    text-align: left;
}
ul {
    margin: 0;
    padding-left: 20px;
}
</style>
