import gql from 'graphql-tag'

export const ADD_USER_AUTHENTICATION = gql`
    mutation createPaxUser($data: PaxUserInput!, $provider: String!) {
        createPaxUser(data: $data, provider: $provider) {
            id
            email
            auto_topup
            notification_from_cards
            all_card_send_notification
            all_card_auto_topup
            fav_operator_id
            favorite_routes {
                id
                operator_id
                route_id
            }
            is_premium
            email_verify
            notification_from_fav_operator
            user_hash_id
        }
    }
`

export const UPDATE_OPERATOR_FAVORITE = gql`
    mutation updateOperatorFav($operator_id: Int!) {
        updateOperatorFav(operator_id: $operator_id) {
            status
            message
        }
    }
`

export const GET_USER_CONSUMER_MIGRATE = gql`
    mutation getUserConsumerMigrate($companyName: String!, $consumerToken: String!) {
        getUserConsumerMigrate(data: $data) {
            id
        }
    }
`

export const REMOVE_USER_DEVICE = gql`
    mutation removeUserDevice($deviceId: String!) {
        removeUserDevice(deviceId: $deviceId) {
            status
            message
        }
    }
`

export const DELETE_ACCOUNT = gql`
    mutation deleteAccount {
        deleteAccount {
            status
            message
        }
    }
`

export const UPDATE_USER_MIGRATE = gql`
    mutation updateUserMigrate(
        $deviceId: String!
        $rootCardToken: String
        $rootCardCompanyId: String
        $userData: PaxUserInput!
        $newDeviceId: String!
    ) {
        updateUserMigrate(
            deviceId: $deviceId
            rootCardToken: $rootCardToken
            rootCardCompanyId: $rootCardCompanyId
            userData: $userData
            newDeviceId: $newDeviceId
        ) {
            pax_user {
                id
                email
                auto_topup
                notification_from_cards
                all_card_send_notification
                all_card_auto_topup
                fav_operator_id
                favorite_routes {
                    id
                    route_id
                    operator_id
                }
                is_premium
                email_verify
                notification_from_fav_operator
                user_hash_id
            }
        }
    }
`

export const SAVE_USER_PRIVACY = gql`
    mutation saveUserPrivacy($operator_id: Int!) {
        saveUserPrivacy(operator_id: $operator_id) {
            status
            message
        }
    }
`

export const CREATE_USER_PURCHASES = gql`
    mutation createUserPurchases($data: String!) {
        createUserPurchases(data: $data) {
            status
            message
        }
    }
`

export const VERIFY_USER_PERCHASE = gql`
    mutation verifyUserPurchase {
        verifyUserPurchase {
            status
            message
        }
    }
`

export const SAVE_ROUTE_FAVORITE = gql`
    mutation saveUserRouteFav($operator_id: Int!, $route_id: Int!) {
        saveUserRouteFav(operator_id: $operator_id, route_id: $route_id) {
            id
            operator_id
            route_id
            user_id
        }
    }
`

export const DEL_ROUTE_FAVORITE = gql`
    mutation deleteUserRouteFav($operator_id: Int!, $route_id: Int!) {
        deleteUserRouteFav(operator_id: $operator_id, route_id: $route_id) {
            message
            status
        }
    }
`

export const UPDATE_DEVICE_INFO = gql`
    mutation updateDeviceInfo($data: UserDeviceInput!) {
        updateDeviceInfo(data: $data) {
            message
            status
        }
    }
`

export const DEL_USER_FAV = gql`
    mutation delAllUserFav {
        delAllUserFav {
            message
            status
        }
    }
`

export const NOTI_FROM_FAV_OPERATOR = gql`
    mutation notificationFromFav($notification_from_fav: Boolean!) {
        notificationFromFav(notification_from_fav: $notification_from_fav) {
            message
            status
        }
    }
`

export const EMAIL_VERIFY = gql`
    mutation emailVerified {
        emailVerified {
            message
            status
        }
    }
`

export const UPDATE_FB_USER_EMAIL = gql`
    mutation updateFbUserEmail {
        updateFbUserEmail {
            message
            status
        }
    }
`
