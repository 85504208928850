<template>
    <div>
        <accordion ref="accordionCard">
            <accordion-item v-for="card in cards" v-bind:key="card.id" class="accordion-container" :isShowIndicatorBtn="!!card.auto_topup_config">
                <template v-slot:accordion-trigger>
                    <ion-grid :class="platform && platform === 'web' ? 'grid-container-web' : 'grid-container'">
                        <ion-row>
                            <ion-col size="10">
                                <div class="col-cont">
                                    <ion-label class="text-overline-style header-color">{{
                                        card.card_number
                                    }}</ion-label>
                                    <ion-label class="text-headline6-style header-color">{{
                                        card.consumer ? card.consumer.cardholder_name : ''
                                    }}</ion-label>
                                </div>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <div class="col-cont">
                                    <ion-label class="text-body2-style header-color" v-if="card.auto_topup_config">
                                        <span class="text-balance">Current balance:</span> ${{ parseFloat(card.balance).toFixed(2) }}
                                    </ion-label>
                                    <ion-label class="text-body2-style header-color" v-if="card.auto_topup_config">
                                        <span class="text-balance">Trigger balance:</span> ${{ parseFloat(card.auto_topup_config.trigger_balance).toFixed(2) }}
                                    </ion-label>
                                    <ion-label class="text-body2-style header-color" v-if="card.auto_topup_config">
                                        <span class="text-balance">Top-up amount:</span> ${{ parseFloat(card.auto_topup_config.amount).toFixed(2) }}
                                    </ion-label>
                                </div>
                            </ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center ion-justify-content-end">
                            <ion-col size="12" class="toggle-container">
                                <ion-text class="text-body2-style ion-text-capitalize toggle-text">{{
                                    $t('auto-top-up-string')
                                }}</ion-text>
                                <ion-toggle
                                    mode="md"
                                    class="top-up-toggle"
                                    :checked="card.auto_topup_config !== null"
                                    @ionChange="onToggle($event, card)"
                                ></ion-toggle>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </template>

                <template v-slot:accordion-content v-if="card.auto_topup_config !== null">
                    <smart-card-top-up-mode
                        :cardNumber="card.card_number"
                        :save="saveConfig"
                        :card="card"
                    ></smart-card-top-up-mode>
                </template>
            </accordion-item>
        </accordion>
    </div>
</template>
<script>
import { chevronDownOutline } from 'ionicons/icons'
import { Capacitor } from '@capacitor/core'
import Accordion from '../../components/accordion/Accordion'
import AccordionItem from '../../components/accordion/AccordionItem'
import SmartCardTopUpMode from '../../views/SmartCard/SmartCardTopUpMode'
import autoTopupApi from '../../util/apis/autoTopup'

export default {
    name: 'SmartcardListSimple',
    props: {
        cards: Array,
        showLoading: Function,
        hideLoading: Function,
    },
    components: {
        Accordion,
        AccordionItem,
        SmartCardTopUpMode,
    },
    data() {
        return {
            pressedCancelBtn: false,
            chevronDownOutline,
            platform: Capacitor.getPlatform(),
        }
    },
    methods: {
        saveConfig(config) {
            this.$emit('save-config', config)
        },
        async onToggle(event, card) {
            this.showLoading()
            if (event) {
                event.preventDefault()
                event.stopPropagation()
            }
            if (this.pressedCancelBtn) {
                this.pressedCancelBtn = false
                this.hideLoading()
                return
            }
            if (!event.target.checked) {
                await autoTopupApi.deleteSmartcardAutoTopUpConfig(card.user_consumer_id)
                this.$store.dispatch('delCardAutoTopupConfig', card.user_consumer_id)
            } else {
                await this.enableConfig(card)
            }
            this.hideLoading()
        },
        async enableConfig(card) {
            const configRes = await autoTopupApi.enableCardAutoTopupConfig({
                user_consumer_id: card.user_consumer_id,
            })
            configRes.data.enableCardAutoTopupConfig.user_consumer_id = card.user_consumer_id
            this.$store.dispatch('setAutoTopupConfig', configRes.data.enableCardAutoTopupConfig)
        },
    },
}
</script>

<style scoped>
.grid-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    min-height: 129px;
}
.grid-container-web {
    display: flex;
    padding: 20px;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    min-height: 129px;
}
.top-up-toggle {
    margin-left: 6px;
}
.card-img {
    width: 40px;
    height: 42px;
}
.accordion-container {
    padding: 0px;
    margin: 24px 0px;
    border: var(--ion-color-light) 1px solid;
    min-height: 129px;
}
.ellip-cont {
    position: 'relative';
}
.car-btn {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    color: var(--ion-color-sm-card-btn);
}
.col-cont {
    display: flex;
    flex-direction: column;
}
.menu-select-cont {
    position: 'absolute';
    bottom: 1px;
    right: 1px;
}
.header-color {
    color: var(--ion-color-text-normal-dark);
}
.down-icon-col {
    text-align: right;
    padding-right: 16px;
}
ion-icon {
    font-size: 24px;
}
.toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.text-balance {
    display: inline-block;
    min-width: 150px;
}

ion-toggle {
    opacity: 1;
}
</style>
