<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-icon name="chevron-back-outline" class="arrow-back-icon" @click="goBack"></ion-icon>
                </ion-buttons>
                <ion-title class="ion-text-capitalize no-zoom-el">{{ $t('tme-premium') }}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-progress-bar type="indeterminate" v-if="loading"></ion-progress-bar>
        <ion-content v-if="isNativePlatform">
            <premium-package :packages="packages" :onPurchased="onPurchased"></premium-package>
        </ion-content>
    </ion-page>
</template>
<script>
import { Purchases } from '@ionic-native/purchases'
import PremiumPackage from './PremiumPackage'
import { modalController } from '@ionic/vue'
import localStorageSrv from '@/util/services/storageService'
import appService from '@/util/services/appService'
import { Capacitor } from '@capacitor/core'

export default {
    name: 'Premium',
    components: {
        PremiumPackage,
    },
    computed: {
        isNativePlatform() {
            return this.$store.getters.platform !== 'web'
        },
    },
    data: function() {
        return {
            packages: null,
            loading: false,
        }
    },
    mounted: async function() {
        this.openLoading()
        localStorageSrv.setItem('openedPremiumDialog', 'true')
        appService.setOpeningPopupStatus(true)
        if (Capacitor.getPlatform() == 'web') {
            this.offLoading()
            return
        }
        const offerings = await Purchases.getOfferings()
        console.log('###PremiumPackage', offerings)
        if (offerings.current !== null && offerings.current.availablePackages.length !== 0) {
            console.log('###CurrentPremiumPackage', offerings.current.availablePackages)
            this.packages = offerings.current.availablePackages
        }
        this.offLoading()
    },
    async beforeUnmount() {
        appService.setOpeningPopupStatus(false)
    },
    methods: {
        openLoading() {
            this.loading = true
        },
        offLoading() {
            this.loading = false
        },
        goBack() {
            modalController.dismiss()
        },
        onPurchased() {
            modalController.dismiss({ purchased: true })
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ion-title {
    padding-bottom: 6px;
}
</style>
