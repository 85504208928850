<template>
    <ion-page>
        <simple-header-toolbar :titleName="openMode && openMode =='replace' ? 'Replace Smartcard' : 'Link Smartcard'" ref="headerBar" :backFunction="goBack"></simple-header-toolbar>
        <ion-content class="ion-padding">
            <ion-button
                v-if="platform && (platform == 'android' || platform == 'ios')"
                ref="loginBtn"
                @click="openSmartcardQrCodeScan"
                expand="block"
                class="ion-text-capitalize ion-margin-top link-qr-btn"
                >{{ $t('link-btn-text') }} <ion-icon class="bold-style icon-ac-route" :src="qrcodeIcon"></ion-icon
            ></ion-button>
            <ion-button
                ref="cardUidBtn"
                expand="block"
                @click="openUseCardUidScreen"
                class="ion-text-capitalize ion-margin-top add-btn use-uid"
                >{{ $t('use-card-uid') }}</ion-button
            >
            <ion-button
                v-if="openMode !== 'replace'"
                ref="loginBtn"
                expand="block"
                @click="openLinkSmartcardAccountModal('normal', null)"
                class="ion-text-capitalize ion-margin-top add-btn"
                >{{ $t('link-smartcard') }}</ion-button
            >
        </ion-content>
    </ion-page>
</template>

<script>
import SimpleHeaderToolbar from '../../components/toolbar/SimpleHeaderToolbar'
import { Capacitor } from '@capacitor/core'
import smartcardApi from '../../util/apis/smartcard'
import appService from '../../util/services/appService'
import { chevronDownOutline } from 'ionicons/icons'
import useNavigator from '../../composables/useNavigator'
import qrScanService from '../../util/services/qrScanService'
import smartcardService from '../../util/services/smartcardService'

export default {
    name: 'smartCardLink',
    props: {
        openMode: String,
        replaceCard: String,
    },
    components: {
        SimpleHeaderToolbar,
    },
    data: function () {
        return {
            form: {
                userName: '',
                password: '',
            },
            smartcardLogo: require('../../assets/logo.png'),
            qrcodeIcon: require('../../assets/qrcode-icon.svg'),
            operator: '',
            chevronDownOutline,
            scanSub: null,
            qrText: '',
        }
    },
    computed: {
        platform() {
            return Capacitor.getPlatform()
        },
    },
    mounted: function () {
    },
    ionViewDidEnter: function() {
        this.$refs.headerBar.setDefaultFocus()
    },
    methods: {
        goBack() {
            useNavigator.goToSmartcard()
        },
        async goToForgetPassword() {
            try {
                const companyXId = this.$store.getters.companyXId
                const userName = this.form.userName
                if (!companyXId || !userName) {
                    await appService.presentAlert({
                        header: 'Link Smartcard',
                        message: 'Username cannot be empty.',
                    })
                } else {
                    const checkUserNameRes = await smartcardApi.checkSmartcardUserName({
                        user_name: userName,
                    })
                    if (checkUserNameRes && checkUserNameRes.data && checkUserNameRes.data.checkSmartcardUsername > 0) {
                        // after check valid user name send verify code to consumer email
                        const sendVerifyCode = await smartcardApi.sendVerifyCode({
                            user_name: userName,
                        })
                        if (sendVerifyCode.data.sendVerifyCode.status === 'Success') {
                            this.$router.replace({
                                name: 'smartcardForgetPassword',
                                params: { user_name: userName },
                            })
                        } else {
                            await appService.presentAlert({
                                header: 'Link Smartcard',
                                message: sendVerifyCode.data.sendVerifyCode.message,
                            })
                        }
                    } else {
                        await appService.presentAlert({
                            header: 'Link Smartcard',
                            message: 'Invalid username.',
                        })
                    }
                }
            } catch (err) {
                await appService.presentAlert({
                    header: 'Link Smartcard',
                    message: err.message,
                })
            }
        },
        async authenticationForm(form) {
            if (!form.userName || !form.password) {
                await appService.presentAlert({
                    header: 'Link Smartcard',
                    message: 'Please enter smartcard password.',
                })
                return false
            } else {
                return true
            }
        },
        setDefaultOperator() {
            const localUser = this.$store.getters.user
            const defaultOperatorId = (localUser && localUser.fav_operator_id)? localUser.fav_operator_id: null;
            this.$store.dispatch('setCompanyXId', defaultOperatorId)
        },
        openLinkSmartcardAccountModal(mode, qrCodeScanText) {
            this.setDefaultOperator()
            this.$router.replace({
                name: 'smartCardAddNew',
                params: {
                    openMode: mode,
                    qrText: qrCodeScanText,
                },
            })
        },
        openUseCardUidScreen() {
            let params = {}
            if (this.openMode && this.openMode == 'replace') {
                params = {
                    openMode: 'replace',
                    replaceCard: this.replaceCard,
                }
            } else {
                this.setDefaultOperator()
            }
            this.$router.replace({
                name: 'smartCardUseUid',
                params,
            })
        },
        replaceSelectedCard(cardUid) {
            const card = JSON.parse(this.replaceCard)
            smartcardService.replaceCard(cardUid, card)
        },
        openSmartcardQrCodeScan() {
            if (this.openMode && this.openMode == 'replace') {
                qrScanService.start(this.replaceSelectedCard, 'replace')
            } else {
                this.setDefaultOperator()
                qrScanService.start(smartcardService.linkSmartcard)
            }
        },
    },
}
</script>
<style scoped>
.item-container {
    border: 1px solid #dfdfdf;
    position: relative;
    padding: 0 12px;
    margin-top: 24px;
    border-radius: 4px;
}
.operator-container {
    display: flex;
    flex-direction: row;
    height: 60px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #dfdfdf;
    justify-content: flex-start;
    align-items: center;
    padding: 0 12px;
    margin-top: 40px;
}
.icon-ac-route {
    padding-left: 12px;
    width: 30px;
    height: 30px;
}
.note-text {
    margin-top: 16px;
}
.main-logo {
    margin-top: 40px;
}
.add-btn {
    color: white;
    --border-radius: 4px;
    font-weight: bold;
    margin-top: 20px;
}
.use-uid {
    --background: var(--ion-color-tertiary);
    --background-activated: var(--ion-color-tertiary);
}
.link-qr-btn {
    --background: var(--ion-color-secondary);
    --border-radius: 4px;
    --background-activated: var(--ion-color-secondary);
    color: white;
    font-weight: bold;
    margin-top: 40px;
}
.forget-text {
    font-family: Verdana;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.15px;
    color: #000000;
    text-align: right;
}

input {
    height: 60px;
    border: none;
    width: 100%;
    outline: none;
}
.down-icon {
    font-size: 24px;
    position: absolute;
    top: 20px;
    right: 12px;
}
</style>
