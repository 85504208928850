<template>
    <div class="container">
        <ion-grid :class="platform && platform === 'web' ? 'grid-container-web' : ''">
            <p class="full-border-top"></p>
            <ion-row>
                <ion-text class="text-headline6-style ion-text-capitalize title-color">{{
                    $t('notification-option')
                }}</ion-text>
            </ion-row>

            <ion-row>
                <ion-col size="9">
                    <ion-text class="text-body2-style ion-text-capitalize">{{ $t('noti-tap-card') }}</ion-text>
                </ion-col>
                <ion-col class="toggle-container" size="3">
                    <ion-toggle
                        mode="md"
                        :checked="computedNotificationOpt ? computedNotificationOpt.notify_on_tapped : false"
                        @ionChange="toggleOption($event.target.checked, 'notify_on_tapped')"
                    ></ion-toggle>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col size="9">
                    <ion-text class="text-body2-style ion-text-capitalize">{{ $t('noti-not-tap-card') }}</ion-text>
                </ion-col>
                <ion-col class="toggle-container" size="3">
                    <ion-toggle
                        mode="md"
                        :checked="computedNotificationOpt ? computedNotificationOpt.notify_on_not_tapped : false"
                        @ionChange="toggleOption($event.target.checked, 'notify_on_not_tapped')"
                    ></ion-toggle>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col size="9">
                    <ion-text class="text-body2-style ion-text-capitalize">{{ $t('noti-tap-unapproved') }}</ion-text>
                </ion-col>
                <ion-col class="toggle-container" size="3">
                    <ion-toggle
                        mode="md"
                        :checked="computedNotificationOpt ? computedNotificationOpt.notify_on_wrong_stop : false"
                        @ionChange="toggleOption($event.target.checked, 'notify_on_wrong_stop')"
                    ></ion-toggle>
                </ion-col>
            </ion-row>
        </ion-grid>
        <ion-grid :class="platform && platform === 'web' ? 'grid-container-web' : ''">
            <p class="full-border-top"></p>
            <ion-row>
                <ion-text class="text-headline6-style ion-text-capitalize title-color ion-margin-left">{{
                    $t('notification-timeframe')
                }}</ion-text>
            </ion-row>
            <notification-timeframes
                ref="weekdaysTimeframe"
                :options="computedWeekDaysTimeframe"
                highlightTitle
            ></notification-timeframes>
            <notification-timeframes
                ref="allDayTimeframes"
                :options="computedTimeframe"
                v-if="!computedIsWeekDays"
            ></notification-timeframes>
        </ion-grid>

        <div class="btn-container">
            <ion-button fill="clear" @click="saveBtn" aria-label="save notification setting" class="btn-action">{{ $t('save') }}</ion-button>
            <ion-button fill="clear" @click="cancelBtn" aria-label="cancel" class="btn-action">{{ $t('cancel') }}</ion-button>
        </div>
        <p class="full-border-top"></p>
    </div>
</template>
<script>
import _ from 'lodash'
import { Capacitor } from '@capacitor/core'
import NotificationTimeframes from '../../components/smartcard/SmartcardNotificationTimeframes'
import notificationApi from '../../util/apis/notification'
import { mapState } from 'vuex'
import helperSrv from '../../util/helper'
import appService from '../../util/services/appService'
import alertSrv from '../../util/services/alertService'
import { TimeframeOptionsConstant, WeekDaysOptions } from '../../util/constant'
import { notificationOptType } from '../../util/constant'

// set touch-action: 'none' to ion-picker to fixed up warning scroll view
export default {
    name: 'SmartcardNotificationSetting',
    components: {
        NotificationTimeframes,
    },
    props: {
        showLoading: Function,
        hideLoading: Function,
        card: Object,
    },
    data: function() {
        return {
            isWeekDays: false,
            pressToggle: false,
            weekdaysOptions: _.cloneDeep(WeekDaysOptions),
            cloneWeekdaysOptions: null,
            cloneTimeframeOptions: null,
            timeframeOptions: _.cloneDeep(TimeframeOptionsConstant),
            platform: Capacitor.getPlatform(),
        }
    },
    computed: {
        ...mapState({
            stateCards: state => state.smartcards,
            user: state => state.user,
        }),
        computedIsWeekDays() {
            if (
                this.weekdaysOptions['weekdays'] &&
                (this.weekdaysOptions['weekdays'].type.pm.checked || this.weekdaysOptions['weekdays'].type.am.checked)
            ) {
                return true
            } else {
                return false
            }
        },
        computedNotificationOpt() {
            return this.card.notification_options
        },

        computedWeekDaysTimeframe() {
            return this.getTimeframeDataLayouts(this.weekdaysOptions)
        },
        computedTimeframe() {
            return this.getTimeframeDataLayouts(this.timeframeOptions)
        },
    },
    created() {
        this.cloneWeekdaysOptions = _.cloneDeep(this.weekdaysOptions)
        this.cloneTimeframeOptions = _.cloneDeep(this.timeframeOptions)
        this.cloneIsWeekDays = _.cloneDeep(this.isWeekDays)
    },
    methods: {
        getTimeframeDataLayouts(timeframes) {
            return this.bindingDataToNotificationTimeframes(this.card.notification_timeframes, timeframes)
        },
        bindingDataToNotificationTimeframes(cardTimeframes, timeframesOpts) {
            let timeFrames = timeframesOpts
            if (cardTimeframes && cardTimeframes.length > 0) {
                cardTimeframes.forEach(time => {
                    const timeObj = timeFrames[helperSrv.getDayOfWeekString(parseInt(time.day_of_week))]
                    if (timeObj) {
                        const timeValue = timeObj.type[time.clock_type]
                        timeValue.checked = true
                        timeValue.disabled = false
                        timeValue.from = this.getTimeFormatFromServe(time.from)
                        timeValue.to = this.getTimeFormatFromServe(time.to)
                    }
                })
            }
            return timeFrames
        },
        getTimeFormatFromServe(time) {
            return time
        },
        bindingDataToNotificationOptions(cardOptions, options) {
            if (cardOptions) {
                options[0].checked = cardOptions.notify_on_tapped
                options[1].checked = cardOptions.notify_on_not_tapped
                options[2].checked = cardOptions.notify_on_wrong_stop
            }

            return options
        },
        onWeekDaysChange(value) {
            this.weekdaysOptions = value
        },
        onTimeOtherChange(value) {
            this.timeframeOptions = value
        },
        cancelBtn() {
            this.weekdaysOptions = _.cloneDeep(this.cloneWeekdaysOptions)
            this.timeframeOptions = _.cloneDeep(this.cloneTimeframeOptions)
        },
        getDataFromChildComponent() {
            let dataOptions
            if (this.computedIsWeekDays) {
                dataOptions = _.cloneDeep(this.$refs.weekdaysTimeframe.initOptions)
                this.cloneWeekdaysOptions = dataOptions
            } else {
                dataOptions = _.cloneDeep(this.$refs.allDayTimeframes.initOptions)
                this.cloneTimeframeOptions = dataOptions
            }
            return dataOptions
        },
        checkValidFromToPeriodTime(time, header) {
            console.log('checking time', time)
            const isValidTime = timeString => {
                return timeString.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)
            }

            if (!isValidTime(time.from)  ||
                !isValidTime(time.to) ||
                (time.type === 'am' && (time.from >= '12:00' || time.to >= '12:00')) ||
                (time.type === 'pm' && (time.from < '12:00' || time.to < '12:00' || time.from >= '24:00' || time.to >= '24:00'))) {
                appService.presentAlert({
                    header: header,
                    message: `Please enter valid time from 00:00 to 11:59 for AM and from 12:00 to 23:59 for PM`,
                    buttons: ['OK'],
                })
                return false
            }

            if ( time.from >= time.to) {
                appService.presentAlert({
                    header: header,
                    message: 'Starting Time must be less than Ending Time!',
                    buttons: ['OK'],
                })
                return false
            }
            return true
        },
        checkValidPeriodTime(time, session, dayOfWeek) {
            const capitaliseHeader = (sentence) => {
                const words = sentence.split(" ");

                for (let i = 0; i < words.length; i++) {
                    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
                }
                return words.join(" ")
            }

            const header = capitaliseHeader(`Invalid settings for ${dayOfWeek}`) + ' ' + session.toUpperCase()
            if (!time.from) {
                appService.presentAlert({
                    header: header,
                    message: `Please enter starting time!`,
                    buttons: ['OK'],
                })
                return false
            }

            if (!time.to) {
                appService.presentAlert({
                    header: header,
                    message: `Please enter ending time!`,
                    buttons: ['OK'],
                })
                return false
            }
            return this.checkValidFromToPeriodTime(time, header)
        },
        verifyData(timeframes) {
            if (timeframes['weekdays']) {
                const weekDays = timeframes['weekdays']
                if (
                    (weekDays.type.am.checked && !this.checkValidPeriodTime(weekDays.type.am, 'am', 'weekdays')) ||
                    (weekDays.type.pm.checked && !this.checkValidPeriodTime(weekDays.type.pm, 'pm', 'weekdays'))
                ) {
                    return false
                }

                return true
            } else {
                for (const dayOfWeek in timeframes) {
                    const dayTime = timeframes[dayOfWeek]

                    if (
                        (dayTime.type.am.checked && !this.checkValidPeriodTime(dayTime.type.am, 'am', dayOfWeek)) ||
                        (dayTime.type.pm.checked && !this.checkValidPeriodTime(dayTime.type.pm, 'pm', dayOfWeek))
                    ) {
                        return false
                    }
                }
                return true
            }
        },

        async saveBtn() {
            const timeframeDatas = this.getDataFromChildComponent()
            if (!this.verifyData(timeframeDatas)) {
                return
            }
            try {
                this.showLoading()
                if (this.user && this.user.all_card_send_notification && this.user.notification_from_cards) {
                    await this.saveAllCardTimeframes(timeframeDatas)
                } else {
                    await this.saveTimeframes(timeframeDatas)
                }
                alertSrv.toastSuccess('Saved')
            } finally {
                this.hideLoading()
            }
        },
        async saveAllCardTimeframes(options) {
            try {
                this.showLoading()
                let params
                if (options['weekdays']) {
                    params = this.getWeekdaysParams(options)
                } else {
                    params = this.getTimeParams(options)
                }
                await notificationApi.saveNotificationTimeframe({
                    data: params,
                })

                this.$store.dispatch('setAllCardTimeframes', params)
            } finally {
                this.hideLoading()
            }
        },
        async saveTimeframes(options) {
            try {
                this.showLoading()
                let params
                if (this.isWeekDays) {
                    params = this.getWeekdaysParams(options)
                } else {
                    params = this.getTimeParams(options)
                }

                const userConsumerId = this.card.user_consumer_id

                const timeframesChanged = await notificationApi.saveNotificationTimeframe({
                    data: params,
                    user_consumer_id: userConsumerId,
                })
                if (timeframesChanged.data.saveNotificationTimeframe.status === 'Success') {
                    let payload = {
                        ...params,
                        user_consumer_id: userConsumerId,
                    }
                    this.$store.dispatch('setTimeframesNoti', payload)
                }
                this.hideLoading()
            } catch (error) {
                console.log('saveTimeframes error', error.message)
                this.hideLoading()
                throw error.message
            }
        },
        getWeekdaysParams(options) {
            const params = []
            const day_of_week_string = 'weekdays'
            const timeframeTypes = options[day_of_week_string].type

            for (var clockType in timeframeTypes) {
                if (
                    timeframeTypes[clockType].checked === true &&
                    timeframeTypes[clockType].from &&
                    timeframeTypes[clockType].to
                ) {
                    const param = {
                        clock_type: clockType,
                        from: timeframeTypes[clockType].from,
                        to: timeframeTypes[clockType].to,
                        day_of_week: helperSrv.getDayOfWeekNumber(day_of_week_string),
                    }
                    params.push(param)
                }
            }
            return params
        },
        getTimeParams(options) {
            const params = []
            for (var day_of_week_string in options) {
                const timeframeTypes = options[day_of_week_string].type
                for (var clockType in timeframeTypes) {
                    if (
                        timeframeTypes[clockType].checked === true &&
                        timeframeTypes[clockType].from &&
                        timeframeTypes[clockType].to
                    ) {
                        const param = {
                            clock_type: clockType,
                            from: timeframeTypes[clockType].from,
                            to: timeframeTypes[clockType].to,
                            day_of_week: helperSrv.getDayOfWeekNumber(day_of_week_string),
                        }
                        params.push(param)
                    }
                }
            }
            return params
        },
        async tapCardOption(checked, optionId) {
            this.showLoading()
            try {
                await this.saveNotificationOpt(checked, optionId)
            } finally {
                this.hideLoading()
            }
        },
        async saveNotificationOpt(checked, optionId) {
            if (!this.user.all_card_send_notification) {
                this.computedNotificationOpt.user_consumer_id = this.card.user_consumer_id
            }
            this.computedNotificationOpt[optionId] = checked
            const saveRes = await notificationApi.saveNotificationOption({
                ...this.computedNotificationOpt,
            })
            if (saveRes && saveRes.data && saveRes.data.saveNotificationOption.status === 'success') {
                this.saveNotificationOptToVuex()
            }
        },
        getNotificationOptParams(state, optionId) {
            let params = {
                notify_on_tapped: this.computedOptions[0].checked,
                notify_on_not_tapped: this.computedOptions[1].checked,
                notify_on_wrong_stop: this.computedOptions[2].checked,
            }
            let selectParams = optionId => {
                if (optionId === notificationOptType.ON_TAPPED) {
                    params.notify_on_tapped = state
                }
                if (optionId === notificationOptType.ON_NOT_TAPPED) {
                    params.notify_on_not_tapped = state
                }
                if (optionId === notificationOptType.ON_WRONG_STOP) {
                    params.notify_on_wrong_stop = state
                }
            }
            if (this.user.all_card_send_notification) {
                selectParams(optionId)
            } else {
                selectParams(optionId)
                params.user_consumer_id = this.card.user_consumer_id
            }

            return params
        },
        saveNotificationOptToVuex() {
            if (this.user.all_card_send_notification) {
                this.$store.dispatch('setAllCardNotificationOptions', this.computedNotificationOpt)
            } else {
                this.computedNotificationOpt.user_consumer_id = this.card.user_consumer_id
                this.$store.dispatch('setNotificationOptions', this.computedNotificationOpt)
            }
        },
        async toggleOption(checked, optionId) {
            // note: click event opposite checked value with onChange events
            await this.tapCardOption(checked, optionId)
        },
    },
}
</script>

<style scoped>
.full-border-top {
    border-top: var(--ion-color-sm-card-btn) 1px solid;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
}
.title-color {
    color: var(--ion-color-tertiary);
    margin-left: 5px;
}
.container {
    padding: 8px;
}
.grid-container-web {
    padding: 20px;
}
.container-web {
    padding: 20px;
}
.group-content {
    position: relative;
}
.time-grid-ctn {
    margin-top: 24px;
}
.input-container {
    display: flex;
    flex-direction: row;
    border: var(--ion-color-medium-tint) 1px solid;
    font-size: 16px;
    font-weight: normal;
    align-items: center;
    justify-content: center;
    line-height: 24px;
    padding: 0 8px;
    height: 35px;
    width: 100%;
    color: var(--ion-color-primary-contrast);
    border-radius: 8px;
}
.time-content {
    border: black 1px solid;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.input-container input {
    margin-left: 8px;
    border: none;
    width: 100%;
    outline: none;
}
.btn-action {
    color: var(--ion-color-sm-card-btn);
    border: var(--ion-color-tertiary) 1px solid;
    border-radius: 4px;
    max-height: 36px;
    width: 100%;
}
.btn-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}
ion-radio {
    border: var(--ion-color-grey) 2px solid;
    width: 24px;
    height: 24px;
}
ion-datetime {
    padding: 0;
}
.toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
</style>
