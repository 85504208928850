import firebase from "firebase/app"
import { Capacitor } from '@capacitor/core'
import "firebase/auth"
import "firebase/messaging"

const config = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)

firebase.initializeApp(config)

const auth = firebase.auth()

let messaging = null
if (Capacitor.getPlatform() == 'web' && firebase.messaging.isSupported()) {
    messaging = firebase.messaging()
}

// export utils/refs
export { auth, messaging }
