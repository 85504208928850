import gql from 'graphql-tag'

export const TOGGLE_AUTO_TOPUP = gql`
mutation toggleAutoTopup ($is_all: Boolean!, $isAutoTopup: Boolean!){   
    toggleAutoTopup (is_all: $is_all, isAutoTopup: $isAutoTopup) {
      status
      message
    }
}`;

export const TURN_OFF_ALL_CARD_AUTO_TOPUP = gql`
mutation turnOffAllCardTopUpConfig {   
    turnOffAllCardTopUpConfig {
        id
        all_card_auto_topup
        auto_topup
    }
}`;

export const TURN_ON_ALL_CARD_AUTO_TOPUP = gql`
mutation turnOnAllCardTopUpConfig {   
    turnOnAllCardTopUpConfig {
        id
        amount
        trigger_balance
        card_number,
        user_consumer_id
    }
}`;

export const ENABLE_CARD_AUTO_TOPUP_CONFIG = gql`
mutation enableCardAutoTopupConfig($user_consumer_id: Int!) {   
    enableCardAutoTopupConfig(user_consumer_id: $user_consumer_id) {
        id
        amount
        trigger_balance
        card_number
    }
}`;

