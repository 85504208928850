import { Storage } from '@capacitor/storage';
class LocalStorageSrv {
    constructor() { }

    async setObject(key, value) {
        await Storage.set({
            key,
            value: JSON.stringify(value)
        });
    }


    async getObject(keyName) {
        const ret = await Storage.get({ key: keyName });
        if (ret) {
            return JSON.parse(ret.value);
        }
        return null
    }

    async setItem(key, value) {
        await Storage.set({
            key,
            value
        });
    }

    async getItem(key) {
        const { value } = await Storage.get({ key });
        return value;
    }

    async removeItem(key) {
        await Storage.remove({ key });
    }

    async keys() {
        const { keys } = await Storage.keys();
        console.log('Got keys: ', keys);
    }

    async clear() {
        await Storage.clear();
    }

    async setLastUpdatedAppInfo(info) {
        this.setObject('LastUpdatedAppInfo', info)
    }
    async getLastUpdatedAppInfo() {
        return this.getObject('LastUpdatedAppInfo')
    }
}

const srv = new LocalStorageSrv();
export default srv