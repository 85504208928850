function initFeaturesForTMR() {
    return {
        socialAuth: false,
        subscription: false,
        smartcardQrCode: true,
    }
}

function initConfigsForTMR() {
    return {
        linkToTermsPrivacy:
            'https://www.qld.gov.au/transport/public/school/school-transport-assistance/travel-to-school/terms-and-privacy',
    }
}

function initForTMR() {
    return {
        features: initFeaturesForTMR(),
        configs: initConfigsForTMR(),
    }
}

function initFeaturesForTME() {
    return {
        socialAuth: true,
        subscription: true,
        smartcardQrCode: true,
        replaceSmartcard: false,
    }
}

function initConfigsForTME() {
    return {
        linkToTermsPrivacy: 'https://transportme.com.au/security/'
    }
}

function initForTME() {
    return {
        features: initFeaturesForTME(),
        configs: initConfigsForTME(),
    }
}

function initAppSettings() {
    switch(process.env.VUE_APP_TARGET) {
        case 'tmr':
            return initForTMR()
        case 'tme':
            return initForTME()
        default:
            throw new Error('Unsupported target')
    }
}

class AppVariantService {
    constructor() {
        this.appSettings = initAppSettings()
    }

    isFeatureEnabled(feature) {
        if (this.appSettings.features[feature] === undefined) {
            return true // enable feature if not defined
        }
        return this.appSettings.features[feature]
    }

    getTermsPrivacyLink() {
        return this.appSettings.configs.linkToTermsPrivacy
    }
}

const srv = new AppVariantService()
export default srv