<template>
    <!-- Listen to before and after tab change events -->
    <ion-page>
        <ion-tabs ref="tabs">
            <ion-tab-bar slot="bottom" v-if="!isKeyboarShowing">
                <ion-tab-button ref="tabCardList" tab="smartCardList" aria-labelledby="lblCardList"
                    :disabled="shouldDisableTab" @click="goToCardList()" :href="listHref">
                    <ion-icon :icon="listCircle"></ion-icon>
                    <ion-label id="lblCardList">{{ $t('card-list') }}</ion-label>
                </ion-tab-button>

                <ion-tab-button ref="tabAutoTopup" tab="smartCardAutoTopUp" aria-labelledby="lblAutoTopup"
                    :disabled="shouldDisableTab" @click="goToTopUp()" :href="autoTopupHref">
                    <ion-icon :icon="logoUsd"></ion-icon>
                    <ion-label id="lblAutoTopup">{{ $t('auto-top-up') }}</ion-label>
                </ion-tab-button>

                <ion-tab-button
                    ref="tabNotification"
                    tab="smartCardNotification"
                    :disabled="shouldDisableTab"
                    @click="goToNotification()"
                    :href="computedPremiumHref"
                    aria-labelledby="lblNotification"
                >
                    <div class="tab-icon">
                        <ion-icon :icon="notifications"></ion-icon>
                        <ion-img class="premium-icon" :src="premiumIcon" v-if="!isPremium()"></ion-img>
                    </div>
                    <ion-label id="lblNotification" class="noti-label">{{ $t('notification') }}</ion-label>
                </ion-tab-button>

                <ion-tab-button 
                    ref="tabHelp"
                    tab="Help" @click="openBeacon"
                    aria-labelledby="lblHelp">
                    <ion-icon :icon="helpCircle" class="icon-help"></ion-icon>
                    <ion-label id="lblHelp">Help</ion-label>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
    </ion-page>
</template>

<script>
import { home, listCircle, notifications, logoUsd, helpCircle } from 'ionicons/icons'
import useNavigator from '@/composables/useNavigator'
import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import appService from '../../util/services/appService'
import wcagService from '../../util/services/wcagService'

export default {
    name: 'SmartCard',
    data: function() {
        return {
            helpCircle,
            premiumIcon: require('../../assets/premium-icon.svg'),
            autoTopupIcon: require('../../assets/auto-topup-icon.svg'),
            home,
            listCircle,
            isKeyboarShowing: false,
            notifications,
            logoUsd,
            smartcardNotificationHref: '/smartcard/smartCardNotification',
            premiumHref: '/main/settings/premium',
            homeHref: '/main/home',
            listHref: '/smartcard/smartCardList',
            autoTopupHref: '/smartcard/smartCardAutoTopUp',
            backBtnEventHandler: null
        }
    },
    mounted: async function() {
        setTimeout(() => {
            if (this.$refs.tabCardList.$el) {
                wcagService.handleTabButton(this.$refs.tabCardList.$el)
                wcagService.handleTabButton(this.$refs.tabAutoTopup.$el)
                wcagService.handleTabButton(this.$refs.tabNotification.$el)
                wcagService.handleTabButton(this.$refs.tabHelp.$el)
            }
        }, 1000)

        if (Capacitor.getPlatform() == 'web') {
            return
        }
        Keyboard.addListener('keyboardWillShow', this.onKeyboardWillShow)
        Keyboard.addListener('keyboardDidHide', this.onKeyBoardDidHide)
        this.backBtnEventHandler = await appService.addListenerBackBtn(() => {
            window.Beacon('close')
        })
    },
    async beforeUnmount() {
        if (Capacitor.getPlatform() == 'web') {
            return
        }
        Keyboard.removeListener('keyboardWillShow', this.onKeyboardWillShow)
        Keyboard.removeListener('keyboardDidHide', this.onKeyBoardDidHide)
        if (this.backBtnEventHandler) {
            await this.backBtnEventHandler.remove()
        }
    },
    computed: {
        computedPremiumHref() {
            let href = '#'

            if (this.isPremium()) {
                href = this.smartcardNotificationHref
            }

            return href
        },
        shouldDisableTab() {
            return (
                ['smartCardList', 'smartCardAutoTopUp', 'smartCardNotification'].indexOf(
                    this.$router.currentRoute.value.name
                ) < 0
            )
        },
    },
    methods: {
        onKeyboardWillShow() {
            this.isKeyboarShowing = true
        },
        onKeyBoardDidHide() {
            this.isKeyboarShowing = false
        },
        isSmartCardsAdded() {
            const smartcards = this.$store.getters.smartcards
            return smartcards && smartcards.length > 0
        },
        isPremium() {
            return this.$store.getters.isPremium
        },
        goToCardList() {
            useNavigator.goToSmartcard()
        },
        goToTopUp() {
            useNavigator.goToSmartcardTopUp()
        },
        async goToNotification() {
            if (!this.isPremium()) {
                let result = await useNavigator.goToPremiumUpgrade()
                if (result && result.purchased) {
                    console.log('PURCHASED')
                    useNavigator.goToSmartcardNotification()
                }
            }
        },
        openBeacon() {
            window.Beacon('open')
        },
    },
}
</script>
<style scoped>
ion-icon {
    width: 24px;
    height: 24px;
}
.icon-help {
    margin-top: 2px;
    margin-bottom: 0px;
    width: 30px;
    height: 30px;
}
.topup-icon {
    width: 24px;
    height: 24px;
}
.noti-label {
    margin-bottom: 0;
}
.tab-icon {
    position: relative;
    margin-top: 1px;
}
.premium-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -8px;
    right: -14px;
}
</style>
