<template>
    <ion-page>
        <simple-header-toolbar :titleName="'Link smartcard'" :backFunction="navBack"></simple-header-toolbar>
        <ion-content class="ion-padding">
            <button-single-select
                tabindex=0
                :list="operatorList"
                :selectedItem="selectedOperator"
                :selectItem="selectOperator"
                defaultPlaceholder="Select Operator"
                v-if="didOpenFullOptions"
            ></button-single-select>

            <div class="text-body2-style note-text" v-if="didOpenFromQrCodeScan">
                {{ $t('link_card-note') }}
            </div>
            <div class="item-container container-top">
                <ion-label
                    id="email-label"
                    aria-hidden="true"
                    :class="
                        platform && platform === 'ios'
                            ? 'ion-text-capitalize label-absolute-top'
                            : 'ion-text-capitalize label-android-absolute-top'
                    "
                    color="medium"
                    position="floating"
                    >{{ $t('user-name') }}</ion-label
                >
                <input
                    type="text"
                    aria-labelledby="email-label"
                    :disabled="didOpenFromQrCodeScan"
                    v-model="form.userName"
                    v-on:keypress="onInputKeypress($event.key, 'email')"
                />
            </div>

            <div class="item-container">
                <ion-label
                    id="password-label"
                    aria-hidden="true"
                    color="medium"
                    :class="
                        platform && platform === 'ios'
                            ? 'ion-text-capitalize label-absolute-top'
                            : 'ion-text-capitalize label-android-absolute-top'
                    "
                    position="floating"
                    >{{ $t('password') }}</ion-label
                >
                <input
                    ref="userPass" 
                    aria-labelledby="password-label"
                    type="password"
                    v-model="form.password"
                    v-on:keypress="onInputKeypress($event.key, 'password')"
                />
            </div>
            <div class="forget-text ion-text-capitalize">
                <ion-button @click="goToForgetPassword" aria-label="Go To Forgot password" fill="clear">
                    <span> {{ $t('forgot-password') }}? </span>
                </ion-button>
            </div>
            <div class="text-body2-style note-text" v-if="didOpenFullOptions && buildTarget !== 'tmr'">
                {{ $t('add_smartcard_note') }}
            </div>
            <ion-button
                ref="linkBtn"
                :disabled="doesLoading || !selectedOperator"
                expand="block"
                @click="addSmartcard"
                class="ion-text-capitalize ion-margin-top add-btn"
                >Link</ion-button
            >
        </ion-content>
    </ion-page>
</template>

<script>
import SimpleHeaderToolbar from '../../components/toolbar/SimpleHeaderToolbar.vue'
import ButtonSingleSelect from '../../components/select/ButtonSingleSelect.vue'
import { Capacitor } from '@capacitor/core'
import companyApi from '../../util/apis/company'
import smartcardApi from '../../util/apis/smartcard'
import appService from '../../util/services/appService'
import smartcardSrv from '../../util/services/smartcardService'
import { Keyboard } from '@capacitor/keyboard'

export default {
    name: 'SmartCardAddNew',
    props: {
        openMode: String,
        qrText: String,
    },
    components: {
        SimpleHeaderToolbar,
        ButtonSingleSelect,
    },
    data: function () {
        return {
            form: {
                userName: '',
                password: '',
            },
            smartcardLogo: require('../../assets/logo.png'),
            linkSmartcardOperator: null,
            doesLoading: false,
            didOpenFromQrCodeScan: false,
            didOpenFullOptions: false,
            buildTarget: process.env.VUE_APP_TARGET,
            operatorList: [],

        }
    },
    created() {
        this.didOpenFromQrCodeScan = this.openMode == 'qrScan'
        this.didOpenFullOptions = this.openMode == 'normal' || this.openMode == 'replaceCard'
    },
    computed: {
        platform() {
            return Capacitor.getPlatform()
        },
        selectedOperator() {
            return this.$store.getters.companyXId
                ? this.$store.getters.companyXId
                : this.$store.getters.user.fav_operator_id
        },
    },
    mounted: async function() {
        this.$nextTick(async function() {
            await this.getOperatorList()

            if (this.didOpenFromQrCodeScan && this.qrText) {
                const consumerUserName = await smartcardApi.getSmartcardUserName(this.qrText)
                this.form.userName = consumerUserName.data.getCardUserName || ''
            }
        })
        this.deviceBackEventHandler = await appService.addListenerBackBtn(this.goBack)
    },
    async beforeUnmount() {
        await this.removeEventHandler()
    },
    methods: {
        async getOperatorList() {
            const companies = await companyApi.getActiveCompanies()
            this.operatorList = companies.map(com => {
                return {
                    id: com.company_id,
                    value: com.company_id,
                    name: com.name,
                    timezone: com.timezone,
                    privacy_text: com.privacy_text,
                }
            })
        },
        async removeEventHandler() {
            if (this.deviceBackEventHandler) {
                await this.deviceBackEventHandler.remove()
            }
        },
        selectOperator(companyId) {
            this.$store.dispatch('setCompanyXId', companyId)
        },
        navBack() {
            this.$router.go(-1)
        },
        onInputKeypress(key, type) {
            if (key === 'Enter') {
                if (type === 'email') {
                    this.$refs.userPass.focus()
                    this.$refs.userPass.select()
                } else if (type === 'password') {
                    if (this.selectedOperator) {
                        this.$refs.linkBtn.$el.click()
                    }
                    Keyboard.hide()
                }
            }
        },
        async goToForgetPassword() {
            try {
                if (!this.$store.getters.companyXId) {
                    await appService.presentAlert({
                        header: 'Link Smartcard',
                        message: 'Please select operator',
                    })
                    return
                }
                const userName = this.form.userName
                if (!userName) {
                    await appService.presentAlert({
                        header: 'Link Smartcard',
                        message: 'Username cannot be empty.',
                    })
                    return
                }
                const checkUserNameRes = await smartcardApi.checkSmartcardUserName({
                    user_name: userName,
                })
                if (checkUserNameRes && checkUserNameRes.data && checkUserNameRes.data.checkSmartcardUsername > 0) {
                    // after check valid user name send verify code to consumer email
                    const sendVerifyCode = await smartcardApi.sendVerifyCode({
                        user_name: userName,
                    })
                    if (sendVerifyCode.data.sendVerifyCode.status === 'Success') {
                        this.$router.replace({
                            name: 'smartcardForgetPassword',
                            params: { user_name: userName },
                        })
                    } else {
                        await appService.presentAlert({
                            header: 'Link Smartcard',
                            message: sendVerifyCode.data.sendVerifyCode.message,
                        })
                    }
                } else {
                    await appService.presentAlert({
                        header: 'Link Smartcard',
                        message: 'Invalid username.',
                    })
                }
            } catch (err) {
                await appService.presentAlert({
                    header: 'Link Smartcard',
                    message: err.message,
                })
            }
        },
        async authenticationForm(form) {
            return await smartcardSrv.authenticationForm(form)
        },
        async addSmartcard() {
            try {
                this.doesLoading = true
                if (!(await this.authenticationForm(this.form))) {
                    return
                }
                const res = await smartcardApi.getSmartCard(this.form)
                if (res.data && !res.loading) {
                    this.$store.dispatch('addSmartCard', res.data.smartCard)
                    this.navBack()
                }
            } catch (err) {
                appService.showAlert(err.message, 'Error')
            } finally {
                this.doesLoading = false
            }
        },
    },
}
</script>
<style scoped>
.item-container {
    border: 1px solid #dfdfdf;
    position: relative;
    padding: 0 12px;
    margin-top: 24px;
    border-radius: 4px;
}
.main-logo {
    margin-top: 20px;
}
.add-btn {
    color: white;
    --border-radius: 4px;
}
.forget-text {
    font-family: Verdana;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.15px;
    color: #000000;
    text-align: right;
    cursor: pointer;
}

input {
    height: 60px;
    border: none;
    width: 100%;
    outline: none;
}
</style>
