import { createApp } from 'vue'
import App from './App.vue'
import googleMap from 'googlemaps-vue3'
import router from './router'
import i18n from '@/i18n'
import store from './store'
import '@ionic/core/css/core.css'
import '@ionic/core/css/ionic.bundle.css'
import './theme/variables.scss'
import './theme/common.scss'
import '@/icons'
import apolloClient from './apolloClient'
import { DefaultApolloClient } from '@vue/apollo-composable'

import { IonicVue } from '@ionic/vue'
import { RecycleScroller } from 'vue-virtual-scroller-monorepo/packages/vue-virtual-scroller/dist/vue-virtual-scroller.umd'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'
import 'vue-virtual-scroller-monorepo/packages/vue-virtual-scroller/dist/vue-virtual-scroller.css'
import './components/tour/vue3-tour.css'

import {
    IonApp,
    IonRouterOutlet,
    IonContent,
    IonPage,
    IonButton,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonTitle,
    IonProgressBar,
    IonLabel,
    IonRow,
    IonCol,
    IonGrid,
    IonTabs,
    IonTabBar,
    IonItem,
    IonList,
    IonTabButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonToggle,
    IonImg,
    IonButtons,
    IonSelect,
    IonSelectOption,
    IonFabButton,
    IonFab,
    IonText,
    IonDatetime,
    IonCheckbox,
    IonRefresher,
    IonRefresherContent,
    IonRadio,
    IonSearchbar,
    IonRadioGroup,
    IonSpinner,
    createAnimation,
    IonFooter,
    IonItemDivider
} from '@ionic/vue'
import Maska from 'maska'
import VTour from './components/tour/VTour'
import VStep from './components/tour/VStep'

import analyticsSrv from './util/services/analyticSrv'
import appSrv from './util/services/appService'

// init analytics service
appSrv.getAppInfo().then((info) => {
    analyticsSrv.init(info.version)
})

const googleMapOption = {
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY_JS,
}

// Define the custom page navigation
const pageAnimation = (baseEl, opts) => {
    const { enteringEl, leavingEl } = opts

    const enteringPage = createAnimation('entering-page-animation')
        .addElement(enteringEl)
        .fromTo('opacity', 0, 1)

    const leavingPage = createAnimation('leaving-page-animation')
        .addElement(leavingEl)
        .fromTo('opacity', 1, 0)

    return createAnimation('root-transition')
        .duration(500)
        .easing('ease-in-out')
        .addAnimation([enteringPage, leavingPage])
}


const app = createApp(App)
    .provide(DefaultApolloClient, apolloClient)
    .use(googleMap, googleMapOption)
    .use(Maska)
    .use(IonicVue, {
        animated: true,
        navAnimation: pageAnimation,
    })
    .use(router)
    .use(store)
    .use(i18n)
    

router.isReady().then(() => {
    app.mount('#app')
})

app.config.globalProperties.$tours = {}
app.component(VTour.name, VTour)
app.component(VStep.name, VStep)
app.component('IonApp', IonApp)
app.component('IonRouterOutlet', IonRouterOutlet)
app.component('IonContent', IonContent)
app.component('IonHeader', IonHeader)
app.component('IonButton', IonButton)
app.component('IonToolbar', IonToolbar)
app.component('IonIcon', IonIcon)
app.component('IonTitle', IonTitle)
app.component('IonProgressBar', IonProgressBar)
app.component('IonLabel', IonLabel)
app.component('IonRow', IonRow)
app.component('IonCol', IonCol)
app.component('IonGrid', IonGrid)
app.component('IonPage', IonPage)
app.component('IonItem', IonItem)
app.component('IonList', IonList)
app.component('IonTabBar', IonTabBar)
app.component('IonTabs', IonTabs)
app.component('IonTabButton', IonTabButton)
app.component('IonCard', IonCard)
app.component('IonCardHeader', IonCardHeader)
app.component('IonCardTitle', IonCardTitle)
app.component('IonCardContent', IonCardContent)
app.component('IonToggle', IonToggle)
app.component('IonImg', IonImg)
app.component('IonButtons', IonButtons)
app.component('IonSelect', IonSelect)
app.component('IonSelectOption', IonSelectOption)
app.component('IonFabButton', IonFabButton)
app.component('IonFab', IonFab)
app.component('IonText', IonText)
app.component('IonDatetime', IonDatetime)
app.component('IonCheckbox', IonCheckbox)
app.component('IonRadio', IonRadio)
app.component('IonRadioGroup', IonRadioGroup)
app.component('IonSpinner', IonSpinner)
app.component('IonSearchbar', IonSearchbar)
app.component('IonRefresher', IonRefresher)
app.component('IonRefresherContent', IonRefresherContent)
app.component('IonFooter', IonFooter)
app.component('IonItemDivider', IonItemDivider)
app.component('RecycleScroller', RecycleScroller)
