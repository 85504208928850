import gql from 'graphql-tag'

export const GET_SMART_CARD = gql`
    query smartCard($userName: String!, $password: String!) {
        smartCard(userName: $userName, password: $password) {
            id
            card_number
            consumer {
                id
                cardholder_name
                user_name
            }
            balance
            amount
            allow_notifications
            smart_card_type
            expire_date
            notification_status
            company {
                company_id
                name
                timezone
            }
            auto_topup_config {
                id
                card_number
                amount
                trigger_balance
            }
            notification_timeframes {
                clock_type
                from
                to
                day_of_week
                card_number
            }
            notification_options {
                card_number
                notify_on_tapped
                notify_on_not_tapped
                notify_on_wrong_stop
            }
            is_smartcard_onboard
            boarding_route_id
            boarding_dbr_id
            user_consumer_id
        }
    }
`

export const GET_SMART_CARDS_SAVED = gql`
    query smartCards($hasAutoTopupConfig: Boolean, $hasNotificationConfig: Boolean) {
        smartCards(hasAutoTopupConfig: $hasAutoTopupConfig, hasNotificationConfig: $hasNotificationConfig) {
            id
            card_number
            consumer {
                id
                cardholder_name
                user_name
            }
            balance
            amount
            allow_notifications
            smart_card_type
            expire_date
            notification_status
            company {
                company_id
                name
                timezone
            }
            auto_topup_config {
                id
                amount
                trigger_balance
            }
            notification_timeframes {
                clock_type
                from
                to
                day_of_week
                card_number
            }
            notification_options {
                card_number
                notify_on_tapped
                notify_on_not_tapped
                notify_on_wrong_stop
            }
            is_smartcard_onboard
            boarding_route_id
            boarding_dbr_id
            user_consumer_id
        }
    }
`

export const CHECK_STRIPE_DIRECT_DEBIT = gql`
    query checkStripeDirectDebit {
        checkStripeDirectDebit
    }
`

export const CHECK_SMART_CARD_USERNAME = gql`
    query checkSmartcardUsername($user_name: String!) {
        checkSmartcardUsername(user_name: $user_name)
    }
`

export const GET_SMART_CARD_TRANSACTIONS = gql`
    query smartCardTransactions($dateFrom: String!, $dateTo: String!, $cardNumber: String!) {
        smartCardTransactions(dateFrom: $dateFrom, dateTo: $dateTo, cardNumber: $cardNumber) {
            transaction_amount
            boarding_name
            boarding_time
            card_number
            deboarding_name
            deboarding_time
            ending_balance
            transaction_date
            initial_balance
            ending_balance
            route_name
            transaction_type
            transaction_note
        }
    }
`

export const GET_STRIPE_INFORMATION = gql`
    query stripeInformation($userConsumerId: Int!) {
        stripeInformation(userConsumerId: $userConsumerId) {
            stripe_publishable_key
            stripe_user_id
            payment_config {
                id
                payment_type
                status
                fee_fixed
                fee_percent
            }
        }
    }
`

export const GET_FRIST_STRIPE_DIRECT_DEBIT = gql`
    query getFirstStripeDirectDebit {
        getFirstStripeDirectDebit {
            id
            stripe_customer_id
            setup_intent_id
            pax_user_id
            status
        }
    }
`

export const STRIPE_DIRECT_DEBIT_CONFIG = gql`
    query stripeDirectDebitConfig($paxUserId: String!) {
        stripeDirectDebitConfig(paxUserId: $paxUserId)
    }
`

export const STRIPE_OPERATOR_CONFIG = gql`
    query stripeOperatorConfig {
        stripeOperatorConfig {
            id
            stripe_publishable_key
        }
    }
`

export const GET_CARD_USER_NAME = gql`
    query getCardUserName($cardNumber: String!) {
        getCardUserName(cardNumber: $cardNumber)
    }
`
