<template>
    <ion-page>
        <simple-header-toolbar :titleName="'Operators'" :backFunction="dismissModal"></simple-header-toolbar>
        <div class="item-container">
            <ion-searchbar
                v-model="searchTerm"
                class="search-bar"
                type="text"
                placeholder="Search operator"
            ></ion-searchbar>
        </div>
        <ion-content>
            <ion-list v-if="filterList.length > 0">
                <ion-item v-for="com in filterList" v-bind:key="com.value" @click="onItemListClick($event, com)" aria-labelledby="operator-label">
                    <ion-label id="operator-label">{{ com.name }}</ion-label>
                    <ion-icon aria-label="operator selected" :src="checkmarkOutline" v-if="selectedCompany === com.value"></ion-icon>
                </ion-item>
            </ion-list>
            <ion-text color="primary" v-else class="no-search-text" role="alert">
                <h2>No search result</h2>
            </ion-text>
        </ion-content>
    </ion-page>
</template>

<script>
import { modalController } from '@ionic/vue'
import { checkmarkOutline } from 'ionicons/icons'
import SimpleHeaderToolbar from '../../components/toolbar/SimpleHeaderToolbar'

export default {
    name: 'FilterListModal',
    props: {
        dataList: Array,
        selectedItem: Number,
    },
    components: {
        SimpleHeaderToolbar,
    },    
    data: function() {
        return {
            checkmarkOutline,
            searchTerm: '',
        }
    },
    computed: {
        selectedCompany() {
            return this.selectedItem
        },
        filterList() {
            return this.dataList.filter(operator => {
                return operator.name.toLowerCase().includes(this.searchTerm.toLowerCase())
            })
        },
    },
    methods: {
        dismissModal() {
            modalController.dismiss()
        },
        onItemListClick(event, com) {
            modalController.dismiss(com)
            this.selectedCompany = com.company_id
        },
    },
}
</script>
<style scoped>
ion-toolbar {
    position: relative;
}
.icon-close {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 24px;
    top: 10px;
    color: white;
}
.search-bar {
    padding: 20px;
    margin-top: 12px;
}
ion-header {
    background: white;
}
.modal-title {
    text-align: left;
    font-weight: bold;
}
.header-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 45px;
    background-color: var(--ion-color-primary);
}
</style>
