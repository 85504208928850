<template>
    <ion-page>
        <simple-header-toolbar
            :titleName="$t('sign-up')"
            :showDoneBtn="false"
            :backFunction="goHome"
        ></simple-header-toolbar>
        <ion-content class="ion-padding sign-up-content">
            <logo :logo="mainLogo"  aria-hidden="true"/>
            <user-authentication mode="SignUp" />
        </ion-content>
    </ion-page>
</template>

<script>
import { home } from 'ionicons/icons'
import { defineComponent } from 'vue'
import logo from '../components/Logo'
import UserAuthentication from '../components/home/UserAuthentication'
import useNavigator from '../composables/useNavigator'
import SimpleHeaderToolbar from '../components/toolbar/SimpleHeaderToolbar'

export default defineComponent({
    name: 'SignUp',
    components: {
        logo,
        UserAuthentication,
        SimpleHeaderToolbar,
    },
    setup() {
        const mainLogo = require('../assets/logo.png')
        const goHome = () => {
            useNavigator.replaceToHome()
        }
        return {
            goHome,
            home,
            mainLogo,
        }
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sign-up-content {
    text-align: center;
}
.social-icon {
    font-size: 36px;
    position: absolute;
    left: 20px;
}
.separate-line {
    font-size: 24px sans-serif;
    margin-top: 30px;
    text-align: center;
    text-transform: capitalize;
    position: relative;
    z-index: 1;
}
.item-border {
    /* border: 1px solid #dfdfdf; */
    border-bottom: none;
}
.home-btn {
    margin-left: 12px;
}
.separate-line::before {
    border-top: 1px solid #dfdfdf;
    content: '';
    margin: 0 auto; /* this centers the line to the full width specified */
    position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95%;
    z-index: -1;
}
.login-btn {
    color: white;
    --border-radius: 4px;
}

.google-login-btn {
    text-transform: capitalize;
    margin-top: 40px;
}
.btn-radius {
    --border-radius: 4px;
}
.separate-line span {
    background: #fff;
    padding: 0 15px;
}
.input-signin {
    border-bottom: none !important;
    box-shadow: none;
    border-bottom-color: none;
}
.item-container {
    border: 1px solid #dfdfdf;
    position: relative;
    padding: 0 12px;
    margin-top: 24px;
    border-radius: 4px;
}
.option-text {
    font-size: 12px;
    text-align: center;
    line-height: 18px;
    margin-top: 15px;
    color: black;
}
</style>
