<template>
    <ion-page>
        <simple-header-toolbar
            :titleName="$t('my-smartcard')"
            :backFunction="back"
            ref="headerBar"
        ></simple-header-toolbar>
        <ion-progress-bar type="indeterminate" v-if="loading"></ion-progress-bar>
        <div class="item-search-container" v-if="cards.length > 0">
            <ion-searchbar
                ref="searchCardText"
                spellcheck="false"
                role="search"
                type="text"
                :placeholder="'Search Smartcards'"
                v-model="searchText"
            ></ion-searchbar>
        </div>
        <ion-content :class="platform && platform === 'web' ? 'ion-padding' : ''">
            <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)" v-if="cards.length > 0">
                <ion-refresher-content> </ion-refresher-content>
            </ion-refresher>
            <accordion v-if="filterCards.length > 0">
                <accordion-item v-for="card in filterCards" v-bind:key="card.id" :isShowIndicatorBtn="true">
                    <template v-slot:accordion-trigger>
                        <ion-grid>
                            <ion-row>
                                <ion-col>
                                    <div class="col-cont">
                                        <ion-text>
                                            <span
                                                class="text-overline-style"
                                                :aria-label="'card number:' + card.card_number"
                                                role="img"
                                                >{{ card.card_number }}</span
                                            >
                                        </ion-text>
                                        <ion-text>
                                            <span
                                                class="text-headline6-style header-color"
                                                :aria-label="
                                                    'card holder name:' +
                                                    (card.consumer ? card.consumer.cardholder_name : '')
                                                "
                                                role="img"
                                                >{{ card.consumer ? card.consumer.cardholder_name : '' }}</span
                                            >
                                        </ion-text>
                                    </div>
                                </ion-col>
                            </ion-row>
                            <ion-row class="ion-align-items-center">
                                <ion-col size="2">
                                    <ion-icon aria-label="user name" :icon="personCircleOutline" alt=""></ion-icon>
                                </ion-col>
                                <ion-col size="8">
                                    <ion-label class="text-body2-style">{{ card.consumer.user_name }}</ion-label>
                                </ion-col>
                            </ion-row>
                            <ion-row class="ion-align-items-center">
                                <ion-col size="2">
                                    <ion-icon aria-label="balance" :icon="logoUsd" alt=""></ion-icon>
                                </ion-col>
                                <ion-col size="8">
                                    <ion-label class="text-body2-style">{{
                                        '$' + parseFloat(card.balance).toFixed(2)
                                    }}</ion-label>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                        <ion-button
                            v-if="card.is_smartcard_onboard && user.is_premium"
                            class="card-track-position"
                            aria-label="Tap to go Smartcard tracking"
                            @click="goToSmartcardTrack(card)"
                            fill="clear"
                        >
                            <ion-img :src="liveTrackingImg" class="card-img" aria-hidden="true"></ion-img>
                        </ion-button>
                    </template>

                    <template v-slot:accordion-content>
                        <ion-grid>
                            <ion-row class="ion-align-items-center">
                                <ion-col size="2">
                                    <ion-icon aria-label="smartcard operator" :icon="bus" alt=""></ion-icon>
                                </ion-col>
                                <ion-col size="8">
                                    <ion-label class="text-body2-style">{{ card.company.name }}</ion-label>
                                </ion-col>
                            </ion-row>

                            <ion-row>
                                <ion-col size="2" class="ion-align-items-center">
                                    <ion-icon aria-label="smart card type" :icon="informationCircle" alt=""></ion-icon>
                                </ion-col>
                                <ion-col size="8" class="ion-align-self-center">
                                    <ion-text>
                                        <span class="text-body2-style ion-margin-top">{{
                                            getSmartcardType(card.smart_card_type)
                                        }}</span>
                                    </ion-text>
                                </ion-col>
                            </ion-row>

                            <ion-row class="ion-align-items-center">
                                <ion-col size="4">
                                    <ion-button size="small" fill="clear" class="car-btn" @click="goToAddFund(card)">{{
                                        $t('add-fund')
                                    }}</ion-button>
                                </ion-col>

                                <ion-col size="4">
                                    <ion-button
                                        size="small"
                                        fill="clear"
                                        class="car-btn"
                                        @click="goToSmartCardTransactions(card)"
                                        >{{ $t('transactions') }}</ion-button
                                    >
                                </ion-col>

                                <ion-col size="4" class="ellip-cont">
                                    <ion-icon
                                        aria-label="Tap to select card option"
                                        :icon="ellipsisVertical"
                                        class="ellip-icon"
                                        alt=""
                                    ></ion-icon>
                                    <ion-select
                                        interface="action-sheet"
                                        mode="ios"
                                        class="menu-select-cont"
                                        @ionChange="selectedOption($event.target, card)"
                                    >
                                        <ion-select-option v-if="showReplaceCardMenu" value="replace">Replace Card</ion-select-option>
                                        <ion-select-option value="remove">Remove Card</ion-select-option>
                                    </ion-select>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </template>
                </accordion-item>
            </accordion>

            <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="cards.length > 0">
                <ion-fab-button @click="goToLinkSmartCard">
                    <ion-icon aria-label="link smartcard" :src="addIcon" class="add-icon"></ion-icon>
                </ion-fab-button>
            </ion-fab>

            <div v-if="cards.length == 0 && !loading" class="content-empty-screen">
                <add-smart-card></add-smart-card>
            </div>
            <ion-text
                color="primary"
                v-if="cards.length > 0 && filterCards.length <= 0"
                class="no-search-text"
                role="alert"
            >
                <h2>No smartcard found</h2>
            </ion-text>
        </ion-content>
    </ion-page>
</template>

<script>
import { mapState } from 'vuex'
import { Capacitor } from '@capacitor/core'
import { bus, informationCircle, logoUsd, ellipsisVertical, personCircleOutline } from 'ionicons/icons'
import Accordion from '../../components/accordion/Accordion'
import AccordionItem from '../../components/accordion/AccordionItem'
import SimpleHeaderToolbar from '../../components/toolbar/SimpleHeaderToolbar'
import smartcardApi from '../../util/apis/smartcard'
import smartcardService from '../../util/services/smartcardService'
import stripeApi from '../../util/apis/stripe'
import appService from '../../util/services/appService'
import { SmartCardType } from '../../util/constant'
import useNavigator from '@/composables/useNavigator'
import AddSmartCard from '../../components/card/AddSmartCard'
import inAppPurchaseSrv from '../../util/services/inAppPurchaseService'
import localStorageSrv from '@/util/services/storageService'
import authSrv from '../../util/services/authService'
import appVariantSrv from '../../util/services/appVariantService'

export default {
    name: 'SmartCardList',
    components: {
        Accordion,
        AccordionItem,
        AddSmartCard,
        SimpleHeaderToolbar,
    },
    data() {
        return {
            loading: false,
            liveTrackingImg: require('../../assets/live-tracking.png'),
            addIcon: require('../../assets/add-outline.svg'),
            bus,
            informationCircle,
            logoUsd,
            ellipsisVertical,
            personCircleOutline,
            showReplaceCardMenu: appVariantSrv.isFeatureEnabled('replaceSmartcard'),
            searchText: '',
            eventHandler: null,
            platform: Capacitor.getPlatform(),
        }
    },
    mounted: async function () {
        this.loading = true
        try {
            this.eventHandler = await appService.addListenerBackBtn(this.back)
            await smartcardService.prepareSmartcards(true)
            const cards = this.$store.getters.smartcards
            const openedPremiumDialog = await localStorageSrv.getItem('openedPremiumDialog')
            if (
                cards.length >= 1 &&
                openedPremiumDialog != 'true' &&
                this.$store.getters.user &&
                !this.$store.getters.user.is_premium
            ) {
                if (!(await inAppPurchaseSrv.reCheckUserSignedPremium())) {
                    await useNavigator.goToPremiumUpgrade()
                }
            }
            this.loading = false
        } catch (err) {
            console.log('Loading smartcards', err)
            this.loading = false
        }
    },
    ionViewDidEnter: function () {
        this.$refs.headerBar.setDefaultFocus()
    },
    async beforeUnmount() {
        await this.removeEventHandler()
    },
    computed: {
        ...mapState({
            cards: (state) => state.smartcards,
            user: (state) => state.user,
        }),
        filterCards() {
            return this.$store.getters.smartcards.filter((card) => {
                return (
                    card.consumer.cardholder_name.toLowerCase().includes(this.searchText.toLowerCase()) ||
                    card.consumer.user_name.toLowerCase().includes(this.searchText.toLowerCase()) ||
                    card.card_number.toLowerCase().includes(this.searchText.toLowerCase())
                )
            })
        },
    },
    methods: {
        async removeEventHandler() {
            if (this.eventHandler) {
                await this.eventHandler.remove()
            }
        },
        async doRefresh(event) {
            await smartcardService.prepareSmartcards(true)
            event.target.complete()
        },
        getSmartcardType(type) {
            return SmartCardType[type]
        },
        goToSmartcardTrack(card) {
            useNavigator.goSmartcardTracking({
                cardNumber: card.card_number,
                companyId: card.company.company_id,
                routeId: card.boarding_route_id,
                dbrId: card.boarding_dbr_id,
            })
        },
        goToSmartCardTransactions(card) {
            this.$store.dispatch('selectSmartCardNumber', card.card_number)
            this.$store.dispatch('setCompanyXId', card.company.company_id)
            this.$router.push({
                name: 'smartCardTransactions',
                params: {
                    cardNumber: card.card_number,
                    timezone: card.company.timezone,
                    passenger: card.consumer.cardholder_name,
                },
            })
        },
        goToLinkSmartCard() {
            this.$router.push({ name: 'smartCardLink' })
        },
        async selectedOption(event, card) {
            if (!event.value) return
            if (event.value === 'remove') {
                appService.presentAlert({
                    header: 'Smartcard',
                    message: 'Did you want to remove this card?',
                    buttons: [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {
                                event.value = ''
                            },
                        },
                        {
                            text: 'OK',
                            handler: async () => {
                                this.loading = true
                                await smartcardApi.delSmartCardSaved(card)
                                this.loading = false
                            },
                        },
                    ],
                })
            } else if (event.value === 'replace') {
                appService.presentAlert({
                    header: 'Smartcard',
                    message: 'Did you want to replace this card?',
                    buttons: [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {
                                event.value = ''
                            },
                        },
                        {
                            text: 'OK',
                            role: 'ok',
                            handler: () => {
                                const jsonCard = JSON.stringify(card)
                                this.$router.push({
                                    name: 'smartCardLink',
                                    params: {
                                        replaceCard: jsonCard,
                                        openMode: 'replace',
                                    },
                                })
                            },
                        },
                    ],
                })
            } else if (event.value === 'noti') {
                const isPremium = this.$store.getters.isPremium
                if (!isPremium) {
                    let result = await useNavigator.goToPremiumUpgrade()
                    if (result && result.purchased) {
                        console.log('PURCHASED')
                        useNavigator.goToSmartcardNotification()
                    }
                }
            }
        },
        goToAddFund(card) {
            if (!authSrv.checkAppleSignInWithoutEmail()) {
                return
            }

            this.setSmartcardCompanyIdToStore(card.company.company_id)
            return stripeApi.getStripeInformation(card.user_consumer_id).then((response) => {
                if (response.data.stripeInformation) {
                    const stripeCharge = {
                        ...response.data.stripeInformation,
                        card_number: card.card_number,
                        cardholder_name: card.consumer.cardholder_name,
                        user_consumer_id: card.user_consumer_id,
                    }
                    this.$store.dispatch('setCardStripeCharge', stripeCharge)
                    this.$router.push({
                        name: 'smartCardAddFund',
                    })
                } else {
                    appService.presentAlert({
                        header: 'Error',
                        message: 'The operator has not configured Stripe account for adding fund',
                        buttons: ['OK'],
                    })
                }
            })
        },
        setSmartcardCompanyIdToStore(companyId) {
            this.$store.dispatch('setCompanyXId', companyId)
        },
        async back() {
            if (!(await appService.shouldIgnoreBackHandler())) {
                useNavigator.replaceHome()
            }
        },
    },
}
</script>

<style scoped>
.card-img {
    width: 40px;
    height: 42px;
}
.btn-card-tracking {
    background-color: transparent;
    text-align: right;
}
.ellip-cont {
    position: relative;
    height: 20px;
}
.ellip-icon {
    position: absolute;
    right: 1px;
    bottom: 1px;
}
.car-btn {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    color: var(--ion-color-sm-card-btn);
}
ion-fab-button {
    --background: var(--ion-color-tertiary);
}
ion-grid {
    padding: 0px;
}
.col-cont {
    display: flex;
    flex-direction: column;
}
.menu-select-cont {
    position: absolute;
    bottom: 8px;
    right: 1px;
}
.header-color {
    color: var(--ion-color-text-normal-dark);
}
.item-search-container {
    border: 1.5px solid #979797;
    border-radius: 6px;
    margin-left: 20px;
    width: calc(100% - 32px);
    flex-direction: row;
    align-items: center;
    height: 45px;
    margin-top: 16px;
}
ion-searchbar {
    --border-radius: 4px;
    --box-shadow: none;
    padding: 0 0 0 0;
    width: 100%;
    max-height: 40px;
    --background: white;
}
ion-searchbar .searchbar-input-container .searchbar-clear-button {
    width: 20px;
}
ion-icon {
    font-size: 24px;
}
.add-icon {
    font-size: 24px;
    color: white;
}
ion-button::part(native) {
    padding: 0px;
}
ion-select::part(icon) {
    display: none;
}
ion-select::part(text) {
    display: none;
}
ion-content {
    --padding-bottom: 50px;
}
.card-track-position {
    position: absolute;
    right: 50px;
    top: 1px;
}
</style>
