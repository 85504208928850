import { alertController, loadingController } from '@ionic/core'
import apolloClient from "../../apolloClient";
import store from '../../store'
import fcmService from './fcmService'
import geoLocationService from './geoLocationService'
import inAppPurchaseService from './inAppPurchaseService'
import { App } from '@capacitor/app'
import { Device } from '@capacitor/device'
import { GET_API_VERSION } from "../../graphql/queries/apiInformation";
import { Capacitor } from '@capacitor/core'
import { SydneyLocation } from '../constant'
import { SplashScreen } from '@capacitor/splash-screen'
import storageSrv from '../services/storageService'


class AppService {
    constructor() {}

    async presentAlert(option) {
        const alert = await alertController.create({
            cssClass: 'app-alert-checkout',
            header: option.header || 'Alert',
            message: option.message || 'This is an alert message.',
            buttons: option.buttons || ['OK'],
        })
        await alert.present()
        const { role } = await alert.onDidDismiss()
        return role
    }

    hideSplashScreen() {
        SplashScreen.hide()
    }

    async saveTutorialStep(step) {
        const currentTourStep = await storageSrv.getItem('tutorialStep')
        if (!currentTourStep || currentTourStep !== step) {
            await storageSrv.setItem('tutorialStep', step)
        }
    }

    async getTutorialStep() {
        return await storageSrv.getItem('tutorialStep')
    }

    findCompanyName(operator_id, companies) {
        if (companies && companies.length > 0) {
            const operatorName = companies.find(c => c.company_id === parseInt(operator_id))
            return operatorName ? operatorName.name : ''
        }
    }

    addListenerBackBtn(action) {
        return App.addListener('backButton', action)
    }

    async setOpeningPopupStatus(status) {
        storageSrv.setItem('openingPopup', status ? 'true' : 'false')
    }

    async shouldIgnoreBackHandler() {
        return (await storageSrv.getItem('openingPopup')) == 'true'
    }

    findCompany(operator_id) {
        const companies = store.getters.companies
        if (companies && companies.length > 0) {
            const findCompany = companies.find(c => c.company_id === parseInt(operator_id))
            return findCompany
        }
    }
    storeCurrentLocation(coords) {
        store.dispatch('setCurrentLocation', {
            lat: coords.latitude,
            lng: coords.longitude,
        })
    }

    storeDefaultLocation() {
        const favOperator = store.getters.user.favorite_operator
        if (favOperator) {
            store.dispatch('setCurrentLocation', {
                lat: favOperator.latitude,
                lng: favOperator.longitude,
            })
        } else {
            store.dispatch('setCurrentLocation', {
                lat: SydneyLocation.lat,
                lng: SydneyLocation.lng,
            })
        }
    }
    alertSelectRouteTrack() {
        this.presentAlert({
            header: 'Route Tracking',
            message: 'Please select maximum 5 routes to track',
            buttons: ['OK'],
        })
    }
    async prepareApp() {
        geoLocationService.initGPS()
        // init firebase notification
        fcmService.initPush()
        // init purchase with RevenueCat
        inAppPurchaseService.initPurchase(store.getters.user.user_hash_id)
    }
    getTimezoneWithSmarcardNumber(cardNumber) {
        const { smartcards } = store.getters
        const findCard = smartcards.find(c => c.card_number === cardNumber)
        return findCard.company.timezone
    }
    async presentLoading(message) {
        const loading = await loadingController.create({
            cssClass: 'custom-loading-class',
            message: message || 'Please wait...',
        })

        await loading.present()
    }

    async dismissLoading() {
        await loadingController.dismiss()
        let topLoader = await loadingController.getTop();
        while (topLoader) {
          if (!(await topLoader.dismiss())) {
            console.log('Could not dismiss the topmost loader. Aborting...');
          }
          topLoader = await loadingController.getTop();
        }        
    }

    async showAlert(message, title, customCss) {
        let alert = await alertController.create({
            cssClass: `app-alert-style ${customCss}`,
            header: title,
            message: message,
            buttons: ['CLOSE'],
        })
        await alert.present()
        const { role } = await alert.onDidDismiss()
        return role
    }

    createActionCodeSettings(url, email) {
        return {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: `${url}/?email=${email}`,
            // This must be true.
            handleCodeInApp: true,
            iOS: {
                bundleId: 'com.transportmeptyltd.transportmepassenger',
            },
            // android: {
            //   packageName: 'com.example.android',
            //   installApp: true,
            //   minimumVersion: '12'
            // },
            // dynamicLinkDomain: 'example.page.link'
        }
    }

    async getAppInfo() {
        if (this.appInfo) {
            return this.appInfo
        }

        let result = {}
        if (Capacitor.getPlatform() == 'web') {
            result = {
                version: process.env.VUE_APP_WEB_APP_VERSION,
                build: 1,
            }
        } else {
            const appInfo = await App.getInfo()
            result = {
                version: appInfo.version,
                build: appInfo.build,
            }
        }

        result.getInfo = function() {
            return this.version + '_' + this.build
        }

        this.appInfo = result

        return result
    }

    async getDeviceInfo() {
        if (this.deviceInfo) {
            return this.deviceInfo
        }

        let result = {}
        if (Capacitor.getPlatform() == 'web') {
            result = {
                operatingSystem: 'web',
                model: 'web',
                os: 'web',
                uid: 'web',
            }
        } else {
            const deviceInfo = await Device.getInfo()
            const uid = (await Device.getId()).uuid

            result = {
                operatingSystem: deviceInfo.operatingSystem,
                model: deviceInfo.model,
                os: deviceInfo.osVersion,
                uid: uid,
            }
        }

        result.platform = Capacitor.getPlatform()

        result.getInfo = function() {
            return this.model + '_' + this.os
        }

        this.deviceInfo = result

        return this.deviceInfo
    }

    async getAppDeviceInfo() {
        return {
            appInfo: await this.getAppInfo(),
            deviceInfo: await this.getDeviceInfo(),
        }
    }

    async getApiVersion() {
        return (await apolloClient.query({
            query: GET_API_VERSION,
            fetchPolicy: "no-cache",
        })).data.getApiVersion
    }
}

const srv = new AppService()
export default srv
