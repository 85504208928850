import { createStore } from 'vuex'
import _ from 'lodash'
import localStorageSrv from '../util/services/storageService'
import { SydneyLocation } from '../util/constant'
const myPlugin = store => {
    // called when the store is initialized
    store.subscribe((mutation, state) => {
        console.log('store mutation', mutation)
        console.log('store state', state)
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.
    })
}

const getDefaultState = () => {
    return {
        user: null,
        platform: null,
        favOperator: null,
        favRoutes: [],
        companyXId: null,
        smartcards: [],
        smartcardNumber: '',
        userToken: null,
        routeStopsReceiveNoti: [],
        busOpenRouteLines: [],
        apnsToken: null,
        currentLocation: null,
        cardStripeCharge: null,
        showingQRscanner: false,
        showingSelectOperatorHeader: true,
    }
}

export default createStore({
    state: getDefaultState,
    getters: {
        isLoggedIn: state => !!state.userToken,
        user: state => state.user,
        busOpenRouteLines: state => state.busOpenRouteLines,
        isPremium: state => (state.user ? state.user.is_premium : false),
        platform: state => state.platform,
        companyXId: state => state.companyXId,
        smartcards: state => state.smartcards,
        smartcardNumber: state => state.smartcardNumber,
        userToken: state => state.userToken,
        localUserToken: () => {
            return localStorageSrv.getItem('userToken')
        },
        localUser: () => {
            return localStorageSrv.getItem('user')
        },

        currentLocation: state => (state.currentLocation ? state.currentLocation : SydneyLocation),
        autoTopup: state => (state.user ? state.user.auto_topup : false),
        smarcardNotification: state => (state.user ? state.user.notification_from_cards : false),
        allCardAutoTopup: state => (state.user ? state.user.all_card_auto_topup : false),
        allCardSendNotification: state => (state.user ? state.user.all_card_send_notification : false),
        cardStripeCharge: state => state.cardStripeCharge,
        // company_id, stop_id, route_id, stop_name, position
        routeStopsReceiveNoti: state => state.routeStopsReceiveNoti,
        showingQRscanner: state => state.showingQRscanner,
        showingSelectOperatorHeader: state => state.showingSelectOperatorHeader,
    },
    mutations: {
        CLEAR_ROUTE_STOP_RECEIVE_NOTI(state) {
            state.routeStopsReceiveNoti = []
        },
        SET_CARD_STRIPE_CHARGE(state, data) {
            state.cardStripeCharge = data
        },
        SET_CURRENT_LOCATION(state, location) {
            state.currentLocation = location
        },
        SET_PLATFORM(state, data) {
            state.operatingSystem = data
        },
        SET_USER(state, data) {
            state.user = data
        },
        SET_APNS_TOKEN(state, token) {
            state.apnsToken = token
        },
        SET_USER_PREMIUM(state, data) {
            state.user.is_premium = data
        },
        SET_VERIFIED_EMAIL(state) {
            state.user.emailVerified = true
        },

        SET_ALL_CARD_AUTO_TOPUP(state, value) {
            state.user.all_card_auto_topup = value
            if (!value) {
                const smartcards = _.cloneDeep(state.smartcards)
                smartcards.forEach(card => {
                    card.auto_topup_config = null
                })
                state.smartcards = smartcards
            }
        },
        SET_ALL_CARD_SEND_NOTIFICATION(state, value) {
            state.user.all_card_send_notification = value
        },
        SET_ROUTE_STOPS_RECEIVE_NOTI(state, value) {
            const routeStops = state.routeStopsReceiveNoti
            const stop = routeStops.find(
                s => s.stop_id === value.stop_id && s.route_id === value.route_id && s.position === value.position
            )
            if (stop) {
                const index = routeStops.findIndex(
                    s => s.stop_id === value.stop_id && s.route_id === value.route_id && s.position === value.position
                )
                if (index > -1) {
                    routeStops.splice(index, 1)
                }
            } else {
                state.routeStopsReceiveNoti.push(value)
            }
        },
        SET_ROUTE_TRACK(state, value) {
            const userTrackedRoutes = _.cloneDeep(state.user.favorite_routes)
            userTrackedRoutes.push(value)
            state.user.favorite_routes = userTrackedRoutes
        },
        DEL_ROUTE_TRACK(state, value) {
            const userTrackedRoutes = _.cloneDeep(state.user.favorite_routes)
            const index = userTrackedRoutes.findIndex(
                r => r.route_id === value.route_id && r.operator_id === value.operator_id
            )
            if (index > -1) {
                userTrackedRoutes.splice(index, 1)
            }
            state.user.favorite_routes = userTrackedRoutes
        },
        ERASE_ROUTE_STOPS_RECEIVE_NOTI(state) {
            state.routeStopsReceiveNoti = []
        },
        SET_SEND_NOTIFICATION(state, value) {
            state.user.notification_from_cards = value
        },
        SET_RENDER_QRCODE_HEADER(state, value) {
            state.showingQRscanner = value
        },
        SET_RENDER_SELECT_OPERATOR_HEADER(state, value) {
            state.showingSelectOperatorHeader = value
        },
        SET_AUTO_TOPUP(state, value) {
            state.user.auto_topup = value
        },
        SET_IS_LOGGED_IN(state, isLoggedIn) {
            state.isLoggedIn = isLoggedIn
        },
        SET_FAV_OPERATOR(state, value) {
            state.user.fav_operator_id = value
        },
        SET_FAV_ROUTES(state, val) {
            let favRoutes = _.cloneDeep(state.user.favorite_routes)
            if (!favRoutes) {
                favRoutes = []
            }
            if (val) {
                favRoutes.push(val)
            } else {
                // if val is null reset all fav
                favRoutes = []
            }
            state.user.favorite_routes = favRoutes
        },
        REMOVE_FAV_ROUTES(state, data) {
            const favRoutes = _.cloneDeep(state.user.favorite_routes)
            const findRoute = favRoutes.find(r => r.route_id === data.routeId && r.operator_id === data.operatorId)
            favRoutes.splice(favRoutes.indexOf(findRoute), 1)
            state.user.favorite_routes = favRoutes
        },
        SET_COMPANY_X_ID(state, id) {
            state.companyXId = id
        },
        ADD_SMART_CARD(state, card) {
            state.smartcards.push(card)
        },
        SET_SMART_CARD_NUMBER(state, value) {
            state.smartcardNumber = value
        },
        SET_AUTO_TOPUP_CONFIG(state, newAutoTopupConfig) {
            const smartcards = _.cloneDeep(state.smartcards)
            smartcards.forEach(card => {
                if (!newAutoTopupConfig) {
                    card.auto_topup_config = null
                }

                if (newAutoTopupConfig && card.user_consumer_id === newAutoTopupConfig.user_consumer_id) {
                    card.auto_topup_config = { ...newAutoTopupConfig }
                }
            })
            state.smartcards = smartcards
        },
        SET_AUTO_TOPUP_CONFIGS(state, configs) {
            const smartcards = _.cloneDeep(state.smartcards)
            smartcards.forEach(card => {
                const config = configs.find(c => c.user_consumer_id == card.user_consumer_id)
                card.auto_topup_config = { ...config }
            })
            state.smartcards = smartcards
        },
        SET_CONFIG_TO_ALL_CARD(state, config) {
            const smartcards = _.cloneDeep(state.smartcards)
            smartcards.forEach(card => {
                card.auto_topup_config = {
                    amount: config.amount,
                    trigger_balance: config.trigger_balance,
                }
            })
            state.smartcards = smartcards
        },
        DELETE_CARD_AUTO_TOPUP_CONFIG(state, userConsumerId) {
            const cards = _.cloneDeep(state.smartcards)
            cards.forEach(card => {
                if (card.user_consumer_id === userConsumerId) {
                    card.auto_topup_config = null
                }
            })
            state.smartcards = cards
        },
        LOG_OUT(state) {
            Object.assign(state, getDefaultState())
        },
        SET_TOKEN(state, token) {
            state.userToken = token
        },
        SET_SMART_CARDS(state, data) {
            let cards = _.cloneDeep(state.smartcards)
            if (cards.length > 0) {
                cards = data
            } else {
                cards = cards.concat(data)
            }
            state.smartcards = cards
        },
        DEL_SMART_CARDS(state, delCardUserConsumerId) {
            const cards = _.cloneDeep(state.smartcards)
            for (var i = 0; i < cards.length; i++) {
                if (cards[i].user_consumer_id === delCardUserConsumerId) {
                    cards.splice(i, 1)
                    i--
                }
            }
            state.smartcards = cards
        },
        UPDATE_CARD_BALANCE(state, newCard) {
            const cards = _.cloneDeep(state.smartcards)

            cards.forEach(card => {
                if (card.user_consumer_id === newCard.user_consumer_id) {
                    card.balance = card.balance + parseFloat(newCard.amount)
                }
            })
            state.smartcards = cards
        },
        REPLACE_CARD(state, replaceCard) {
            const cards = _.cloneDeep(state.smartcards)
            cards.forEach(card => {
                if (card.user_consumer_id === replaceCard.user_consumer_id) {
                    card.card_number = replaceCard.card_number
                    card.id = replaceCard.id
                }
            })
            state.smartcards = cards
        },
        REPLACE_FAV_ROUTES(state, routeIds) {
            state.user.favorite_routes = routeIds
        },
        SET_ALL_CARD_NOTIFICATION_OPTIONS(state, options) {
            const cards = _.cloneDeep(state.smartcards)

            cards.forEach(card => {
                card.notification_options = options
            })

            state.smartcards = cards
        },
        SET_NOTIFICATION_OPTION(state, option) {
            const cards = _.cloneDeep(state.smartcards)
            cards.forEach(card => {
                if (card.user_consumer_id === option.user_consumer_id) {
                    card.notification_options = option
                }
            })
            state.smartcards = cards
        },
        SET_ALL_CARD_TIMEFRAMES(state, times) {
            const cards = _.cloneDeep(state.smartcards)
            cards.forEach(card => {
                const cardTimeframes = times.map(t => {
                    return {
                        ...t,
                        card_number: card.card_number,
                    }
                })
                card.notification_timeframes = cardTimeframes
            })
            state.smartcards = cards
        },
        SET_TIMEFRAMES_NOTI(state, data) {
            const cards = _.cloneDeep(state.smartcards)
            cards.forEach(card => {
                if (card.user_consumer_id === data.user_consumer_id) {
                    card.notification_timeframes = data.times
                }
            })

            state.smartcards = cards
        },
        SET_ALLOW_CARD_RECEIVE_NOTIFICATION(state, data) {
            const cards = _.cloneDeep(state.smartcards)
            cards.forEach(card => {
                if (card.user_consumer_id === data.user_consumer_id) {
                    card.allow_notifications = data.value
                }
            })
            state.smartcards = cards
        },
        SET_NOTI_FROM_OPERATOR(state, value) {
            state.user.notification_from_fav_operator = value
        },
        SET_CARD_LOW_BALANCE_NOTI(state, value) {
            state.user.smartcard_low_balance_notification = value
        },
        SET_BUS_DELAY_NOTI(state, value) {
            state.user.bus_delays_notification = value
        }
    },
    actions: {
        setCardStripeCharge({ commit }, data) {
            commit('SET_CARD_STRIPE_CHARGE', data)
        },
        setNotiFromOperator({ commit }, data) {
            commit('SET_NOTI_FROM_OPERATOR', data)
        },
        setCardLowBalanceNotification({ commit }, data) {
            commit('SET_CARD_LOW_BALANCE_NOTI', data)
        },
        setBusDelayNotification({ commit }, data) {
            commit('SET_BUS_DELAY_NOTI', data)
        },
        setApnsToken({ commit }, token) {
            commit('SET_APNS_TOKEN', token)
        },
        setPlatform({ commit }, data) {
            commit('SET_PLATFORM', data)
        },

        setAllowCardReceiveNotification({ commit }, data) {
            commit('SET_ALLOW_CARD_RECEIVE_NOTIFICATION', data)
        },
        setAllCardNotificationOptions({ commit }, options) {
            commit('SET_ALL_CARD_NOTIFICATION_OPTIONS', options)
        },
        setNotificationOptions({ commit }, option) {
            commit('SET_NOTIFICATION_OPTION', option)
        },
        setAllCardTimeframes({ commit }, times) {
            commit('SET_ALL_CARD_TIMEFRAMES', times)
        },
        setRouteTrack({ commit }, value) {
            commit('SET_ROUTE_TRACK', value)
        },
        removeRouteTrack({ commit }, value) {
            commit('DEL_ROUTE_TRACK', value)
        },
        setTimeframesNoti({ commit }, data) {
            commit('SET_TIMEFRAMES_NOTI', data)
        },
        setVerifiedEmail({ commit }) {
            commit('SET_VERIFIED_EMAIL')
        },
        setAllCardSendNotification({ commit }, value) {
            commit('SET_ALL_CARD_SEND_NOTIFICATION', value)
        },
        setCurrentLocation({ commit }, location) {
            commit('SET_CURRENT_LOCATION', location)
        },
        setAllCardAutoTopup({ commit }, value) {
            commit('SET_ALL_CARD_AUTO_TOPUP', value)
        },
        setAutoTopup({ commit }, value) {
            commit('SET_AUTO_TOPUP', value)
        },
        setSendNotification({ commit }, value) {
            commit('SET_SEND_NOTIFICATION', value)
        },
        setRenderQrcodeHeader({ commit }, value) {
            commit('SET_RENDER_QRCODE_HEADER', value)
        },
        setRenderSelectOperatorHeader({ commit }, value) {
            commit('SET_RENDER_SELECT_OPERATOR_HEADER', value)
        },
        eraseRouteStops({ commit }) {
            commit('ERASE_ROUTE_STOPS_RECEIVE_NOTI')
        },
        clearRouteStopReceiveNoti({ commit }) {
            commit('CLEAR_ROUTE_STOP_RECEIVE_NOTI')
        },
        fetchUser({ commit }, user) {
            if (user) {
                commit('SET_USER', user)
            } else {
                commit('SET_USER', null)
            }
        },
        updateCardBalance({ commit }, newCard) {
            if (newCard) {
                commit('UPDATE_CARD_BALANCE', newCard)
            }
        },
        setUserPremium({ commit }, isPremium) {
            commit('SET_USER_PREMIUM', isPremium)
        },
        delCardAutoTopupConfig({ commit }, userConsumerId) {
            if (userConsumerId) {
                commit('DELETE_CARD_AUTO_TOPUP_CONFIG', userConsumerId)
            }
        },
        setAutoTopupConfig({ commit }, config) {
            commit('SET_AUTO_TOPUP_CONFIG', config)
        },

        async restoreDataFromLocalStore({ commit }) {
            const userToken = await localStorageSrv.getItem('userToken')
            if (userToken) {
                commit('SET_TOKEN', userToken)
            }
        },
        setToken({ commit, state }, token) {
            if (token) {
                if (state.userToken != token) {
                    commit('SET_TOKEN', token)
                    localStorageSrv.setItem('userToken', token)
                }
            }
        },
        async logOut({ commit }) {
            await localStorageSrv.clear()
            commit('LOG_OUT')
        },
        delSmartCard({ commit }, value) {
            if (value) {
                commit('DEL_SMART_CARDS', value)
            }
        },
        setCompanyXId({ commit }, value) {
            commit('SET_COMPANY_X_ID', value)
        },
        setCardConfigs({ commit }, configs) {
            if (configs && configs.length > 0) {
                commit('SET_AUTO_TOPUP_CONFIGS', configs)
            }
        },
        setConfigToAllCard({ commit }, config) {
            commit('SET_CONFIG_TO_ALL_CARD', config)
        },
        addSmartCard({ commit }, data) {
            commit('ADD_SMART_CARD', data)
        },
        setSmartCards({ commit }, data) {
            commit('SET_SMART_CARDS', data)
        },
        saveOperatorFav({ commit }, value) {
            commit('SET_FAV_OPERATOR', value)
        },
        selectRoutesFav({ commit }, val) {
            commit('SET_FAV_ROUTES', val)
        },
        replaceRoutesfav({ commit }, routeIds) {
            if (routeIds && routeIds.length > 0) {
                commit('REPLACE_FAV_ROUTES', routeIds)
            }
        },
        replaceSmartcard({ commit }, replaceCard) {
            if (replaceCard) {
                commit('REPLACE_CARD', replaceCard)
            }
        },
        selectSmartCardNumber({ commit }, val) {
            if (val) {
                commit('SET_SMART_CARD_NUMBER', val)
            }
        },
        unSelectRouteFav({ commit }, val) {
            if (val) {
                commit('REMOVE_FAV_ROUTES', val)
            }
        },
    },
    plugins: [myPlugin],
})
