<template>
    <ion-button class="btn-card-home" fill="clear" v-on:click="click">
        <ion-card>
            <ion-grid>
                <ion-row class="card-row ion-align-items-center">
                    <ion-col size="4">
                        <img :src="thumbnail" :style="thumbnailStyle" class="thumbnail" aria-hidden="true" alt="" />
                    </ion-col>
                    <ion-col size="8">
                        <ion-text class="card-text ion-text-capitalize">{{ $t(text) }}</ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-card>
    </ion-button>
</template>

<script>
export default {
    name: 'home-card',
    props: ['thumbnail', 'text', 'width', 'click'],
    data: function () {
        return {}
    },
    computed: {
        thumbnailStyle() {
            return {
                width: `${this.width}px`,
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.thumbnail {
    margin-top: 16px;
    height: auto;
}
.btn-card-home {
    text-align: left;
    height: auto;
    width: 100%;
    ion-card {
        width: 100%;
        margin: 15px 10px 10px 10px;
    }
}
.btn-card-home::part(native) {
  padding: 0;
}

.card-row {
    height: 120px;
}
.card-text {
    font-size: 19px;
    font-weight: bold;
    color: var(--ion-color-text-normal);
}
</style>
