import { registerPlugin } from '@capacitor/core'
const UserStoragePlugin = registerPlugin('UserStoragePlugin')
const UserLocalStoragePlugin = registerPlugin('UserLocalStoragePlugin')
import { Capacitor } from '@capacitor/core'

class UserLocalStorageServices {
    constructor() {}

    async getObject(keyName) {
        let ret = null
        const platform = Capacitor.getPlatform()
        if (platform === 'android') {
            ret = await UserLocalStoragePlugin.get({ key: keyName })

            if (ret) {
                try {
                    ret = JSON.parse(ret.value)
                } catch {
                    return ret.value
                }
            }
        }

        if (platform === 'ios') {
            ret = await UserStoragePlugin.getUserStorage({ key: keyName })
        }

        return ret
    }

    async removeObject(platform, keyName) {
        if (platform === 'android') {
            await UserLocalStoragePlugin.remove({ key: keyName })
        } else if (platform === 'ios') {
            await UserStoragePlugin.removeUserStorage({ key: keyName })
        }
    }
}

const srv = new UserLocalStorageServices()
export default srv
