<template>
    <ion-page>
        <simple-header-toolbar :titleName="$t('home')"></simple-header-toolbar>            
        <ion-content class="ion-padding ion-margin-top ">
            <logo :logo="mainLogo" class="logo" />
            <div :class="platform && platform === 'web' ? 'card-group' : ''">
                <card-home
                    v-bind:thumbnail="liveTrackingImg"
                    text="live-tracking"
                    v-if="platform && platform !== 'mobileweb'"
                    width="100"
                    v-bind:click="replaceToLiveTracking"
                    class="single-card"
                    :class="platform && platform === 'web' ? 'single-card' : ''"
                />
                <card-home
                    :class="platform && platform === 'web' ? 'single-card' : ''"
                    v-bind:thumbnail="tmeLogoImg"
                    text="smartcards"
                    width="120"
                    v-bind:click="goToSmartcard"
                />
                <card-home
                    :class="platform && platform === 'web' ? 'single-card' : ''"
                    v-bind:thumbnail="favImg"
                    text="favorite"
                    width="100"
                    v-bind:click="goToFavorite"
                />
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import SimpleHeaderToolbar from "../../components/toolbar/SimpleHeaderToolbar";
import { defineComponent, onMounted, ref, onBeforeUnmount} from 'vue'
import useNavigator from '@/composables/useNavigator'
import appService from '../../util/services/appService'
import logo from '../Logo'
import CardHome from './CardHome'
import store from '../../store'
import { Capacitor } from '@capacitor/core'
import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics'
import { App } from '@capacitor/app'
import GoogleMapPlugins from '../../util/services/googleMapPluginService'
import { onIonViewDidEnter } from '@ionic/vue'

export default defineComponent({
    name: 'Home',
    components: {
        SimpleHeaderToolbar,
        logo,
        CardHome,
    },
    setup() {
        const liveTrackingImg = require('../../assets/live-tracking.png')
        const tmeLogoImg = require('../../assets/smartcard.png')
        const favImg = require('../../assets/favorite.png')
        const backEventHandler = ref(null)

        const { replaceToLiveTracking, goToFavorite, goToSmartcard } = useNavigator
        const mainLogo = require('../../assets/logo.png')

        const setUserId = async () => {
            if (Capacitor.getPlatform() == 'web') {
                return
            }
            await FirebaseCrashlytics.setUserId({
                userId: store.getters.user.id,
            })
        }
        const addBackEventHandler = async () => {
            backEventHandler.value = await appService.addListenerBackBtn(() => {
                App.exitApp()
            })
        }
        onMounted(() => {
            setUserId()
            addBackEventHandler()
            appService.hideSplashScreen()
        })
        onBeforeUnmount(async () => {
            await backEventHandler.value.remove()
        })
        onIonViewDidEnter(() => {
            GoogleMapPlugins.hide()
            GoogleMapPlugins.clear()
            GoogleMapPlugins.close()
            GoogleMapPlugins.removeAllListeners()
        })
        const platform = Capacitor.getPlatform()

        return {
            liveTrackingImg,
            platform,
            tmeLogoImg,
            favImg,
            replaceToLiveTracking,
            goToFavorite,
            goToSmartcard,
            mainLogo,
        }
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (max-width: 320px) {
    .card-ctn {
        justify-content: unset;
    }
}

.card-group {
    margin-top: 30px;
}
.single-card {
    margin-top: 0px;
}

.logo {
    margin-top: 30px;
}
</style>
