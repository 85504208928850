<template>
    <ion-card class="accordion__item">
        <ion-button class="card-action-btn" fill="clear" @click="open" v-if="isShowIndicatorBtn">
            <ion-icon :aria-label="visible ? 'Tap to collapse' : 'Tap to expand'" class="down-icon" :src="visible ? closeOutline : chevronDownOutline"></ion-icon>
        </ion-button>
        
        <div class="accordion__trigger" :class="{ accordion__trigger_active: visible }" @click="open">
            <!-- This slot will handle the title/header of the accordion and is the part you click on -->
            <slot name="accordion-trigger"></slot>
        </div>

        <transition name="accordion" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
            <div class="accordion__content" v-show="visible">
                <!-- This slot will handle all the content that is passed to the accordion -->
                <slot name="accordion-content"></slot>
            </div>
        </transition>
    </ion-card>
</template>

<script>
import { chevronDownOutline, closeOutline } from 'ionicons/icons'
export default {
    props: {
        isShowIndicatorBtn: Boolean,
    },
    inject: ['Accordion'],
    data() {
        return {
            index: null,
            chevronDownOutline,
            closeOutline,
        }
    },
    computed: {
        visible() {
            return this.index == this.Accordion.active
        },
    },
    methods: {
        open() {
            if (this.visible) {
                this.Accordion.active = null
            } else {
                this.Accordion.active = this.index
            }
        },
        start(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        end(el) {
            el.style.height = ''
        },
    },
    created() {
        this.index = this.Accordion.count++
    },
}
</script>

<style lang="scss" scoped>
.accordion__item {
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid #ebebeb;
    position: relative;
}

.accordion__trigger {
    display: flex;
    justify-content: space-between;
}

.accordion-enter-active,
.accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
    height: 0 !important;
    opacity: 0;
}
.card-action-btn {
    position: absolute;
    right: 2px;
    top: 2px;
}
</style>
