import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, concat } from '@apollo/client/core'
import store from './store'
import { auth } from './firebase'
import { onError } from '@apollo/client/link/error'
import appSrv from './util/services/appService'
import authSrv from './util/services/authService'
import alertSrv from './util/services/alertService'
import analyticSrv from './util/services/analyticSrv'
import { ErrorCodes } from './util/constant'
import useNavigator from './composables/useNavigator'

const cache = new InMemoryCache()

// HTTP connection to the API
const httpLink = new HttpLink({
    // You should use an absolute URL here
    uri: process.env.VUE_APP_SERVER_URL,
})

const authMiddleware = new ApolloLink(async (operation, forward) => {
    // add the authorization to the headers
    const companyId = store.getters.companyXId
    let token = await store.getters.localUserToken
    if (auth && auth.currentUser) {
        token = await auth.currentUser.getIdToken()
        store.dispatch('setToken', token)
    }
    const appDeviceInfo = await appSrv.getAppDeviceInfo()
    const getHeaders = () => {
        const result = {
            'x-cid': companyId,
            'x-app': appDeviceInfo.appInfo.getInfo(),
            'x-platform': appDeviceInfo.deviceInfo.platform,
            authorization: `Bearer ${token}`,
        }
        return result
    }

    operation.setContext({
        headers: getHeaders(),
    })

    return forward(operation)
})

const handleGraphQlErrorCode = async (errorCode, error, networkError) => {
    networkError.shouldIgnore = true
    if (errorCode === ErrorCodes.APP_UPDATE_REQUIRED) {
        return appSrv.presentAlert({
            header: 'App Update',
            message: error.message,
            buttons: [
                {
                    text: 'Update',
                    handler: () => {
                        useNavigator.goToMobileStore()
                        if (error.extensions.forceUpdate) {
                            return false
                        }
                    },
                },
            ],
        })
    } else if (errorCode === ErrorCodes.INVALID_PREMIUM_STATUS) {
        return appSrv.presentAlert({
            header: 'Premium Feature',
            message: 'This feature requires premium upgrade.',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'OK',
                    handler: async () => {
                        const { user } = store.getters
                        await authSrv.fetchAndUpdateUserToStore(user)
                        await authSrv.navigationUserAfterLogin()
                    },
                },
            ],
        })
    } else if (errorCode === ErrorCodes.CONCURRENT_UPDATE) {
        return appSrv.presentAlert({
            header: 'Concurrent update',
            message: 'Data was modified by another user.',
            buttons: [
                {
                    text: 'OK',
                    handler: async () => {
                        const { user } = store.getters
                        await authSrv.fetchAndUpdateUserToStore(user)
                        await authSrv.navigationUserAfterLogin()
                    },
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
            ],
        })
    } else if (errorCode === ErrorCodes.ACCOUNT_DELETED) {
        return appSrv.presentAlert({
            header: 'Account deleted',
            message: 'This account has been deleted by another device. Please sign up with a new account to continue using this app.',
            buttons: [
                {
                    text: 'OK',
                    handler: async () => {
                        await authSrv.signOut(true)
                        location.reload()
                    },
                },
            ],
        })
    } else {
        networkError.shouldIgnore = false
    }
}

// Log any GraphQL errors or network error that occurred
const errorLink = onError(errorInfo => {
    const { operation, graphQLErrors, networkError } = errorInfo
    if (graphQLErrors && graphQLErrors.length > 0) {
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
        analyticSrv.logError('GrapQLError', {
            ...operation,
            ...graphQLErrors,
        })
    }

    if (networkError) {
        analyticSrv.logError('NetworkError', {
            ...operation,
            ...networkError,
        })
    }
    if (graphQLErrors && graphQLErrors[0].extensions.errorCode) {
        handleGraphQlErrorCode(graphQLErrors[0].extensions.errorCode, graphQLErrors[0], networkError)
    } else {
        const error = networkError || (graphQLErrors && graphQLErrors[0])
        if (error && error.extensions && error.extensions.code !== 'UNAUTHENTICATED') {
            alertSrv.toastError(error.message, 5000)
        }
    }
})

// Create the apollo client
const apolloClient = new ApolloClient({
    link: concat(concat(authMiddleware, errorLink), httpLink),
    cache,
    connectToDevTools: true,
})

export default apolloClient
