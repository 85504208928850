import { datadogLogs } from '@datadog/browser-logs';

class AnalyticsSrv {
    constructor(appVersion) { 
        this.appVersion = appVersion
    }

    init() {
        datadogLogs.init({
            clientToken: 'pub21f912f00d158b7eeeee2dd02630a8a1',
            site: 'datadoghq.com',
            forwardErrorsToLogs: true,
            sampleRate: 100,
            version: this.appVersion,
            env: process.env.VUE_APP_ENV
        });
    }
    setUserInfo(user) {
        console.log('User info', user)
    }

    logInfo(msg, data) {
        datadogLogs.logger.info(msg, data);
    }

    logError(msg, err) {
        datadogLogs.logger.error(msg, err);
    }
}

const srv = new AnalyticsSrv()
export default srv