import gql from 'graphql-tag';

export const GET_COMPANIES = gql`
    query activeCompanies {
        activeCompanies {
            company_id
            name
            privacy_text
            timezone
        }
    }
`;
