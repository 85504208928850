import appService from "../services/appService";
import store from "../../store";
import stripeApi from "../apis/stripe";

class InAppPurchaseApi {

    constructor() { }
    
    async verifyUserPurchase() {
        try {
          const response = await stripeApi.verifyUserPurchase();
          if (
            response &&
            response.data.verifyUserPurchase &&
            response.data.verifyUserPurchase.status === "Success"
          ) {
            store.dispatch("setUserPremium", true);
            return true;
          }
        } catch (e) {
          console.log("verifyUserPurchase", e);
          appService.presentAlert({
            header: "Premium error",
            message: e.message,
            buttons: ["CANCEL"],
          });
        }
    }
}


const srv = new InAppPurchaseApi();
export default srv;