<template>
    <ion-grid ref="content">
        <ion-row>
            <ion-col size="4">
                <ion-text class="text-body2-style">{{ transaction.displayDate }}</ion-text>
            </ion-col>
            <ion-col size="5">
                <div class="col-row-cont">
                    <ion-icon :icon="informationCircleOutline" class="icon-info"></ion-icon>
                    <ion-text class="text-body2-style">{{ TransactionTypeLayOut[transaction.transaction_type] }}</ion-text>
                </div>
            </ion-col>
            <ion-col size="3" class="align-end">
                <ion-text class="text-body2-style ion-text-nowrap">{{ transaction.displayAmount }}</ion-text>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script>
import { TransactionTypeApi, TransactionTypeLayOut } from '../../util/constant'
import { informationCircleOutline } from 'ionicons/icons'
export default {
    name: 'TransactionTypeManual',
    props: {
        transaction: Object,
    },
    data: function () {
        return {
            TransactionTypeApi,
            TransactionTypeLayOut,
            informationCircleOutline: informationCircleOutline,
            routeImg: require('../../assets/location_arrow.png'),
            boardingImg: require('../../assets/bus_stop.png'),
            deboardingImg: require('../../assets/bus_ac_wc.png'),
            transactionImg: require('../../assets/imoney-bag-48.png'),
        }
    },
    methods: {},
}
</script>

<style scoped>
.col-row-cont {
    display: flex;
    flex-direction: row;
}
.icon-desc-info {
    min-width: 20px;
}
ion-img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.align-end {
    justify-content: flex-end;
    display: flex;
}
.align-center {
    justify-content: center;
    display: flex;
}
</style>
