<template>
    <ion-page>
        <ion-content class="ion-padding sign-in-content">
            <logo :logo="mainLogo" class="main-logo" />

            <ion-button mode="ios" expand="block" color="secondary" class="sign-up-btn btn-radius" @click="goToSignUp">
                <ion-text class="btn-text">{{ $t('new-sign-up') }}</ion-text>
            </ion-button>

            <div class="separate-line">
                <ion-text class="line-text">or</ion-text>
            </div>

            <ion-button
                v-if="showSocialAuthentication"
                color="shade"
                mode="ios"
                expand="block"
                class="google-login-btn btn-radius"
                @click="signInGoogle"
            >
                <ion-icon name="logo-google-color" class="social-icon google"></ion-icon>
                <ion-text class="btn-text">{{ $t('sign-in-google') }}</ion-text>
            </ion-button>

            <ion-button
                v-if="isOpenAppleSignIn && showSocialAuthentication"
                expand="block"
                mode="ios"
                color="dark"
                class="ion-margin-top ion-text-capitalize btn-radius"
                @click="signInApple"
            >
                <ion-icon :icon="logoApple" class="social-icon apple"></ion-icon>
                <ion-text class="btn-text">{{ $t('sign-in-apple') }}</ion-text>
            </ion-button>

            <div class="separate-line" v-if="showSocialAuthentication">
                <ion-text class="sign-in-text">{{ $t('sign-in-text') }}</ion-text>
            </div>

            <user-authentication mode="SignIn" />

            <ion-fab vertical="bottom" horizontal="end" slot="fixed" class="help-fab">
                <ion-fab-button @click="openBeacon">
                    <ion-icon :src="helpOutline" class="help-icon" aria-label="Help button"></ion-icon>
                </ion-fab-button>
            </ion-fab>
        </ion-content>
    </ion-page>
</template>

<script>
import logo from '../components/Logo'
import { logoApple, helpOutline } from 'ionicons/icons'
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'
import UserAuthentication from '../components/home/UserAuthentication'
import authApi from '../util/apis/auth'
import useNavigator from '@/composables/useNavigator'
import appVariantSrv from '../util/services/appVariantService'
import authSrv from '../util/services/authService'
import appService from '../util/services/appService'
import analyticSrv from '../util/services/analyticSrv'
import authAppleService from '../util/services/authAppleService'
import { Device } from '@capacitor/device'
import { SignInWithApple } from '@capacitor-community/apple-sign-in'
import { cfaSignInGoogle } from 'capacitor-firebase-auth'
import { Capacitor } from '@capacitor/core'

export default defineComponent({
    name: 'SignIn',
    components: {
        logo,
        UserAuthentication,
    },
    setup() {
        const platform = Capacitor.getPlatform()
        const mainLogo = require('../assets/logo.png')
        const showSocialAuthentication = appVariantSrv.isFeatureEnabled('socialAuth')
        let backBtnEventHandler = null
        const goToSignUp = () => {
            useNavigator.goToSignUp()
        }
        const auth = async (user, provider) => {
            try {
                const isExistedUid = await authApi.checkExistUid(user.uid)

                if (isExistedUid && isExistedUid.data && isExistedUid.data.checkExistUid > 0) {
                    await authSrv.fetchAndUpdateUserToStore(user)
                    authSrv.navigationUserAfterLogin()
                } else {
                    // check old user already has data
                    const userOldData = await authSrv.getPaxUserOldData()
                    if (userOldData) {
                        const uid = (await appService.getDeviceInfo()).uid
                        await authSrv.updateUserMigrateAndSave(userOldData, user, uid)
                        // delete deviceId old pax_app
                        await authSrv.removePaxUserOldData()
                    } else {
                        await authSrv.createAndSaveUser(user, provider)
                    }
                    await appService.prepareApp()
                    // navigate to favorite
                    useNavigator.replaceToFavorite({ routeMode: 'operatorFav' })
                }
                appService.dismissLoading()
            } catch (err) {
                appService.dismissLoading()
                throw err.message
            }
        }

        const signInGoogle = () => {
            return confirmSignUpAgreement(async () => {
                appService.presentLoading()
                cfaSignInGoogle().subscribe(
                    user => {
                        auth(user, 'google')
                    },
                    err => {
                        console.log('signInGoogle', err)
                        appService.dismissLoading()
                    }
                )
                appService.dismissLoading()
            })
        }
        const isOpenAppleSignIn = ref(false)

        const checkOSToAppleSignIn = async () => {
            isOpenAppleSignIn.value = await authSrv.checkOsToAppleSignIn()
            console.log('checkOSToAppleSignIn', isOpenAppleSignIn.value)
        }
        onMounted(async () => {
            appService.hideSplashScreen()
            backBtnEventHandler = await appService.addListenerBackBtn(() => {
                window.Beacon('close')
            })
            checkOSToAppleSignIn()
        })

        onBeforeUnmount(async () => {
            if (backBtnEventHandler) {
                await backBtnEventHandler.remove()
            }
        })

        const confirmSignUpAgreement = async onOK => {
            await appService.presentAlert({
                header: 'Terms & Conditions',
                message: 'By clicking OK, you agree to our Terms, Data Policy and Cookie Policy',
                buttons: [
                    {
                        text: 'VIEW',
                        handler: async () => {
                            var url = 'https://transportme.com.au/security/'
                            window.open(url, '_system', 'location=yes')
                        },
                    },
                    {
                        text: 'OK',
                        handler: async () => {
                            onOK && onOK()
                        },
                    },
                ],
            })
        }

        const signInApple = async () => {
            return confirmSignUpAgreement(async () => {
                appService.presentLoading()
                let device = await Device.getInfo()
                if (device.operatingSystem === 'ios') {
                    SignInWithApple.authorize({
                        scopes: 'email name',
                    })
                        .then(async res => {
                            const credential = authAppleService.createCredential(res.response)
                            const fbUser = await authAppleService.createCredentialFirebaseUser(credential)
                            if (!res.response.email && !fbUser.user.email) {
                                appService.dismissLoading()
                                await authSrv.openReloginAppleMessModal()
                                return
                            }
                            if (res.response.email && !fbUser.user.email) {
                                await fbUser.user.updateEmail(res.response.email)
                                await authApi.updateFbUserEmail()
                                await fbUser.user.reload()
                                await auth(fbUser.user, 'apple')
                            } else {
                                await auth(fbUser.user, 'apple')
                            }
                            appService.dismissLoading()
                        })
                        .catch(err => {
                            console.error(err)
                            appService.dismissLoading()
                            handleSocialLoginError('Apple Sign In', 'Unable to authenticate user', err)
                        })
                } else if (device.platform === 'web') {
                    const appleLoginUser = await authAppleService.signInWithAppleInBrowser()
                    if (!appleLoginUser.email) {
                        appService.dismissLoading()
                        await authSrv.openReloginAppleMessModal()
                        return
                    } else {
                        await auth(appleLoginUser, 'apple')
                    }
                }
            })
        }

        const handleSocialLoginError = async (title, message, data) => {
            analyticSrv.logError(title + ' ' + message, data)
            await appService.presentAlert({
                header: title,
                message: message,
                buttons: [
                    {
                        text: 'OK',
                        handler: async () => {},
                    },
                ],
            })
        }

        function openBeacon() {
            window.Beacon('open')
        }

        return {
            signInGoogle,
            showSocialAuthentication,
            openBeacon,
            logoApple,
            helpOutline,
            platform,
            mainLogo,
            signInApple,
            goToSignUp,
            checkOSToAppleSignIn,
            isOpenAppleSignIn,
        }
    },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sign-in-content {
    text-align: center;
}
.social-icon {
    font-size: 36px;
    position: absolute;
    left: 20px;
}

.social-icon.google {
    width: 26px;
    height: 26px;
    background: white;
    border-radius: 50%;
    padding: 5px;
}
.sign-up-btn {
    opacity: 0.8;
    margin-top: 20px;
    text-transform: capitalize;
}
.separate-line {
    margin-top: 18px;
    position: relative;
    text-align: center;
}

.line-text {
    font-size: 12px;
    color: var(--ion-color-medium-shade);
    padding: 12px;
    background-color: white;
}
.item-border {
    /* border: 1px solid #dfdfdf; */
    border-bottom: none;
}
.separate-line::before {
    border-top: 1px solid #dfdfdf;
    content: '';
    margin: 0 auto; /* this centers the line to the full width specified */
    position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
    top: 50%;
    left: 0;
    z-index: -1;
    right: 0;
    bottom: 0;
    width: 95%;
}

.google-login-btn {
    text-transform: capitalize;
    margin-top: 20px;
    color: white;
    background-color: #c0392b;
    border-radius: 4px;
}
.btn-radius {
    --border-radius: 4px;
}
.help-icon {
    font-size: 24px;
    color: white;
}
.separate-line span {
    background: #fff;
    padding: 0 15px;
}
.input-signin {
    border-bottom: none !important;
    box-shadow: none;
    border-bottom-color: none;
}
.item-container {
    border: 1px solid #dfdfdf;
    position: relative;
    padding: 0 12px;
    margin-top: 24px;
    border-radius: 4px;
}
.option-text {
    font-size: 14px;
    color: var(--ion-color-dark);
    text-align: center;
}
.main-logo {
    margin-top: calc(env(safe-area-inset-top) + 16px);
}
.note-text {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    font-weight: 400;
    text-align: center;
}
.btn-text {
    font-size: 14px;
    color: white;
    font-weight: 700;
    text-align: center;
    line-height: 16px;
}
.sign-in-text {
    font-size: 14px;
    color: black;
    padding: 12px;
    background-color: white;
}
.help-fab {
    right: 20px;
}
</style>
