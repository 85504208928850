<template>
  <ion-toolbar class="ion-text-center" mode="md" aria-hidden="true">
    <img :src="logo" class="logo-tme" alt=""/>
  </ion-toolbar>
</template>
<script>
import { defineComponent } from 'vue';
import { IonToolbar } from '@ionic/vue';
export default defineComponent(
  {
  name: "Logo",
  components: {
    IonToolbar
  },
  props: {
    logo: String
  },
})
</script>
<style scoped>
.logo-tme {
  width: 90%;
  height: auto;
}
ion-toolbar {
  --background: none;
}

</style>
