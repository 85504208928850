<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start" class="home-btn">
                    <ion-button @click="goHome" aria-label="Go Home">
                        <ion-icon :icon="home"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title class="ion-text-capitalize">Email verification</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <div class="content-screen">
                <ion-card>
                    <ion-card-content class="card-content">
                        <ion-label class="text-headline6-style label-color">{{ $t('verify-email') }}</ion-label>
                    </ion-card-content>
                </ion-card>
                <ion-button
                    fill="outline"
                    size="default"
                    :disabled="sendingVerificationMail"
                    @click="resendEmailVerification"
                    >Resend verification email</ion-button
                >
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { home } from 'ionicons/icons'
import { auth } from '../firebase'
import useNavigator from '../composables/useNavigator'
import authSrv from '../util/services/authService'
import appService from '../util/services/appService'

export default {
    name: 'VerifyEmail',
    data: function() {
        return {
            home,
            exitCheckFbUser: null,
            sendingVerificationMail: false
        }
    },
    mounted: async function() {
        const exitInterval = setInterval(async () => {
            // reload auth data
            await auth.currentUser.reload()
            const currentFbUser = auth.currentUser
            console.log('VerifyEmail', currentFbUser)
            if (currentFbUser && currentFbUser.emailVerified) {
                await authSrv.fetchAndUpdateUserToStore(currentFbUser)
                useNavigator.replaceToFavorite({ routeMode: 'operatorFav' })
                clearInterval(exitInterval)
            }
        }, 5000)
        this.exitCheckFbUser = exitInterval
    },
    methods: {
        async resendEmailVerification() {
            try {
                this.sendingVerificationMail = true
                await auth.currentUser.sendEmailVerification()
                await appService.presentAlert({
                    header: 'Email sent',
                    message: this.$t('verify-email'),
                    buttons: ['OK'],
                })
            } catch (e) {
                this.sendingVerificationMail = false
            }
            setTimeout(() => {
                this.sendingVerificationMail = false
            }, 10000)
        },
        goHome() {
            this.$router.replace('/')
        },
    },
    beforeUnmount() {
        clearInterval(this.exitCheckFbUser)
    },
}
</script>
<style scoped>
.content-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.card-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
}
ion-card {
    height: auto;
}
ion-icon {
    font-size: 25px;
    color: var(--ion-color-tertiary);
    margin-right: 15px;
}
.label-color {
    color: #1073ba;
    text-align: center;
}
.home-btn {
    margin-left: 12px;
}
ion-icon {
    color: white;
}
</style>
