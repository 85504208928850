<template>
    <div>
        <div class="item-container">
            <ion-label
                id="email-label"
                aria-hidden="true"
                :class="
                    platform && platform === 'ios'
                        ? 'ion-text-capitalize label-absolute-top'
                        : 'ion-text-capitalize label-android-absolute-top'
                "
                position="floating"
                >{{ $t('email') }}</ion-label
            >
            <input
                type="email"
                ref="userEmail"
                aria-labelledby="email-label"
                v-model="form.email"
                v-on:keypress="eventHandler($event.key, 'email')"
            />
        </div>

        <div class="item-container">
            <ion-label
                id="password-label"
                aria-hidden="true"
                :class="
                    platform && platform === 'ios'
                        ? 'ion-text-capitalize label-absolute-top'
                        : 'ion-text-capitalize label-android-absolute-top'
                "
                position="floating"
                >{{ $t('password') }}</ion-label
            >
            <input
                type="password"
                ref="userPass"
                aria-labelledby="password-label"
                v-model="form.password"
                v-on:keypress="eventHandler($event.key, 'password')"
            />
        </div>
        <div class="item-container" v-if="mode !== 'SignIn'">
            <ion-label
                id="confirm-password-label"
                aria-hidden="true"
                :class="
                    platform && platform === 'ios'
                        ? 'ion-text-capitalize label-absolute-top'
                        : 'ion-text-capitalize label-android-absolute-top'
                "
                position="floating"
                >{{ $t('confirm-pass') }}</ion-label
            >
            <input
                type="password"
                ref="userConfirmPass"
                aria-labelledby="confirm-password-label"
                v-model="form.confirmPassword"
                v-on:keypress="eventHandler($event.key, 'confirmPassword')"
            />
        </div>

        <ion-button
            mode="ios"
            expand="block"
            class="ion-text-capitalize ion-margin-top login-btn"
            @click="login"
            v-if="mode === 'SignIn'"
            ref="loginBtn"
        >
            <ion-spinner name="lines" v-if="loading"></ion-spinner>
            <span class="btn-text" v-else>{{ $t('login') }}</span>
        </ion-button>
        <div class="signup-action-content" v-if="mode === 'SignUp'">
            <password-rule></password-rule>
            <div class="term-policy-content">
                <a :href="HREF_TRANSPORTME" target="_blank">{{ $t('signup-note') }}</a> 
            </div>
            <ion-button
                mode="ios"
                expand="block"
                @click="handleSignUp"
                class="ion-text-capitalize ion-margin-top login-btn"
                ref="signUpBtn"
            >
                <ion-spinner name="lines" v-if="loading"></ion-spinner>
                <span class="btn-text" v-else>{{ $t('sign-up') }}</span>
            </ion-button>
        </div>
        <ion-grid v-if="mode === 'SignIn'">
            <ion-row class="ion-justify-content-center">
                <ion-col size="12" class="ion-align-self-end forget-col">
                    <ion-button
                        @click="sendEmailResetPass"
                        :disabled="loading"
                        color="dark"
                        fill="clear"
                        size="small"
                        class="option-text ion-text-capitalize btn-forgot-psw"
                        >{{ $t('forgot-password') }}?</ion-button
                    >
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { Capacitor } from '@capacitor/core'
import { auth } from '../../firebase'
import authApi from '../../util/apis/auth'
import appService from '../../util/services/appService'
import helperSrv from '../../util/helper'
import useNavigator from '@/composables/useNavigator'
import authSrv from '../../util/services/authService'
import errorHandlerSrv from '../../util/services/errorHandlerService'
import PasswordRule from '../../components/rules/PasswordRule'
import { Keyboard } from '@capacitor/keyboard'
import appVariantSrv from '../../util/services/appVariantService'
const HREF_TRANSPORTME = appVariantSrv.getTermsPrivacyLink()
export default defineComponent({
    name: 'UserAuthentication',
    components: {
        PasswordRule,
    },
    props: ['mode'],
    setup(props) {
        //Component refs
        const userPass = ref(null)
        const userConfirmPass = ref(null)
        const signUpBtn = ref(null)
        const loginBtn = ref(null)
        const form = ref({ email: '', password: '', confirmPassword: ''})
        const loading = ref(false)
        const authFbErrCode = {
            INVALID_EMAIL: 'auth/invalid-email',
            WRONG_PASS: 'auth/wrong-password',
            USER_NOT_FOUND: 'auth/user-not-found',
        }
        const prohibitEmail = ['yopmail.com', 'maildrop.cc']
        const alertTitle = {
            FORGOT_PASS: 'Forgot password',
            SIGN_UP: 'Sign up',
            LOGIN: 'Login',
        }
        const eventHandler = (key, type) => {
            if (key === 'Enter') {
                if (type === 'email') {
                    userPass.value.focus()
                } else if (type === 'password') {
                    if (props.mode == 'SignIn') {
                        loginBtn.value.$el.click()
                    } else {
                        userConfirmPass.value.focus()
                    }
                } else if (type == 'confirmPassword') {
                    signUpBtn.value.$el.click()
                    Keyboard.hide()
                }
            }
        }

        const hasProhibitEmail = email => {
            return prohibitEmail.find(e => email && email.endsWith(e))
        }
        const validateEmailPasswordInClient = (type) => {
            const email = form.value.email.trim()
            if (!email.includes('@')) {
                appService.showAlert('Please include an @ in email address', 'Invalid email')
                return false
            }
            if (!email.includes('.')) {
                appService.showAlert('Please include an . in email address', 'Invalid email')
                return false
            }
            if (!helperSrv.validateEmail(form.value.email)) {
                appService.showAlert('Incorrect email format', 'Invalid email')
                return false
            }
            if (hasProhibitEmail(email)) {
                appService.showAlert('This mailbox is not supported', 'Invalid email')
                return false
            }

            const password = form.value.password
            if (!authSrv.validatePassword(password)) {
                appService.showAlert(
                    'Password must include at least 8 characters, 1 capitalize letter, 1 lowercase letter and 1 number',
                    'Invalid password'
                )
                return false
            }

            if (type == 'signUp') {
                if (!authSrv.validatePassword(form.value.confirmPassword)) {
                    appService.showAlert(
                        'Confirm password must include at least 8 characters, 1 capitalize letter, 1 lowercase letter and 1 number',
                        'Invalid password'
                    )
                    return false
                }

                if (form.value.confirmPassword && password !== form.value.confirmPassword) {
                    appService.showAlert('The password and confirmation password do not match', 'Password error')
                    return false
                }
            }
            return true
        }
        const login = async () => {
            if (!validateEmailPasswordInClient('login')) {
                return
            }
            loading.value = true
            try {
                const data = await auth.signInWithEmailAndPassword(form.value.email, form.value.password)
                loading.value = false
                if (data && data.user && data.user.emailVerified) {
                    await authSrv.fetchAndUpdateUserToStore(data.user)
                    authSrv.navigationUserAfterLogin()
                } else {
                    useNavigator.goVerifyEmail()
                }
            } catch (err) {
                if (!errorHandlerSrv.isHandled(err)) {
                    showAuthFbAlert(err, alertTitle.LOGIN)
                }
                authApi.authError()
            } finally {
                loading.value = false
            }
        }

        const createFireBaseUser = async (email, password) => {
            return await auth.createUserWithEmailAndPassword(email, password)
        }
        const signUp = async () => {
            const resSignUp = await createFireBaseUser(form.value.email, form.value.password)
            if (resSignUp && resSignUp.user) {
                await authSrv.createAndSaveUser(resSignUp.user, 'emailAndPassword')

                await goToVerifyEmail()
            }
        }
        const goToVerifyEmail = async () => {
            await auth.currentUser.sendEmailVerification()
            useNavigator.goVerifyEmail()
        }

        const migrate = async oldUserData => {
            const resSignUp = await createFireBaseUser(form.value.email, form.value.password)
            if (resSignUp && resSignUp.user) {
                const uid = (await appService.getDeviceInfo()).uid
                await authSrv.updateUserMigrateAndSave(oldUserData, resSignUp.user, uid)
                // delete deviceId old pax_app
                await authSrv.removePaxUserOldData()
                await goToVerifyEmail()
            }
        }

        const handleSignUp = async () => {
            if (!validateEmailPasswordInClient('signUp')) {
                return
            }

            try {
                loading.value = true
                // check old user already has data
                const userOldData = await authSrv.getPaxUserOldData()

                if (userOldData) {
                    await migrate(userOldData)
                } else {
                    await signUp()
                }
                loading.value = false
            } catch (err) {
                loading.value = false
                showAuthFbAlert(err, alertTitle.SIGN_UP)
                authApi.authError()
            }
        }

        const showAuthFbAlert = (err, title) => {
            if (err) {
                switch (true) {
                    case err.code === authFbErrCode.INVALID_EMAIL &&
                        !form.value.email &&
                        !form.value.password &&
                        title !== alertTitle.FORGOT_PASS:
                        appService.showAlert('Please enter email and password', title)
                        break
                    case err.code === authFbErrCode.INVALID_EMAIL &&
                        !form.value.email &&
                        !form.value.password &&
                        title === alertTitle.FORGOT_PASS:
                        appService.showAlert('Please enter email address to reset password', title)
                        break
                    case err.code === authFbErrCode.INVALID_EMAIL &&
                        !form.value.email &&
                        title === alertTitle.FORGOT_PASS:
                        appService.showAlert('Please enter email address to reset password', title)
                        break
                    case err.code === authFbErrCode.INVALID_EMAIL:
                        appService.showAlert('Incorrect email format', title)
                        break
                    case err.code === authFbErrCode.WRONG_PASS:
                    case err.code === authFbErrCode.USER_NOT_FOUND:
                        if (title === alertTitle.FORGOT_PASS) {
                            appService.showAlert('Invalid email', title)
                        } else {
                            appService.showAlert(
                                'Invalid email or password. If you have not registered a Passenger App account please Sign Up.',
                                title
                            )
                        }

                        break
                    default:
                        appService.showAlert(err.message, title)
                }
            }
        }

        const sendEmailResetPass = () => {
            if (hasProhibitEmail(form.value.email)) {
                appService.showAlert('This mailbox is not supported', 'Invalid email')
                return
            }
            loading.value = true
            auth.sendPasswordResetEmail(form.value.email)
                .then(data => {
                    console.log('###sendPasswordResetEmail', data)
                    appService.showAlert('Please check your email for password reset instruction.', 'Forgot password')
                    loading.value = false
                })
                .catch(err => {
                    showAuthFbAlert(err, 'Forgot password')
                    loading.value = false
                })
        }

        const platform = Capacitor.getPlatform()
        return {
            eventHandler,
            form,
            migrate,
            login,
            createFireBaseUser,
            loading,
            signUp,
            sendEmailResetPass,
            showAuthFbAlert,
            handleSignUp,
            platform,
            userPass,
            userConfirmPass,
            loginBtn,
            signUpBtn,
            HREF_TRANSPORTME,
        }
    },
})
</script>
<style scoped>
.item-container {
    border: 1px solid #dfdfdf;
    position: relative;
    padding: 0 12px;
    margin-top: 24px;
    border-radius: 4px;
}

.login-btn {
    color: white;
    --border-radius: 4px;
}

input {
    height: 60px;
    border: none;
    width: 100%;
    outline: none;
}

.option-text {
    font-size: 14px;
    white-space: nowrap;
    /* padding-left: 5px; */
}

.option-text-sign-up {
    font-size: 14px;
    white-space: nowrap;
    /* padding-right: 5px; */
    color: var(--ion-color-primary);
}

.forget-col {
    bottom: 8px;
    text-align: center;
}
.btn-text {
    font-size: 14px;
    color: white;
    font-weight: 700;
    text-align: center;
    line-height: 16px;
}

.btn-forgot-psw {
    text-decoration: underline;
}
.signup-action-content {
    margin-top: 20px;
}
.term-policy-content {
    width: 100%;
    text-align: center;
    font-size: 14;
}
</style>

