
import gql from 'graphql-tag'

export const GET_USER = gql`
query paxUser {   
  paxUser {
      id
      email
      auto_topup
      notification_from_cards
      all_card_auto_topup
      all_card_send_notification
      fav_operator_id
      favorite_operator {
        name
        website
        longitude
        latitude
      }
      favorite_routes {
        id
        operator_id
        route_id
      }
      is_premium
      email_verify
      notification_from_fav_operator
      smartcard_low_balance_notification
      bus_delays_notification
      user_hash_id
  }
}`;

export const CHECK_EXIST_UID = gql`
query checkExistUid($user_uid: String!) {   
  checkExistUid(user_uid: $user_uid) 
}`;

export const CHECK_EXIST_USER_DEVICE = gql`
query checkExistPaxUserDevice($device_id: String!) {   
  checkExistPaxUserDevice(user_uid: $user_uid) 
}`;

export const COUNT_USER_CHECKED_PRIVACY = gql`query countUserCheckedPrivacy($operator_id: Int!) {
  countUserCheckedPrivacy(operator_id: $operator_id)
}`;


export const EXISTED_USER = gql`query paxUserCount($email: String!) {
  paxUserCount(email: $email)
}`