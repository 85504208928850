<template>
    <ion-app>
        <ion-router-outlet id="app-router-outlet" :style="{ maxWidth: maxWidth}"/>
        <qr-code-header v-if="showingQRscanner" id="qrCode-header" :backFunction="closeScanScreen" :titleName="$t('scan-qr-title')"></qr-code-header>
    </ion-app>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { onMounted } from 'vue'
import { Capacitor } from '@capacitor/core'
import { TextZoom } from '@capacitor/text-zoom';
import authApi from './util/apis/auth'
import authSrv from '@/util/services/authService'
import appSrv from './util/services/appService'
import analyticsSrv from './util/services/analyticSrv'
import localStorageSrv from './util/services/storageService'
import qrScanService from './util/services/qrScanService'
import QrCodeHeader from './components/toolbar/QrCodeHeaderToolbar.vue'
import { useStore } from 'vuex'
import { App } from '@capacitor/app'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Keyboard } from '@capacitor/keyboard'
//import alertSrv from './util/services/alertService'

export default defineComponent({
    name: 'App',
    components: {
        QrCodeHeader,
    },
    setup() {
        const store = useStore()
        const refreshUserInfo = async () => {
            await authApi.refreshUserInfo()
            appSrv.prepareApp()
            authApi.updateUserDeviceInfo()
        }
        const showingQRscanner = computed(() => store.getters.showingQRscanner)
        const getPlatformData = async () => {
            const platforms = Capacitor.getPlatform()

            store.dispatch('setPlatform', platforms)
            await store.dispatch('restoreDataFromLocalStore')
            const isLoggedIn = await authSrv.hasCurrentLoggedInUser()

            if (isLoggedIn) {
                analyticsSrv.setUserInfo(await store.getters.localUser)
                refreshUserInfo()
            }
        }

        const closeScanScreen = () => {
            qrScanService.exitScan()
        }

        const resetLocalStorage = () => {
            appSrv.setOpeningPopupStatus(false)

        }

        const setupStatusBarStyle = async () => {
            console.log("setupStatusBarStyle")
            await StatusBar.setStyle({ style: Style.Dark });
        };
        const setupKeyboard = () => {
            Keyboard.setAccessoryBarVisible({isVisible: true});
        }
        const setupTextZoom = async () => {
            const zoomLevel = (await TextZoom.getPreferred()).value;
            TextZoom.set({ value: zoomLevel > 2? 2: zoomLevel });
        }
        const initApp = () => {
            if (process.env.VUE_APP_ENV != 'production') {
                //alertSrv.toastWarning('WARNING: NOT PRODUCTION config: ' + process.env.VUE_APP_ENV)
            }
            setupKeyboard();
            setupStatusBarStyle();
            setupTextZoom();

            getPlatformData()

            resetLocalStorage()

            window.Beacon('init', process.env.VUE_APP_BEACON_ID)
            window.Beacon('config', {
                hideFABOnMobile: true,
                hideFABLabelOnMobile: true,
                display: {
                    style: 'manual',
                },
            })
            window.Beacon('on', 'open', async () => {
                appSrv.setOpeningPopupStatus(true)

                let user = await store.getters.user
                if (user) {
                    window.Beacon('prefill', {
                        email: user.email,
                    })
                }
            })
            window.Beacon('on', 'close', () => {
                appSrv.setOpeningPopupStatus(false)
            })
        }

        onMounted(() => {
            initApp()
        })

        if (Capacitor.getPlatform() !== 'web') {
            App.addListener('appStateChange', ({ isActive }) => {
                if (isActive) {
                    localStorageSrv.getItem('didSetupPurchase').then((didSetupPurchase) => {
                        if (didSetupPurchase == 'true') {
                            // Skip this refresh otherwise In App Purchase won't work
                            return
                        }
                        // codePush.sync()
                        if (store.getters.user) {
                            refreshUserInfo()
                        }
                    })
                    setupTextZoom();
                }
            })
        } else {
            document.getElementsByTagName('body')[0].style.background = '#9d9fa6'
        }

        let maxWidth = "1024px"
        if (['android', 'ios'].indexOf(Capacitor.getPlatform()) >= 0) {
            maxWidth = '100%'
        }

        return {
            closeScanScreen,
            showingQRscanner,
            maxWidth
        }
    },
})
</script>
<style>
.my-alert-style .alert-wrapper {
    border-radius: 5px;
}
.my-alert-style .alert-head {
    padding-bottom: 12px;
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.87;
}

.ion-page {
    background: white;
}

ion-app.ion-page {
    background: transparent;
}

ion-picker {
    touch-action: none;
}
.input-label {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #000000;
}
.font-weight-normal {
    font-weight: normal !important;
}
.my-alert-style .alert-button {
    border-top: none;
}
ion-toolbar {
    --background: var(--ion-color-primary);
    --color: white;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
}
.my-alert-style .alert-message {
    font-size: 14px;
    padding-bottom: 0px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #979797;
    text-align: left;
}
.my-alert-style .alert-button .alert-button-inner {
    align-items: flex-end;
    justify-content: flex-end;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    color: #4f4f4f;
    padding-right: 15px;
    padding-bottom: 12px;
}
.checkout-modal-class .modal-wrapper {
    width: 100%;
    height: 30%;
}
.alert-checkout .alert-wrapper .alert-button-group {
    flex-wrap: nowrap;
}
.text-body2-style {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--ion-color-text-normal);
}
.text-bold {
    font-weight: bold;
}
.text-headline6-style {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--ion-color-text-normal);
}
.text-overline-style {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: capitalize;
    color: var(--ion-color-text-normal);
}
.col-flex-end {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.card-title-style {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-transform: capitalize;
}

.arrow-back-icon {
    margin-left: 15px;
    width: 24px;
    height: 24px;
    color: white;
}
.single-popover .popover-content {
    width: 100%;
    left: 0px;
}
.single-popover .popover-arrow {
    display: none;
}
ion-tab-button {
    --color-selected: var(--ion-color-tertiary);
    --color: var(--ion-color-tertiary-tint-2);
}
.label-absolute-top {
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    top: -36px;
    background-color: white;
    z-index: 1;
    padding: 0 5px;
    padding-right: 8px;
    color: var(--ion-color-text-normal);
}
.label-android-absolute-top {
    font-size: 12px;
    position: absolute;
    top: -20px;
    font-weight: 400;
    background-color: white;
    color: var(--ion-color-text-normal);
    z-index: 1;
    padding: 0 5px;
}
.label-android-addcard-absolute-top {
    font-size: 12px;
    position: absolute;
    top: -12px;
    background-color: white;
    z-index: 1;
    padding: 0 5px;
}
.tab-label-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    text-align: center;
    letter-spacing: 0.4px;

    color: #1e54a2;
}
/* verify email modal */
.verify-email-modal .modal-wrapper {
    width: calc(4em * 5);
    left: calc(50% - (600px / 2));
    height: calc(3.5em * 3);
    top: calc(50% - (500px / 2));
    border-radius: 10px;
}

/* link smartcard modal */
.link-smartcard-account-class .modal-wrapper {
    width: 600px;
    height: 80%;
}

.direct-debit-modal-class-web .modal-wrapper {
    width: 600px;
    height: 100%;
}

/* apple login message modal */
.apple-login-mess-modal .modal-wrapper {
    width: 320px;
    height: 480px;
    border-radius: 10px;
}

/* route bus infor modal style */
.bus-infor-modal .modal-wrapper {
    width: calc(4em * 5);
    left: calc(50% - (600px / 2));
    height: calc(4em * 4);
    top: calc(50% - (500px / 2));
    border-radius: 6px;
}
/* setting bottom sheet live tracking map style */
.setting-bottom-sheet-modal .modal-wrapper {
    width: calc(4em * 5);
    height: 200px;
    border-radius: 6px;
}
.user-privacy-modal .modal-wrapper {
    width: calc(4em * 5);
    left: calc(50% - (600px / 2));
    height: calc(6em * 5);
    top: calc(50% - (500px / 2));
    border-radius: 6px;
}
/* google maps */
.google-map.gm-style.gmnoprint {
    bottom: 50px;
}
.content-empty-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.label-marker-position {
    bottom: 6px;
    left: 0;
    position: relative;
}
ion-button {
    --box-shadow: none;
}
.custom-loading-class {
    color: var(--ion-color-primary);
}
.app-alert-checkout .alert-wrapper .alert-button-group {
    flex-wrap: nowrap;
}
.app-alert-checkout .alert-wrapper .alert-button-group {
    flex-wrap: nowrap;
}
.app-alert-checkout .alert-wrapper .alert-button-group .alert-button-inner {
    justify-content: center;
}
.notification-msg .toast-wrapper .toast-container .toast-content .toast-content .toast-message {
    width: 70%;
}
.header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 45px;
    background-color: var(--ion-color-primary);
    padding: 5px;
}
.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 45px;
    background-color: transparent;
}
#beacon-container .hsds-beacon .Beacon .BeaconContainer {
    margin-top: env(safe-area-inset-top);
    height: calc(96% - env(safe-area-inset-top));
}

.label-marker-position {
    padding: 1px;
    border: solid 2px #3CAADE;
    border-radius: 8px;
    bottom: 30px;
}

@keyframes animation-pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
}
ion-router-outlet {
    margin: 0 auto;
}
.no-scroll {
    --overflow: hidden;
}
.no-search-text {
    text-align: center;
}
.no-margin {
    margin: 0;
}
</style>
