import { toastController } from '@ionic/vue'

class AlertSrv {
    constructor() {}

    async toastError(msg, timer) {
        let delay = timer ? timer : 3000
        return this.toast(msg, 'danger', delay)
    }

    async toastWarning(msg) {
        return this.toast(msg, 'warning', 1500)
    }

    async toastSuccess(msg, duration) {
        return this.toast(msg, 'primary', duration || 1000)
    }

    async toastNotification(msg, duration) {
        const toast = await toastController.create({
            cssClass: 'notification-msg',
            message: msg,
            showCloseButton: true,
            duration: duration || 0,
            color: 'primary',
            buttons: [
                {
                    text: 'Done',
                    role: 'cancel',
                },
            ],
        })
        toast.setAttribute('aria-live', 'polite');
        toast.setAttribute('role', 'alert');
        await toast.present()
        const { role } = await toast.onDidDismiss()
        return role
    }

    async toast(msg, color, duration) {
        const toast = await toastController.create({
            message: msg,
            duration: duration,
            color: color,
        })
        toast.setAttribute('aria-live', 'assertive');
        toast.setAttribute('role', 'alert');
        toast.present()
    }
}

const srv = new AlertSrv()
export default srv
