import apolloClient from '../../apolloClient'
import { GET_USER, EXISTED_USER, CHECK_EXIST_UID, CHECK_EXIST_USER_DEVICE } from '../../graphql/queries/user'
import {
    ADD_USER_AUTHENTICATION,
    EMAIL_VERIFY,
    UPDATE_FB_USER_EMAIL,
    UPDATE_DEVICE_INFO,
    GET_USER_CONSUMER_MIGRATE,
    UPDATE_USER_MIGRATE,
    REMOVE_USER_DEVICE,
    DELETE_ACCOUNT,
} from '../../graphql/mutations/user'
import storageSrv from '../services/storageService'
import analyticsSrv from '../services/analyticSrv'
import appSrv from '../services/appService'
import store from '../../store'
import { Device } from '@capacitor/device'
import _ from 'lodash'

class AuthApi {
    constructor() {}

    getLoginUser() {
        return apolloClient.query({
            query: GET_USER,
            fetchPolicy: 'no-cache',
        })
    }

    checkExistUid(uid) {
        return apolloClient.query({
            query: CHECK_EXIST_UID,
            fetchPolicy: 'no-cache',
            variables: {
                user_uid: uid,
            },
        })
    }
    checkExistPaxUserDevice(deviceId) {
        return apolloClient.query({
            query: CHECK_EXIST_USER_DEVICE,
            fetchPolicy: 'no-cache',
            variables: {
                device_id: deviceId,
            },
        })
    }

    async syncUserToApp(firebaseData, apiData) {
        if (firebaseData && apiData) {
            const userVariable = await this.createUserVariable(firebaseData)
            const userObj = {
                id: apiData.id,
                platform: userVariable.platform,
                emailVerified: firebaseData.emailVerified,
                device_fb_token: apiData.device_fb_token,
                email: userVariable.email,
                userId: userVariable.user_id,
                notification_from_cards: apiData.notification_from_cards,
                all_card_send_notification: apiData.all_card_send_notification,
                auto_topup: apiData.auto_topup,
                all_card_auto_topup: apiData.all_card_auto_topup,
                fav_operator_id: apiData.fav_operator_id,
                favorite_operator: apiData.favorite_operator,
                favorite_routes: apiData.favorite_routes,
                is_premium: apiData.is_premium,
                user_hash_id: apiData.user_hash_id,
                notification_from_fav_operator: apiData.notification_from_fav_operator,
                smartcard_low_balance_notification: apiData.smartcard_low_balance_notification,
                bus_delays_notification: apiData.bus_delays_notification,
            }
            await storageSrv.setObject('user', userObj)
            store.dispatch('fetchUser', userObj)
            analyticsSrv.setUserInfo(userObj)
        }
    }

    authError() {
        storageSrv.removeItem('user')
    }

    async refreshUserInfo() {
        const userLoginedRes = await this.getLoginUser()
        if (userLoginedRes && userLoginedRes.data && userLoginedRes.data.paxUser) {
            const userLocal = await storageSrv.getObject('user')
            await this.syncUserToApp(userLocal, userLoginedRes.data.paxUser)
        }
    }

    async upateEmailVerified() {
        return apolloClient.mutate({
            mutation: EMAIL_VERIFY,
        })
    }

    async updateFbUserEmail() {
        return apolloClient.mutate({
            mutation: UPDATE_FB_USER_EMAIL,
        })
    }

    async createNewUser(userData, provider) {
        const userVariable = await this.createUserVariable(userData)
        return apolloClient.mutate({
            mutation: ADD_USER_AUTHENTICATION,
            variables: {
                data: {
                    ...userVariable,
                },
                provider,
            },
        })
    }

    async checkUserExisted(email) {
        const reponse = await apolloClient.query({
            query: EXISTED_USER,
            fetchPolicy: 'no-cache',
            variables: {
                email,
            },
        })
        return reponse.data.paxUserCount
    }

    async getAppDeviceInfo() {
        const info = await appSrv.getAppDeviceInfo()
        return {
            platform: info.deviceInfo.operatingSystem,
            model: info.deviceInfo.model,
            os_version: info.deviceInfo.os,
            app_version: info.appInfo.getInfo(),
            device_id: info.deviceInfo.uid,
        }
    }

    async createUserVariable(userData) {
        const info = await this.getAppDeviceInfo()
        return {
            ...info,
            email: userData.email,
            email_verify: userData.emailVerified,
            user_id: userData.uid,
        }
    }

    async updateDeviceInfo(newFbToken) {
        const info = await this.getAppDeviceInfo()
        return apolloClient.mutate({
            mutation: UPDATE_DEVICE_INFO,
            variables: {
                data: {
                    ...info,
                    device_fb_token: newFbToken,
                },
            },
        })
    }

    getUserConsumerMigrate(params) {
        return apolloClient.mutate({
            mutation: GET_USER_CONSUMER_MIGRATE,
            fetchPolicy: 'no-cache',
            variables: {
                ...params,
            },
        })
    }

    updateUserMigrate(params) {
        return apolloClient.mutate({
            mutation: UPDATE_USER_MIGRATE,
            variables: {
                deviceId: params.oldUserData.deviceId,
                rootCardToken: params.oldUserData.rootCardToken,
                rootCardCompanyId: params.oldUserData.rootCardCompanyId,
                userData: {
                    email: params.userData.email,
                    email_verify: params.userData.emailVerified,
                    user_id: params.userData.uid,
                },
                newDeviceId: params.newDeviceId,
            },
        })
    }

    async removeUserDevice() {
        const deviceInfo = await Device.getId()
        return apolloClient.mutate({
            mutation: REMOVE_USER_DEVICE,
            variables: {
                deviceId: deviceInfo.uuid,
            },
        })
    }

    async deleteAccount() {
        return apolloClient.mutate({
            mutation: DELETE_ACCOUNT,
        })
    }

    async clear() {
        apolloClient.stop()
        apolloClient.cache.reset()
        await apolloClient.resetStore()
    }

    async updateUserDeviceInfo() {
        const lastUpdateInfo = await storageSrv.getLastUpdatedAppInfo()
        const currentInfo = await appSrv.getAppDeviceInfo()
        if (!_.isEqual(lastUpdateInfo, currentInfo)) {
            //console.log("===========> updateDeviceInfo from lastUpdateInfo", lastUpdateInfo, currentInfo)
            //console.trace()
            // Update pax_user_device here
            await this.updateDeviceInfo()
            storageSrv.setLastUpdatedAppInfo(currentInfo)
        }
    }
}

const srv = new AuthApi()
export default srv
