<template>
    <ion-page>
        <simple-header-toolbar :titleName="$t('notification-title')" :backFunction="back"></simple-header-toolbar>
        <ion-progress-bar type="indeterminate" v-if="loading"></ion-progress-bar>
        <ion-content class="ion-padding">
            <div v-if="user && stateCards.length > 0">
                <ion-row class="ion-align-items-center">
                    <ion-col size="8">
                        <ion-text class="text-body2-style ion-text-capitalize">{{
                            $t('smartcard-notification')
                        }}</ion-text>
                    </ion-col>
                    <ion-col size="4" class="toggle-container">
                        <ion-toggle
                            mode="md"
                            :checked="smarcardNotification"
                            @ionChange="toggleNotificationChange($event.target.checked)"
                            ref="toggleNotification"
                        ></ion-toggle>
                    </ion-col>
                </ion-row>

                <ion-row class="ion-align-items-center" v-if="smarcardNotification">
                    <ion-col size="8">
                        <ion-text class="text-body2-style ion-text-capitalize">{{ $t('all-smartcards') }}</ion-text>
                    </ion-col>
                    <ion-col size="4" class="toggle-container">
                        <ion-toggle
                            mode="md"
                            :checked="allCardSendNotification"
                            @ionChange="toggleAllCardsChange($event.target.checked)"
                            ref="toggleAllCardNotifications"
                        ></ion-toggle>
                    </ion-col>
                </ion-row>
                <p class="border-list-container" v-if="isHaveBorderBottom && smarcardNotification"></p>
                <smartcard-notification-list
                    :cards="stateCards"
                    v-if="!allCardSendNotification && smarcardNotification"
                    :showLoading="showLoading"
                    :hideLoading="hideLoading"
                ></smartcard-notification-list>
                <smart-card-setting
                    v-if="allCardSendNotification && smarcardNotification"
                    :showLoading="showLoading"
                    :hideLoading="hideLoading"
                    :card="stateCards[0]"
                ></smart-card-setting>
            </div>
            <div v-else class="content-empty-screen">
                <add-smart-card></add-smart-card>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { mapState } from 'vuex'
import { Capacitor } from '@capacitor/core'
import SimpleHeaderToolbar from '../../components/toolbar/SimpleHeaderToolbar'
import notificationApi from '../../util/apis/notification'
import SmartcardNotificationList from '../../views/SmartCard/SmartCardNotificationList'
import SmartCardSetting from '../../views/SmartCard/SmartCardNotifcationSetting'
import smartcardService from '../../util/services/smartcardService'
import AddSmartCard from '../../components/card/AddSmartCard'
import appSrv from '../../util/services/appService'
import useNavigator from '@/composables/useNavigator'
import fcmService from "../../util/services/fcmService"

export default {
    name: 'SmartCardNotification',
    components: {
        SimpleHeaderToolbar,
        SmartcardNotificationList,
        SmartCardSetting,
        AddSmartCard,
    },
    data: function() {
        return {
            loading: false,
            smartcards: [],
            isHaveBorderBottom: false,
            alertPressCancel: false,
            backEventHandler: null,
            platform: Capacitor.getPlatform(),
        }
    },

    mounted: async function() {
        this.loading = true;
        this.backEventHandler = await appSrv.addListenerBackBtn(this.back);
        if (await fcmService.registerPush(true)) {
            await smartcardService.prepareSmartcards();
        } else {
            useNavigator.goToSmartcard()
        }
        this.loading = false
    },

    computed: {
        ...mapState({
            stateCards: state => {
                console.log('stateCards', state.smartcards)
                return state.smartcards
            },
            user: state => state.user,
            smarcardNotification: state => state.user.notification_from_cards,
            allCardSendNotification: state => state.user.all_card_send_notification,
        }),
    },
    async beforeUnmount() {
        if (this.backEventHandler) {
            await this.backEventHandler.remove()
        }
    },
    methods: {
        async back() {
            if (!(await appSrv.shouldIgnoreBackHandler())) {
                useNavigator.replaceHome()
            }
        },
        // set up all cards mode
        toggleAllCardsChange(checked) {
            this.showLoading()
            if (this.alertPressCancel) {
                this.alertPressCancel = false
                return
            }
            if (!checked) {
                this.turnOffAllCard(checked)
            } else {
                this.turnOnAllCard()
            }
        },
        async handleTurnOffAllCard() {
            const turnOffAllCardRes = await notificationApi.updateUserNotificationConfig({
                all_card_send_notification: false,
                notification_from_cards: this.smarcardNotification,
            })
            if (turnOffAllCardRes && turnOffAllCardRes.data && turnOffAllCardRes.data.updateUserNotificationConfig) {
                this.$store.dispatch('setAllCardSendNotification', false)
                console.log('turn off notification cards', this.stateCards)
            }
        },
        async handleTurnOnAllCard() {
            const turnOnAllCardRes = await notificationApi.updateUserNotificationConfig({
                all_card_send_notification: true,
                notification_from_cards: this.smarcardNotification,
            })
            if (turnOnAllCardRes && turnOnAllCardRes.data && turnOnAllCardRes.data.updateUserNotificationConfig) {
                this.$store.dispatch('setAllCardSendNotification', true)
                const updatedCards = turnOnAllCardRes.data.updateUserNotificationConfig
                if (updatedCards.length > 0) {
                    this.$store.dispatch('setSmartCards', updatedCards)
                }
            }
        },
        turnOffAllCard() {
            const _this = this
            appSrv.presentAlert({
                header: 'Notification',
                message: 'Did you want to turn off notifications for all smartcards?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: async () => {
                            _this.alertPressCancel = true
                            _this.$refs.toggleAllCardNotifications.$el.checked = true
                            _this.hideLoading()
                        },
                    },
                    {
                        text: 'OK',
                        handler: async () => {
                            await _this.handleTurnOffAllCard()
                            _this.hideLoading()
                        },
                    },
                ],
            })
        },
        turnOnAllCard() {
            const _this = this
            appSrv.presentAlert({
                header: 'Notification',
                message: 'Did you want to turn on notifications for all smartcards?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: async () => {
                            _this.alertPressCancel = true
                            _this.$refs.toggleAllCardNotifications.$el.checked = false
                            _this.hideLoading()
                        },
                    },
                    {
                        text: 'OK',
                        handler: async () => {
                            await _this.handleTurnOnAllCard()
                            _this.hideLoading()
                        },
                    },
                ],
            })
        },
        // set up notification
        async toggleNotificationChange(checked) {
            if (this.isReseting) {
                this.isReseting = false
                return
            }
            try {
                this.showLoading()
                if (!checked) {
                    await this.handleTurnOffNotification()
                } else {
                    await this.handleTurnOnNotification()
                }
            } finally {
                this.hideLoading()
            }
        },
        async handleTurnOnNotification() {
            try {
                this.$store.dispatch('setSendNotification', true)
                await notificationApi.updateUserNotificationConfig({
                    all_card_send_notification: this.allCardSendNotification,
                    notification_from_cards: true,
                })
            } catch {
                this.isReseting = true
                this.$store.dispatch('setSendNotification', false)
            }
        },
        async handleTurnOffNotification() {
            try {
                this.$store.dispatch('setSendNotification', false)
                await notificationApi.updateUserNotificationConfig({
                    all_card_send_notification: this.allCardSendNotification,
                    notification_from_cards: false,
                })
            } catch {
                this.isReseting = true
                this.$store.dispatch('setSendNotification', true)
            }
        },
        hideLoading() {
            this.loading = false
        },
        showLoading() {
            this.loading = true
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
</style>
