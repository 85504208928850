<template>
    <div class="sr-container">
        <ion-header>
            <ion-toolbar mode="md">
                <ion-title class="text-header ion-text-capitalize no-zoom-el">{{ $t('card-detail') }}</ion-title>
            </ion-toolbar>
        </ion-header>
        <div class="sr-root">
            <div class="sr-main">
                <div class="sr-payment-form card">
                    <div class="sr-form-row">
                        <div class="title-container">
                            <ion-icon class="icon-lock" :src="lockClosedOutline"></ion-icon>
                            <ion-text class="title-text">powered by</ion-text>
                            <img class="stripe-icon" :src="stripeLogo" alt="" />
                        </div>
                        <div id="card-element">
                            <!-- A Stripe card Element will be inserted here. -->
                        </div>
                    </div>
                    <div class="sr-field-error" id="card-errors" role="alert"></div>
                    <ion-item lines="none" class="item-check-box" v-if="openMode == 'openFromCardAddFund'">
                        <ion-label class="ion-text-wrap">Save card details for future use</ion-label>
                        <ion-checkbox
                            mode="md"
                            color="primary"
                            slot="start"
                            :checked="shouldSaveCard"
                            @ionChange="onCheckSaveCard($event.target.checked)"
                        ></ion-checkbox>
                    </ion-item>
                    <button id="submit" class="btn-modal btn-save">
                        <div class="spinner hidden" id="spinner"></div>
                        <span
                            v-if="openMode == 'openFromCardAddFund'"
                            id="button-text"
                            class="ion-text-capitalize text-bold"
                            >{{ shouldSaveCard ? 'Save' : 'Add Fund' }}</span
                        >
                        <span v-else id="button-text" class="ion-text-capitalize text-bold">Save</span>
                    </button>
                    <button @click="modalClose(null)" class="btn-modal">
                        <div class="spinner hidden" id="spinner"></div>
                        <span id="button-text" class="ion-text-capitalize text-bold">Cancel</span>
                    </button>
                </div>
                <div class="note-stripe-text" aria-labelledby="term-label">
                    {{ $t('card-detail-note') }}
                    <a class="tag-href" id="term-label" :href="HREF_STRIPE_PRIVACY" target="_blank">Stripe's Privacy Policy</a> {{ $t('and') }}
                    <a class="tag-href" :href="HREF_STRIPE_TERM" target="_blank">Term</a>
                </div>

                <div class="sr-result hidden">
                    <p>Card setup completed<br /></p>
                    <pre>
            <code></code>
          </pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import stripeApi from '../../util/apis/stripe'
import { modalController } from '@ionic/vue'
import { lockClosedOutline } from 'ionicons/icons'
import { Capacitor } from '@capacitor/core'
const HREF_STRIPE_PRIVACY = 'https://stripe.com/en-au/privacy-center/legal'
const HREF_STRIPE_TERM = 'https://stripe.com/en-au/legal/end-users'
const setupElements = function(stripe, stripeConfig, vueCtx) {
    const elements = stripe.elements()

    // Element styles
    var style = {
        base: {
            fontSize: '16px',
            color: '#32325d',
            fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: 'rgba(0,0,0,0.4)',
            },
        },
    }

    const cardElement = elements.create('card', { style: style })

    cardElement.mount('#card-element')

    // Element focus ring
    cardElement.on('focus', function() {
        var el = document.getElementById('card-element')
        el.classList.add('focused')
    })

    cardElement.on('blur', function() {
        var el = document.getElementById('card-element')
        el.classList.remove('focused')
    })

    // Handle payment submission when user clicks the pay button.
    var button = document.getElementById('submit')
    const confirmCardSetUp = clientSecret => {
        stripe
            .confirmCardSetup(clientSecret, {
                payment_method: {
                    card: cardElement,
                },
            })
            .then(function(result) {
                if (result.error) {
                    console.log('###confirmCardSetup error', result.error.message)
                    showError(result.error.message)
                } else {
                    // The PaymentMethod was successfully set up
                    console.log('###confirmCardSetup', result.setupIntent)
                    orderComplete(result.setupIntent, stripeConfig, vueCtx)
                }
            })
    }
    const prepareSetupIntent = () => {
        stripeApi
            .prepareSetupIntent()
            .then(intent => {
                confirmCardSetUp(intent.data.prepareSetupIntent)
            })
            .catch(function(e) {
                console.error('###createStripeSetupIntent error', e.message) // "oh, no!"
                showError(e.message)
            })
    }

    button.addEventListener('click', function(event) {
        event.preventDefault()
        changeLoadingState(true)
        prepareSetupIntent()
    })
}

const showError = function(message) {
    changeLoadingState(false)
    var displayError = document.getElementById('card-errors')
    displayError.textContent = message
}

// Show a spinner on payment submission
var changeLoadingState = function(isLoading) {
    if (isLoading) {
        document.querySelector('button').disabled = true
        document.querySelector('#spinner').classList.remove('hidden')
        document.querySelector('#button-text').classList.add('hidden')
    } else {
        document.querySelector('button').disabled = false
        document.querySelector('#spinner').classList.add('hidden')
        document.querySelector('#button-text').classList.remove('hidden')
    }
}

/* Shows a success / error message when the payment is complete */
var orderComplete = function(setupIntent, stripeConfig, context) {
    context.modalClose({
        setupIntent,
    })

    changeLoadingState(false)
}

export default {
    name: 'StripeDirectDebitModal',
    props: {
        action: String,
        openMode: String,
        stripeDirectDebit: Object,
    },
    data: function() {
        return {
            lockClosedOutline,
            stripeLogo: require('../../assets/logo-stripe.png'),
            shouldSaveCard: true,
            platform: Capacitor.getPlatform(),
            HREF_STRIPE_PRIVACY,
            HREF_STRIPE_TERM
        }
    },
    mounted: function() {
        this.includeStripe('js.stripe.com/v3/', () => {
            this.configureStripe()
        })
    },
    methods: {
        /*
        Includes Stripe.js dynamically
    */
        includeStripe(URL, callback) {
            let documentTag = document,
                tag = 'script',
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0]
            object.src = 'https://' + URL
            if (callback) {
                object.addEventListener(
                    'load',
                    function(e) {
                        callback(null, e)
                    },
                    false
                )
            }
            scriptTag.parentNode.insertBefore(object, scriptTag)
        },
        configureStripe() {
            return stripeApi.getStripeOperatorConfig().then(response => {
                if (response && response.data && response.data.stripeOperatorConfig) {
                    const stripeConfig = response.data.stripeOperatorConfig
                    let stripe = window.Stripe(stripeConfig.stripe_publishable_key)

                    setupElements(stripe, stripeConfig, this)
                }
            })
        },
        onCheckSaveCard(check) {
            this.shouldSaveCard = check
        },
        modalClose: function(response) {
            if (response) {
                response.openMode = this.openMode
                response.shouldSaveCard = this.shouldSaveCard
            } else {
                response = {
                    openMode: this.openMode,
                    shouldSaveCard: this.shouldSaveCard,
                }
            }

            modalController.dismiss(response)
        },
    },
}
</script>

<style scoped>
/* Layout */
.StripeElement {
    box-sizing: border-box;

    height: 40px;

    padding: 10px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.sr-container {
    height: 100%;
}

/* Buttons and links */
button {
    background: var(--accent-color);
    border-radius: var(--radius);
    color: white;
    border: 0;
    padding: 12px 16px;
    margin-top: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}
button:hover {
    filter: contrast(115%);
}
button:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
}
button:disabled {
    opacity: 0.5;
    cursor: none;
}

a {
    color: var(--link-color);
    text-decoration: none;
    transition: all 0.2s ease;
}

a:hover {
    filter: brightness(0.8);
}

a:active {
    filter: brightness(0.5);
}

/* Code block */
code,
pre {
    font-family: 'SF Mono', 'IBM Plex Mono', 'Menlo', monospace;
    font-size: 12px;
}

.sr-root {
    display: flex;
    justify-content: center;
    overflow: auto;
    height: calc(100% - 57px);
}

.sr-main {
    width: 90%;
    height: auto;
    padding: 10px;
    margin: 10px;
    background: rgb(247, 250, 252);
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1),
        0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.hidden {
    display: none;
}

@keyframes field-in {
    0% {
        opacity: 0;
        transform: translateY(8px) scale(0.95);
    }
    100% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}

@keyframes form-in {
    0% {
        opacity: 0;
        transform: scale(0.98);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.btn-modal {
    background-color: #003C69;
    border-radius: 4px;
    height: 40px;
}
.btn-save {
    margin-top: 20px;
}
.note-stripe-text {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    opacity: 0.6;
    margin-top: 20px;
}
.title-text {
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    opacity: 0.3;
    font-size: 14px;
    font-weight: 500;
    margin: 0 5px;
}
.title-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}
.icon-lock {
    font-size: 20px;
    color: #696969;
}
.stripe-icon {
    font-size: 40px;
}
.text-header {
    text-align: center;
}
ion-checkbox {
    --size: 20px;
}
.item-check-box {
    --background: transparent;
}
.tag-href {
    color: var(--ion-color-tertiary);
    text-decoration: underline;
}
</style>
