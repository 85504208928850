import { auth } from '../../firebase'
import storageSrv from './storageService'
import userLocalStorageSrv from './userLocalStorageSrv'
import authApi from '../apis/auth'
import appService from './appService'
import useNavigator from '../../composables/useNavigator'
import store from '../../store'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { modalController } from '@ionic/vue'
import AppleLoginMessageModal from '@/views/modal/AppleLoginMessageModal.vue'
import firebase from 'firebase'
import { cfaSignOut } from 'capacitor-firebase-auth'
import iapSrv from '@/util/services/inAppPurchaseService'
import smartcardApi from '@/util/apis/smartcard'
import router from '@/router'
class AuthService {
    constructor() {
        auth.onAuthStateChanged(() => {
            this.isAuthReady = true;
          })
    }

    async waitTillAuthReady() {
        const promise = new Promise(resolve => {
            var tryCallback = async () => {
                if (this.isAuthReady) {
                    resolve()
                } else {
                    setTimeout(tryCallback, 1000)
                }
            }
            tryCallback()
        })

        return promise
    }

    async saveUserToLocal(user) {
        await storageSrv.setObject('user', user)
    }

    updateFirebaseUser(uid, options) {
        return auth.updateUser(uid, ...options)
            .then(userRecord => {
                console.log('Successfully updated user', userRecord.toJSON())
            })
            .catch(error => {
                console.log('Error updating user:', error)
            })
    }

    createDefaultUserLocal(params) {
        return {
            id: params.id,
            platform: null,
            emailVerified: false,
            email: params.email,
            userId: params.user_id,
            notification_from_cards: [],
            all_card_send_notification: false,
            auto_topup: false,
            all_card_auto_topup: false,
            fav_operator_id: null,
            favorite_routes: [],
            is_premium: false,
            notification_from_fav_operator: false,
            user_hash_id: params.user_hash_id,
        }
    }

    async refreshNativeToken() {
        const userToken = await storageSrv.getItem('userToken')
        console.log('Native user token', userToken)
        if (userToken) {
            auth.signInWithCustomToken(userToken).then(user => {
                console.log('user signInWithCustomToken', user)
            })
        }
    }

    async createAndSaveUser(user, provider) {
        const userRes = await authApi.createNewUser(user, provider)
        if (userRes && userRes.data && userRes.data.createPaxUser) {
            const defaultLocalUser = this.createDefaultUserLocal(userRes.data.createPaxUser)
            if (provider !== 'emailAndPassword') {
                defaultLocalUser.emailVerified = true
            }
            await storageSrv.setObject('user', defaultLocalUser)
            store.dispatch('fetchUser', defaultLocalUser)
        }
    }
    async updateUserMigrateAndSave(oldUserData, user, newDeviceId) {
        const userRes = await authApi.updateUserMigrate({
            oldUserData,
            userData: user,
            newDeviceId,
        })
        if (userRes.data.updateUserMigrate && userRes.data.updateUserMigrate.pax_user) {
            const defaultLocalUser = this.createDefaultUserLocal(userRes.data.updateUserMigrate.pax_user)
            if (user && user.emailVerified) {
                defaultLocalUser.emailVerified = true
            }
            await storageSrv.setObject('user', defaultLocalUser)
            store.dispatch('fetchUser', defaultLocalUser)
        }
    }

    async checkOsToAppleSignIn() {
        let device = await Device.getInfo()
        console.log('checkOsToAppleSignIn', device)
        console.log(
            'checkOsToAppleSignIn',
            device && parseFloat(device.osVersion) >= 13 && device.operatingSystem === 'ios'
        )
        return (
            device &&
            ((parseFloat(device.osVersion) >= 13 && device.operatingSystem === 'ios') ||
                device.platform == 'web')
        )
    }

    async fetchAndUpdateUserToStore(user) {
        const paxUserResponse = await authApi.getLoginUser()
        if (!paxUserResponse.data.paxUser.email_verify && user.emailVerified) {
            await authApi.upateEmailVerified()
        }
        await authApi.syncUserToApp(user, paxUserResponse.data.paxUser)
        appService.prepareApp()
        //console.log("===========> updateDeviceInfo from fetchAndUpdateUserToStore")
        //console.trace()
        authApi.updateDeviceInfo()
    }

    navigationUserAfterLogin() {
        const user = store.getters.user
        if (user && user.fav_operator_id) {
            useNavigator.goHome()
        } else {
            useNavigator.replaceToFavorite({ routeMode: 'operatorFav' })
        }
    }

    async getPaxUserOldData() {
        if (Capacitor.platform === 'android') {
            const fcmToken = await userLocalStorageSrv.getObject('FCM_TOKEN')
            if (fcmToken) {
                const deviceInfo = await Device.getId()
                const data = await userLocalStorageSrv.getObject('user_account')
                const { token, companyId } = data || {}
                return {
                    deviceId: deviceInfo ? deviceInfo.uuid : null,
                    rootCardToken: token,
                    rootCardCompanyId: companyId,
                }
            }
        } else {
            const deviceIdStored = await userLocalStorageSrv.getObject('device_id')
            const kPassengerAuthToken = await userLocalStorageSrv.getObject('kPassengerAuthToken')
            const kPassengerCompanyID = await userLocalStorageSrv.getObject('kPassengerCompanyID')

            if (deviceIdStored && deviceIdStored.value) {
                return {
                    deviceId: deviceIdStored ? deviceIdStored.value : null,
                    rootCardToken: kPassengerAuthToken ? kPassengerAuthToken.value : null,
                    rootCardCompanyId: kPassengerCompanyID ? kPassengerCompanyID.value : null,
                }
            }
        }
        return null
    }

    async removePaxUserOldData() {
        if (Capacitor.platform === 'android') {
            await userLocalStorageSrv.removeObject(Capacitor.platform, 'user_account')
            await userLocalStorageSrv.removeObject(Capacitor.platform, 'FCM_TOKEN')
        } else {
            await userLocalStorageSrv.removeObject(Capacitor.platform, 'device_id')
        }
    }

    validatePassword(password) {
        return password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
    }

    async signOut(skipRemovingUserDevice) {
        await appService.presentLoading()
        try {
            if (!skipRemovingUserDevice) {
                await authApi.removeUserDevice()
            }
            await firebase.auth().signOut()
            await iapSrv.signOut()
            await authApi.clear()
            cfaSignOut().subscribe()
            router.replace({ name: 'SignIn', params: { force: true } })
            store.dispatch('logOut')
        } catch (err) {
            appService.showAlert(err.message, 'Logout')
            store.dispatch('logOut')
        } finally {
            appService.dismissLoading()
        }
    }

    async deleteAccount(haveCreditInfor) {
        await appService.presentLoading()
        try {
            if (haveCreditInfor) {
                await smartcardApi.removeLinkedCard()
            }
            await authApi.deleteAccount()
            await iapSrv.signOut()
            await authApi.clear()
            cfaSignOut().subscribe()
            router.replace({ name: 'SignIn', params: { force: true } })
            store.dispatch('logOut')
        } catch (err) {
            appService.showAlert(err.message, 'Logout')
            store.dispatch('logOut')
        } finally {
            appService.dismissLoading()
        }
    }

    async openReloginAppleMessModal(showInstructionOnly) {
        let modal = await modalController.create({
            component: AppleLoginMessageModal,
            cssClass: 'apple-login-mess-modal',
            showBackdrop: true,
            componentProps: { 
                showInstructionOnly,
            }
        })

        await modal.present()
        await modal.onDidDismiss()
    }

    checkAppleSignInWithoutEmail() {
        if (store.state.user && !store.state.user.email && Capacitor.getPlatform() == 'ios') {
            const confirmUpdateEmail = async (onOK) => {
                await appService.presentAlert({
                    header: 'Email Required',
                    message: 'We need your email address to send payment receipt. You are using Apple Sign In and unfortunately we are unable to save your contact email address.<br>Do you want to set your contact email with Apple Sign In? We would need to log you out first.',
                    buttons: [
                        {
                            text: 'Cancel'
                        },
                        {
                            text: 'OK',
                            handler: async () => {
                                onOK && onOK()
                            },
                        },
                    ],
                })
            }

            confirmUpdateEmail(() => {
                this.openReloginAppleMessModal()
            })
        } else {
            return true
        }
    }

    async hasCurrentLoggedInUser() {
        const jsonLocalUser = await store.getters.localUser
        const localUser = JSON.parse(jsonLocalUser)
        const localUserToken = await store.getters.localUserToken
        const isLoggedIn = Boolean(localUserToken) && localUser && localUser.emailVerified
        console.log('isLoggedIn', isLoggedIn)
        await this.waitTillAuthReady();
        return isLoggedIn && auth.currentUser;
    }
}

const srv = new AuthService()
export default srv
