<template>
    <ion-page>
        <simple-header-toolbar :titleName="$t('smartcard-add-fund')"></simple-header-toolbar>

        <ion-progress-bar type="indeterminate" v-if="loading"></ion-progress-bar>
        <ion-content>
            <ion-grid>
                <ion-row class="ion-margin-bottom">
                    <ion-col size="4">
                        <ion-label class="input-label">Name </ion-label>
                    </ion-col>
                    <ion-col size="8">
                        <div class="fee-container">
                            <ion-text class="input-label text-normal">{{
                                computedStripCharge.cardholder_name
                            }}</ion-text>
                        </div>
                    </ion-col>
                </ion-row>

                <ion-row class="ion-margin-bottom">
                    <ion-col size="4">
                        <ion-label class="input-label">Card</ion-label>
                    </ion-col>
                    <ion-col size="8">
                        <div class="fee-container">
                            <ion-text class="input-label text-normal">{{ computedStripCharge.card_number }}</ion-text>
                        </div>
                    </ion-col>
                </ion-row>

                <ion-row class="ion-align-items-center ion-margin-bottom">
                    <ion-col size="4">
                        <ion-label class="input-label">{{ $t('amount') }}</ion-label>
                    </ion-col>
                    <ion-col size="8">
                        <div class="item-container">
                            <input class="amount-input" v-model="amount" type="number" inputmode="decimal" required />
                        </div>
                    </ion-col>
                </ion-row>

                <ion-row class="ion-margin-bottom">
                    <ion-col size="4">
                        <ion-label class="input-label">{{ $t('fee') }}</ion-label>
                    </ion-col>
                    <ion-col size="8">
                        <div class="fee-container">
                            <ion-text class="input-label text-normal">${{ computedStripeFee }}</ion-text>
                            <ion-text class="text-body2-style">{{ computedStripeFeeNote }}</ion-text>
                        </div>
                    </ion-col>
                </ion-row>

                <ion-row class="ion-margin-bottom">
                    <ion-col class="input-label total-color" size="4">{{ $t('total') }}</ion-col>
                    <ion-col size="8" class="input-label text-normal total-color">${{ computedTotal }}</ion-col>
                </ion-row>

                <ion-row class="ion-margin-bottom ion-justify-content-center ion-align-items-center">
                    <ion-button size="small" fill="outline" @click="navBack" class="btn-text">{{
                        $t('cancel')
                    }}</ion-button>
                    <ion-button size="small" @click="addFund" :disabled="loading" fill="outline" class="btn-text">{{
                        $t('add')
                    }}</ion-button>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>
import SimpleHeaderToolbar from '../../components/toolbar/SimpleHeaderToolbar'
import StripeDirectDebitModal from '../modal/StripeDirectDebitModal'
import stripeApi from '../../util/apis/stripe'
import { modalController } from '@ionic/vue'
import appService from '../../util/services/appService'
import { configTypeSetting } from '../../util/constant'
export default {
    name: 'SmartCardAddFund',
    components: {
        SimpleHeaderToolbar,
    },
    data() {
        return {
            amount: 10,
            description: '',
            stripeCharge: null,
            feePercent: null,
            feeFixed: null,
            stripeFee: 0,
            stripeTotal: 0,
            loading: false,
            stripeFeeNote: 'Fee is paid by Operator',
        }
    },
    computed: {
        computedStripCharge() {
            return this.$store.getters.cardStripeCharge
        },
        computedStripeFee() {
            let fee = 0
            if (this.computedStripCharge.payment_config) {
                if (
                    this.computedStripCharge.payment_config.status === configTypeSetting.STRIPE_FEE_PASSENGER_PAY &&
                    this.amount
                ) {
                    const totalAmount = this.calTotalAmount()
                    fee = (totalAmount - parseFloat(this.amount)).toFixed(2)
                }
            }
            return fee
        },
        computedTotal() {
            return this.calTotalAmount()
        },
        computedStripeFeeNote() {
            let note = this.stripeFeeNote
            if (this.computedStripCharge.payment_config.status == 2) {
                note = 'Fee is added on when customers checkout'
            }
            return note
        },
    },
    methods: {
        calTotalAmount() {
            let total = 0
            if (this.computedStripCharge.payment_config.status == 2 && this.amount) {
                const feeFixed = parseFloat(this.computedStripCharge.payment_config.fee_fixed)
                const feePercent = parseFloat(this.computedStripCharge.payment_config.fee_percent)
                const totalAmount = (parseFloat(this.amount) + feeFixed / 100) / (1 - feePercent)
                total = totalAmount.toFixed(2)
            } else {
                total = this.amount
            }
            return total
        },
        addFund() {
            this.loading = true
            if (this.amount && this.amount > 0) {
                this.checkToOpenDirectDebitModal()
            } else {
                appService.presentAlert({
                    header: 'Add Fund',
                    message: 'Please fill in amount',
                    buttons: ['OK'],
                })
            }
            this.loading = false
        },
        navBack() {
            this.$router.go(-1)
        },
        //called when the modal is closed
        async modalCloseHandler(response) {
            this.loading = true
            console.log('smartcard-add-fund-modal-closed', response)
            if (response && response.setupIntent) {
                if (response.shouldSaveCard) {
                    stripeApi
                        .saveStripeSetupIntent({
                            setup_intent_id: response.setupIntent.id,
                        })
                        .then(async response => {
                            if (response.data.saveSetupIntent.status === 'success') {
                                await this.chargeDirectCard()
                            }
                        })
                } else {
                    await this.chargeDirectCard({ del_payment_method: true })
                }
            } else {
                this.navBack()
            }
            this.loading = false
        },
        validateAddFundAmount() {
            return this.amount >= 10
        },
        async alertUserAddFund() {
            return await appService.presentAlert({
                header: 'Add Fund',
                message: `Did you want to add <b>$${this.amount}</b> to this card?`,
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel',
                    },
                    {
                        text: 'Yes',
                        role: 'ok',
                    },
                ],
            })
        },
        async checkToOpenDirectDebitModal() {
            this.loading = true
            if (!this.validateAddFundAmount()) {
                return await appService.presentAlert({
                    header: 'Add Fund',
                    message: `Minimum top up amount is $10`,
                    buttons: ['OK'],
                })
            }
            const hasStripeDirectDebitSetup = await stripeApi.checkStripeDirectDebitInfor()
            if (!hasStripeDirectDebitSetup) {
                await this.openCheckoutModal()
            } else {
                if (this.computedStripCharge) {
                    if ((await this.alertUserAddFund()) === 'ok') {
                        await this.chargeDirectCard()
                    }
                } else {
                    const alertPrams = {
                        header: 'Add Fund',
                        message: 'Please select card to add fund',
                        buttons: [
                            {
                                text: 'OK',
                                handler: () => {
                                    this.navBack()
                                },
                            },
                        ],
                    }
                    appService.presentAlert(alertPrams)
                }
            }
            this.loading = false
        },
        async chargeDirectCard(options) {
            this.loading = true
            try {
                const chargeDirectRes = await this.chargeDirect(options)
                console.log('###chargeDirectCard', chargeDirectRes)
                const alertPrams = {
                    header: 'Add Fund',
                    message: 'Success',
                    buttons: [
                        {
                            text: 'OK',
                            handler: () => {
                                this.navBack()
                            },
                        },
                    ],
                }
                if (chargeDirectRes.data.chargeDirect.status === 'success') {
                    appService.presentAlert(alertPrams)
                    this.$store.dispatch('updateCardBalance', {
                        amount: this.amount,
                        user_consumer_id: this.computedStripCharge.user_consumer_id,
                    })
                } else {
                    alertPrams.message = 'Failed to add fund'
                    appService.presentAlert(alertPrams)
                }
                this.loading = false
            } catch (err) {
                this.loading = false
                appService.showAlert(err, 'Failed to add fund')
            }
        },
        chargeDirect(options) {
            return stripeApi.directCharge({
                amount: parseFloat(this.amount),
                userConsumerId: this.computedStripCharge.user_consumer_id,
                del_payment_method: options && options.del_payment_method ? options.del_payment_method : false,
            })
        },
        async openCheckoutModal() {
            let modal = await modalController.create({
                component: StripeDirectDebitModal,
                cssClass: 'direct-debit-modal-class',
                componentProps: {
                    openMode: 'openFromCardAddFund',
                },
            })
            await modal.present()
            let modalResponse = await modal.onDidDismiss()
            await this.modalCloseHandler({ ...modalResponse.data })
        },
    },
}
</script>

<style scoped>
.item-container {
    border: 1px solid var(--ion-color-medium-tint);
    border-radius: 2px;
}
input {
    border: none;
    width: 100%;
    height: 40px;
    outline: none;
}

.text-normal {
    font-weight: normal;
}
ion-button {
    min-width: 86px;
    margin-right: 12px;
    min-height: 36px;
}
.btn-text {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    color: var(--ion-color-sm-card-btn);
}
.fee-container {
    display: flex;
    flex-direction: column;
}
.amount-input {
    padding: 10px;
}
.total-color {
    color: var(--ion-color-total-text);
    font-weight: bold;
}
</style>
