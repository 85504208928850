import gql from 'graphql-tag';

export const GET_CUSTOM_TRACKING_ROUTES = gql`
    query customTrackingRoutes($routes: [inputRunningRoutes!]) {
        customTrackingRoutes(routes: $routes) {            
            id
            name
            trackable_routes {
                id
                name
                trackable_stops {
                    id
                    name
                    address
                    position
                    display_name
                    departure_time
                    lat
                    lng
                    route_stop_id
                    departure_time
                    tracked
                }
            }
        }
    }
`;

export const GET_TRACKING_ROUTES = gql`
    query trackingRoutes {
        trackingRoutes {
            id
            name
            trackable_routes {
                id
                name
                trackable_stops {
                    id
                    display_name
                    departure_time
                    position
                    name
                    address
                    lat
                    lng
                    route_stop_id
                    departure_time
                    tracked
                }
            }
        }
    }
`;


export const RUNNING_ROUTES = gql`
    query runningRoutes($routes: [inputRunningRoutes!]) {
        runningRoutes(routes: $routes) {
            dbr_id
            operator_id
            route_id
            route_name
            bus_id
            bus_number
            lat
            lng
            pax_app_show_pax_count
        }
    }
`;

export const GET_ROUTES_CAN_BE_TRACK = gql`
    query canBeTrackRoutes($fav_operator_id: Int!) {
        canBeTrackRoutes(fav_operator_id: $fav_operator_id) {
            id
            name
            is_route_end
            bus_id
            bus_number
            ac_check
            wheelchair_check
        }
    }
`;

export const GET_ROUTE_STOPS = gql`
    query getRouteStops($route_ids: String!) {
        getRouteStops(route_ids: $route_ids) {
            id
            lat
            lng
            stop_name
            stop_display_name
            route_name
            route_id
            stop_id
            cat_id
            position
            departure_time
            operator_id
            allow_receive_noti
        }
    }
`;



export const GET_OPERATOR_ROUTES = gql`
    query getOperatorRoutes($route_ids: [Int!], $company_id: Int!) {
        getOperatorRoutes(route_ids: $route_ids, company_id: $company_id) {
            id
            route_name
            operator {
                id
                name
            }
        }
    }
`;

export const GET_ROUTE_LINES = gql`
    query getRouteLines($route_id: Int!, $company_id: Int!) {
        getRouteLines(route_id: $route_id, company_id: $company_id) {
            id
            lat
            lng
        }
    }
`;


