class WcagService {
    handleTabButton(elTab) {
        elTab.shadowRoot.firstChild.ariaHidden = true
        elTab.addEventListener('keypress', (e) => {
            if (e.code === "Enter" || e.code === "Space") {
                elTab.shadowRoot.firstChild.click()
            }
        })
    }
}

const srv = new WcagService()
export default srv