<template>
  <ion-page>
    <ion-content>
      <simple-header-toolbar
        :titleName="$t('my-smartcard')"
        :backFunction="back"
        ref="headerBar"
      ></simple-header-toolbar>
      <div class="content-screen">
        <add-smart-card></add-smart-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import SimpleHeaderToolbar from "../../components/toolbar/SimpleHeaderToolbar";
import AddSmartCard from "../../components/card/AddSmartCard";
export default {
  name: "SmartCardHome",
  components: {
    SimpleHeaderToolbar,
    AddSmartCard,
  },
  ionViewDidEnter: function() {
      this.$refs.headerBar.setDefaultFocus()
  },  
  methods: {
    back() {
      this.$router.replace({name: "Home"})
    }
  }
};
</script>
<style scoped>
.content-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>

