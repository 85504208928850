<template>
    <div :class="platform && platform === 'web' ? 'container-web' : 'container'">
        <ion-grid v-for="item in topUpOptions" v-bind:key="item.id" class="group-content">
            <p class="full-border-top"></p>
            <ion-row>
                <ion-text class="text-headline6-style ion-text-capitalize title-color">{{ $t(item.title) }}</ion-text>
            </ion-row>
            <ion-row class="text-body2-style">
                <ion-text>{{ $t(item.description) }}</ion-text>
            </ion-row>

            <ion-radio-group
                :value="item.getSelectedId()"
                @click="item.setSelectedId($event.target.value)"
                :ref="item.componentName"
            >
                <ion-row>
                    <ion-col class="input-label font-weight-normal">
                        <ion-item lines="none">
                            <ion-radio :value="item.price1" type="md" aria-hidden="true"></ion-radio>
                            <ion-label class="text-margin-left">${{ item.price1 }}</ion-label>
                        </ion-item>
                    </ion-col>
                    <ion-col class="input-label font-weight-normal">
                        <ion-item lines="none">
                            <ion-radio class="left-margin" :value="item.price2" type="md"></ion-radio>
                            <ion-label class="text-margin-left">${{ item.price2 }}</ion-label>
                        </ion-item>
                    </ion-col>
                </ion-row>

                <ion-row>
                    <ion-col class="input-label font-weight-normal"
                        ><ion-item lines="none">
                            <ion-radio :value="item.price3" type="md"></ion-radio>
                            <ion-label class="text-margin-left">${{ item.price3 }}</ion-label>
                        </ion-item></ion-col
                    >
                    <ion-col class="input-label font-weight-normal"
                        ><ion-item lines="none">
                            <ion-radio :value="item.price4" class="left-margin" type="md"></ion-radio>
                            <ion-label class="text-margin-left">${{ item.price4 }}</ion-label>
                        </ion-item></ion-col
                    >
                </ion-row>

                <ion-row>
                    <ion-col class="input-label font-weight-normal" size="7"
                        ><ion-item lines="none">
                            <ion-radio :value="item.other" type="md" class="right-margin"></ion-radio>
                            <ion-label class="text-margin-left wrap-text">{{ $t('others') }} (min ${{ item.min }})</ion-label>
                        </ion-item></ion-col
                    >
                    <ion-col class="input-label font-weight-normal" size="5">
                        <ion-item lines="none">
                            $&nbsp;<input
                                style="width:90%;max-width:100px"
                                v-model="item.otherValue"
                                type="number"
                                inputmode="decimal"
                                @focus="item.onOptionOtherFocused()"
                            />
                        </ion-item>
                    </ion-col>                    
                </ion-row>
            </ion-radio-group>
            <!-- <div :class="platform && platform === 'web' ? 'input-container web-space' : 'input-container'">
                $
                <input
                    v-model="item.otherValue"
                    type="number"
                    inputmode="decimal"
                    @focus="item.onOptionOtherFocused()"
                />
            </div> -->
        </ion-grid>

        <div class="btn-container">
            <ion-button @click="saveConfig" fill="clear" class="btn-action">{{ $t('save') }}</ion-button>
            <ion-button fill="clear" @click="reset" class="btn-action">{{ $t('cancel') }}</ion-button>
        </div>
        <p class="full-border-top"></p>
    </div>
</template>

<script>
import { watch, computed } from 'vue'
import { Capacitor } from '@capacitor/core'
const AllPrices = [10, 20, 50, 100]
class TopUpConfig {
    constructor(id, title, componentName, description, min, config) {
        this.id = id
        this.title = title
        this.componentName = componentName
        this.description = description
        this.price1 = 10
        this.price2 = 20
        this.price3 = 50
        this.price4 = 100
        this.other = 'others'
        this.min = min
        this.config = config

        this.otherValue = computed({
            get: () => {
                let val = this.getSelectedId() == this.other ? this.getConfigValue() : null
                console.log('OTHER VALUE ', val)
                return val
            },
            set: value => {
                this.setConfigValue(value)
            },
        })
    }

    getConfigValue() {
        return this.id == 'topUpAmount' ? this.config.amount : this.config.trigger_balance
    }

    setConfigValue(value) {
        if (this.id == 'topUpAmount') {
            this.config.amount = value
        } else {
            this.config.trigger_balance = value
        }
    }

    getSelectedId() {
        if (AllPrices.includes(this.getConfigValue())) {
            return this.getConfigValue()
        }
        return this.other
    }

    setSelectedId(id) {
        if (AllPrices.includes(id)) {
            this.setConfigValue(id)
        } else {
            this.setConfigValue(null)
        }
    }

    onOptionOtherFocused() {
        this.setSelectedId(this.other)
    }
}

export default {
    name: 'SmartcardTopUpMode',
    props: {
        save: Function,
        cardNumber: String,
        card: Object,
    },
    emits: ['save-config'],
    data: () => ({
        backdoor: 0,
        tempConfig: {},
        topUpOptions: [],
        platform: Capacitor.getPlatform(),
    }),
    mounted() {
        watch(this.card.auto_topup_config, () => {
            this.reset()
        })

        this.reset()
    },
    methods: {
        init(config) {
            this.topUpOptions = [
                new TopUpConfig('topUpTrigger', 'top-up-trigger', 'topUpTrigger', 'top-up-trigger-description', 5, config),
                new TopUpConfig('topUpAmount', 'top-up-amount', 'topUpAmount', 'top-up-amount-description', 10, config),
            ]
        },
        reset() {
            this.tempConfig = { ...this.card.auto_topup_config }
            this.init(this.tempConfig)
        },
        saveConfig() {
            const config = {
                is_all: false,
                amount: this.tempConfig.amount,
                trigger_balance: this.tempConfig.trigger_balance,
                user_consumer_id: this.card.user_consumer_id,
            }

            console.log('computed configs', config)
            if (this.save) {
                this.save(config)
            } else {
                this.$emit('save-config', config)
            }
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.full-border-top {
    border-top: var(--ion-color-sm-card-btn) 1px solid;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
}
.title-color {
    color: var(--ion-color-tertiary);
}
.container {
    padding: 8px;
}
.container-web {
    padding: 20px;
}
.group-content {
    position: relative;
}
.input-container {
    display: flex;
    flex-direction: row;
    border: var(--ion-color-medium-tint) 1px solid;
    font-size: 16px;
    font-weight: normal;
    align-items: center;
    justify-content: center;
    line-height: 24px;
    padding: 0 8px;
    height: 35px;
    width: 25%;
    color: var(--ion-color-primary-contrast);
    border-radius: 8px;
    position: absolute;
    right: 24px;
    bottom: 20px;
}
.input-container.web-space {
    right: 80px;
}
.input-container input {
    margin-left: 8px;
    border: none;
    width: 100%;
    outline: none;
}
.btn-action {
    color: var(--ion-color-sm-card-btn);
    border: var(--ion-color-tertiary) 1px solid;
    border-radius: 4px;
    max-height: 36px;
    width: 100%;
}
.btn-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}
ion-radio {
    border-radius: 12px;
    border: var(--ion-color-grey) 2px solid;
    width: 24px;
    height: 24px;
}
ion-item {
    width: 100%;
    --padding-start: 0px;
}
ion-col {
    padding-left: 0;
    padding-right: 0;
}
.left-margin {
    margin-left: 24px;
}
.text-margin-left {
    margin-left: 12px;
}
.wrap-text {
  white-space: normal;
}
</style>
