import apolloClient from "../../apolloClient";
import { GET_TRACKING_ROUTES, GET_CUSTOM_TRACKING_ROUTES, GET_ROUTE_STOPS, GET_ROUTES_CAN_BE_TRACK, RUNNING_ROUTES, GET_OPERATOR_ROUTES, GET_ROUTE_LINES } from "../../graphql/queries/route";
import { SAVE_ROUTE_STOPS, DEL_SAVED_STOP, SAVE_USER_TRACK_ROUTE, DEL_ROUTE_TRACK } from "../../graphql/mutations/route";
// import store from "../../store";

class RouteApi {
    constructor() { }
    // queries 
    async getTrackingRoutes() {
        const response = await apolloClient.query({
            query: GET_TRACKING_ROUTES,
            fetchPolicy: "no-cache",
        });

        return response.data.trackingRoutes;
    }
  
    async getCustomTrackingRoutes(params) {
        return (await apolloClient.query({
            query: GET_CUSTOM_TRACKING_ROUTES,
            fetchPolicy: "no-cache",
            variables: {
                routes: params.routes
            }
        })).data.customTrackingRoutes;
    }

    async getRunningRoutes(params) {
        return (await apolloClient.query({
            query: RUNNING_ROUTES,
            fetchPolicy: "no-cache",
            variables: {
                routes: params.routes
            }
        })).data.runningRoutes;
    }

    getRoutesCanBeTrack(params) {
        return apolloClient.query({
            query: GET_ROUTES_CAN_BE_TRACK,
            fetchPolicy: "no-cache",
            variables: {
                fav_operator_id: params.fav_operator_id
            }
        });
    }
    getRouteLine(params) {
        return apolloClient.query({
            query: GET_ROUTE_LINES,
            fetchPolicy: "no-cache",
            variables: {
                ...params
            }
        });
    }

    getRouteStops(params) {
        return apolloClient.query({
            query: GET_ROUTE_STOPS,
            fetchPolicy: "no-cache",
            variables: {
                route_ids: params.route_ids,
            }
        });
    }

    getOperatorRoutes(params) {
        return apolloClient.query({
            query: GET_OPERATOR_ROUTES,
            fetchPolicy: "no-cache",
            variables: {
                route_ids: params.route_ids,
                company_id: params.company_id
            }
        });
    }
    // mutations
    saveRouteStop(params) {
        const { route_stops_id, operator_id } = params
        return apolloClient.mutate({
            mutation: SAVE_ROUTE_STOPS,
            fetchPolicy: "no-cache",
            variables: {
                route_stops_id: route_stops_id,
                operator_id
            }
        });
    }

    delSavedStop(params) {
        return apolloClient.mutate({
            mutation: DEL_SAVED_STOP,
            fetchPolicy: "no-cache",
            variables: {
                route_stops_id: parseInt(params.route_stops_id),
                operator_id: params.operator_id
            }
        });
    }

    saveUserTrackRoute(params) {
        return apolloClient.mutate({
            mutation: SAVE_USER_TRACK_ROUTE,
            fetchPolicy: "no-cache",
            variables: {
                ...params
            }
        });
    }

    delRouteTrack(args) {
        return apolloClient.mutate({
            mutation: DEL_ROUTE_TRACK,
            fetchPolicy: "no-cache",
            variables: {
                route_id: args.route_id,
                operator_id: args.operator_id
            }
        });
    }
}

const srv = new RouteApi();
export default srv;
