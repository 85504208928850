<template>
    <div>
        <ion-row v-for="(value, name) in initOptions" v-bind:key="value.id" class="ion-align-items-center">
            <ion-col>
                <ion-row>
                    <ion-col>
                        <ion-text
                            class="text-body2-style ion-text-capitalize"
                            v-bind:class="{ 'bold-text': highlightTitle }"
                            >{{ name }}</ion-text
                        >
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-row>
                            <ion-col size="3">
                                <div class="session-container">
                                    <ion-checkbox
                                        mode="md"
                                        :checked="value.type.am.checked"
                                        @ionChange="onCheckAM($event.target.checked, name, 'am')"
                                    ></ion-checkbox>
                                    <ion-label class="input-label font-weight-normal">AM</ion-label>
                                </div>
                            </ion-col>
                            <ion-col size="9">
                                <div class="session-container">
                                    <div class="time-content" v-bind:class="{ 'border-disabled': value.type.am.disabled }">
                                        <input
                                            type="text"
                                            class="time-picker"
                                            aria-label="from"
                                            inputmode="decimal"
                                            v-maska="'##:##'"
                                            v-model="value.type.am.from"
                                            :disabled="value.type.am.disabled"
                                            v-on:change="onAMChange($event.target.value, name, 'from')"
                                        />
                                    </div>
                                    <div class="time-content" v-bind:class="{ 'border-disabled': value.type.am.disabled }">
                                        <input
                                            type="text"
                                            class="time-picker"
                                            aria-label="to"
                                            inputmode="decimal"
                                            v-maska="'##:##'"
                                            v-model="value.type.am.to"
                                            :disabled="value.type.am.disabled"
                                            v-on:change="onAMChange($event.target.value, name, 'to')"
                                        />
                                    </div>
                                </div>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col size="3">
                                <div class="session-container">
                                    <ion-checkbox
                                        mode="md"
                                        :checked="value.type.pm.checked"
                                        @ionChange="onCheckPM($event.target.checked, name, 'pm')"
                                    ></ion-checkbox>
                                    <ion-label class="input-label font-weight-normal">PM</ion-label>
                                </div>
                            </ion-col>
                            <ion-col size="9">
                                <div class="session-container">
                                    <div class="time-content" v-bind:class="{ 'border-disabled': value.type.pm.disabled }">
                                        <input
                                            type="text"
                                            class="time-picker"
                                            inputmode="decimal"
                                            v-maska="'##:##'"
                                            v-model="value.type.pm.from"
                                            :disabled="value.type.pm.disabled"
                                            v-on:change="onPMChange($event.target.value, name, 'from')"
                                        />
                                    </div>
                                    <div class="time-content" v-bind:class="{ 'border-disabled': value.type.pm.disabled }">
                                        <input
                                            type="text"
                                            class="time-picker"
                                            inputmode="decimal"
                                            v-maska="'##:##'"
                                            v-model="value.type.pm.to"
                                            :disabled="value.type.pm.disabled"
                                            v-on:change="onPMChange($event.target.value, name, 'to')"
                                        />
                                    </div>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </ion-col>
        </ion-row>
    </div>
</template>
<script>
export default {
    name: 'NotificationTimeFrames',
    props: {
        options: Object,
        highlightTitle: Boolean,
        onCheckClockType: Function,
        isWeekTime: Boolean,
    },
    data: function() {
        return {
            initIsWeekTime: this.isWeekTime,
        }
    },
    computed: {
        initOptions() {
            return this.options
        },
    },
    methods: {
        onAMChange(value, day, type) {
            this.initOptions[day].type.am[type] = value
        },
        onPMChange(value, day, type) {
            this.initOptions[day].type.pm[type] = value
        },
        onCheckPM(checked, day) {
            this.initOptions[day].type.pm.checked = checked
            this.initOptions[day].type.pm.disabled = !checked
            if (!checked) {
                this.initOptions[day].type.pm.from = ''
                this.initOptions[day].type.pm.to = ''
            }
        },
        onCheckAM(checked, day) {
            this.initOptions[day].type.am.checked = checked
            this.initOptions[day].type.am.disabled = !checked
            if (!checked) {
                this.initOptions[day].type.am.from = ''
                this.initOptions[day].type.am.to = ''
            }
        },
        onCheckIsWeekDay() {
            if (
                this.initOptions['weekdays'] &&
                (this.initOptions['weekdays'].type.pm.checked || this.initOptions['weekdays'].type.am.checked)
            ) {
                return true
            } else {
                return false
            }
        },
    },
}
</script>
<style scoped>
.content-web {
    padding: 20px;
}
.session-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.time-content {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    max-width: 45%;
    margin-left: 5px;
}
.time-picker {
    text-align: center;
    min-width: 76px;
}
.border-disabled {
    border: var(--ion-color-light-shade) 1px solid;
}
.bold-text {
    font-weight: bold;
    color: '#5F6368';
}
.input-label {
    margin-left: 10px;
}
</style>
