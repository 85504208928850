// import { addIcons } from "ionicons";
// import * as allIcons from "ionicons/icons";
// import logoGoogle from "../assets/logo-google.svg";

// const iconsObject = {
//   "logo-google-color": logoGoogle,
//   "logo-facebook": allIcons.logoFacebook,
//   "ios-home-outline": allIcons.homeOutline,
//   "md-home-outline": allIcons.homeOutline,
//   "ios-heart-outline": allIcons.heartOutline,
//   "md-heart-outline": allIcons.heartOutline,
//   "ios-settings-outline": allIcons.settingsOutline,
//   "md-settings-outline": allIcons.settingsOutline,
// };
// addIcons(iconsObject);

import { addIcons } from "ionicons";
import * as allIcons from "ionicons/icons";
import logoGoogle from "../assets/logo-google.svg";

const currentIcons = Object.keys(allIcons).map(i => {
  let key = i.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
  if (
    key.startsWith("logo") ||
    key.startsWith("chevron") ||
    key.startsWith("arrow")
  ) {
    // console.log(key);
    return {
      [key]: allIcons[i]
    };
  }

  // console.log("ios-" + key);
  return {
    ["ios-" + key]: allIcons[i],
    ["md-" + key]: allIcons[i]
  };
});
const iconsObject = Object.assign({}, ...currentIcons);
// console.log(iconsObject);
addIcons(iconsObject);
addIcons({
  "logo-google-color": logoGoogle,
});
