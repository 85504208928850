export const LiveTrackingBottomSheetInitHeight = 0

export const ErrorCodes = Object.freeze({
    UNAUTHENTICATED: 1000,
    INVALID_PREMIUM_STATUS: 1001,
    CONCURRENT_UPDATE: 1002,
    STRIPE_CHARGE_FAILED: 1003,
    APP_UPDATE_REQUIRED: 1004,
    ACCOUNT_DELETED: 1005,
})

export const TransactionTypeLayOut = Object.freeze({
    ADD_FUND: 'ADD FUND',
    PAYMENT: 'PAYMENT',
    DEDUCTION: 'MAX FARE DEDUCTION',
    TRANSFER_FROM: 'TRANSFER FROM',
    TRANSFER_TO: 'TRANSFER TO',
    REFUND: 'REFUND',
    EXT_ADJUSTMENT: 'EX ADJUSTMENT',
    ADD_FUND_WITHOUT_STRIPE: 'TOP UP',
    ADD_FUND_CREDIT_CARD: 'TOP UP(cc)',
    ADD_FUND_FROM_DISCOUNT: 'DISCOUNT',
    AUTO_TOPUP: 'AUTO TOP-UP',
    MANUAL_CREDIT: 'OPERATOR CREDIT',
    MANUAL_DEBIT: 'OPERATOR DEBIT',
    TERM_FARE_RESET: 'TERM FARE RESET',
})

export const configTypeSetting = Object.freeze({
    STRIPE_FEE_OPERATOR_PAY: 1,
    STRIPE_FEE_PASSENGER_PAY: 2,
})

export const SydneyLocation = Object.freeze({
    lat: -33.8688,
    lng: 151.2093,
})

export const liveTrackingHeader = Object.freeze({
    ios: 64,
    android: 60,
})

export const notificationOptType = Object.freeze({
    ON_TAPPED: 'tap-card-opt',
    ON_NOT_TAPPED: 'not-tap-card-opt',
    ON_WRONG_STOP: 'unapproved-tap-card-opt',
})

export const TransactionTypeApi = Object.freeze({
    ADD_FUND: 'ADD_FUND',
    PAYMENT: 'PAYMENT',
    DEDUCTION: 'DEDUCTION',
    TRANSFER_FROM: 'TRANSFER_FROM',
    TRANSFER_TO: 'TRANSFER_TO',
    REFUND: 'REFUND',
    EXT_ADJUSTMENT: 'EXT_ADJUSTMENT',
    ADD_FUND_WITHOUT_STRIPE: 'ADD_FUND_WITHOUT_STRIPE',
    ADD_FUND_CREDIT_CARD: 'ADD_FUND_CREDIT_CARD',
    ADD_FUND_FROM_DISCOUNT: 'ADD_FUND_FROM_DISCOUNT',
    AUTO_TOPUP: 'AUTO_TOPUP',
    MANUAL_CREDIT: 'MANUAL_CREDIT',
    MANUAL_DEBIT: 'MANUAL_DEBIT',
    TERM_FARE_RESET: 'TERM_FARE_RESET',
})

export const DayOfWeek = Object.freeze({
    WEEKDAYS: 8,
    MONDAY: 2,
    TUESDAY: 3,
    WEDNESDAY: 4,
    THURSDAY: 5,
    FRIDAY: 6,
    SATURDAY: 7,
    SUNDAY: 1,
})

export const SmartCardType = Object.freeze({
    1: 'No Fare',
    2: 'Static Fare',
    3: 'Dynamic Fare',
    4: 'Temr Fare',
})

export const WeekDaysOptions = {
    weekdays: {
        id: 'weekdays',
        type: {
            am: {
                id: 'weekdaysAm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'am',
            },
            pm: {
                id: 'weekdaysPm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'pm',
            },
        },
    },
}

export const NotificationOptions = [
    {
        id: 'tap-card-opt',
        title: 'noti-tap-card',
        checked: false,
        value: 1,
    },
    {
        id: 'not-tap-card-opt',
        title: 'noti-not-tap-card',
        checked: false,
        value: 2,
    },
    {
        id: 'unapproved-tap-card-opt',
        title: 'noti-tap-unapproved',
        checked: false,
        value: 3,
    },
]

export const TimeframeOptionsConstant = {
    monday: {
        id: 'monday',
        type: {
            am: {
                id: 'mondayAm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'am',
            },
            pm: {
                id: 'mondayPm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'pm',
            },
        },
    },
    tuesday: {
        id: 'tuesday',
        type: {
            am: {
                id: 'tuesdayAm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'am',
            },
            pm: {
                id: 'tuesdayPm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'pm',
            },
        },
    },
    wednesday: {
        id: 'wednesday',
        type: {
            am: {
                id: 'wednesdayAm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'am',
            },
            pm: {
                id: 'wednesdayPm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'pm',
            },
        },
    },
    thursday: {
        id: 'thursday',
        type: {
            am: {
                id: 'thursdayAm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'am',
            },
            pm: {
                id: 'thursdayPm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'pm',
            },
        },
    },
    friday: {
        id: 'friday',
        type: {
            am: {
                id: 'fridayAm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'am',
            },
            pm: {
                id: 'fridayPm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'pm',
            },
        },
    },
    saturday: {
        id: 'saturday',
        type: {
            am: {
                id: 'saturdayAm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'am',
            },
            pm: {
                id: 'saturdayPm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'pm',
            },
        },
    },
    sunday: {
        id: 'sunday',
        type: {
            am: {
                id: 'sundayAm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'am',
            },
            pm: {
                id: 'sundayPm',
                from: '',
                to: '',
                checked: false,
                disabled: true,
                type: 'pm',
            },
        },
    },
}
