<template>
    <div>
        <accordion ref="accordionCard">
            <accordion-item v-for="card in cards" v-bind:key="card.id" class="accordion-container" :isShowIndicatorBtn="card.allow_notifications">
                <template v-slot:accordion-trigger>
                    <ion-grid :class="platform && platform === 'web' ? 'grid-container-web' : 'grid-container'">
                        <ion-row>
                            <ion-col size="10">
                                <div class="col-cont">
                                    <ion-label class="text-overline-style header-color">{{
                                        card.card_number
                                    }}</ion-label>
                                    <ion-label class="text-headline6-style header-color">{{
                                        card.consumer ? card.consumer.cardholder_name : ''
                                    }}</ion-label>
                                </div>
                            </ion-col>
                        </ion-row>
                        <ion-row class="ion-align-items-center ion-justify-content-end">
                            <ion-col size="12" class="toggle-container">
                                <ion-text class="text-body2-style ion-text-capitalize toggle-text">{{
                                    $t('smartcard-notification')
                                }}</ion-text>
                                <ion-toggle
                                    mode="md"
                                    class="top-up-toggle"
                                    @ionChange="toggleNotification($event, card)"
                                    :checked="card.allow_notifications"
                                ></ion-toggle>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </template>

                <template v-slot:accordion-content>
                    <smart-card-setting
                        v-if="card.allow_notifications"
                        :card="card"
                        :showLoading="showLoading"
                        :hideLoading="hideLoading"
                    ></smart-card-setting>
                </template>
            </accordion-item>
        </accordion>
    </div>
</template>

<script>
import { chevronDownOutline } from 'ionicons/icons'
import { Capacitor } from '@capacitor/core'
import Accordion from '../../components/accordion/Accordion'
import AccordionItem from '../../components/accordion/AccordionItem'
import SmartCardSetting from '../../views/SmartCard/SmartCardNotifcationSetting'
import notificationApi from '../../util/apis/notification'
export default {
    name: 'SmartcardNotificationList',
    props: {
        cards: Array,
        showLoading: Function,
        hideLoading: Function,
    },
    components: {
        Accordion,
        AccordionItem,
        SmartCardSetting,
    },

    data() {
        return {
            chevronDownOutline,
            platform: Capacitor.getPlatform(),
        }
    },
    methods: {
        async toggleNotification(event, card) {
            if (this.isReseting) {
                this.isReseting = false
                return
            }
            const checked = event.target.checked

            try {
                this.showLoading()
                this.$store.dispatch('setAllowCardReceiveNotification', {
                    value: checked,
                    user_consumer_id: card.user_consumer_id,
                })
                await notificationApi.setAllowCardReceiveNotification({
                    user_consumer_id: card.user_consumer_id,
                    receive_notification: checked,
                })
            } catch {
                this.isReseting = true
                this.$store.dispatch('setAllowCardReceiveNotification', {
                    value: !checked,
                    user_consumer_id: card.user_consumer_id,
                })
            } finally {
                this.hideLoading()
            }
        },
    },
}
</script>

<style scoped>
.grid-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    min-height: 129px;
}
.grid-container-web {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    min-height: 129px;
    padding: 20px;
}
.top-up-toggle {
    margin-left: 12px;
}
.card-img {
    width: 40px;
    height: 42px;
}
.accordion-container {
    padding: 0px;
    margin: 24px 0px;
    border: var(--ion-color-light) 1px solid;
    min-height: 129px;
}
.ellip-cont {
    position: 'relative';
}
.car-btn {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    color: var(--ion-color-sm-card-btn);
}
.col-cont {
    display: flex;
    flex-direction: column;
}
.menu-select-cont {
    position: 'absolute';
    bottom: 1px;
    right: 1px;
}
.header-color {
    color: var(--ion-color-text-normal-dark);
}
.down-icon-col {
    text-align: right;
    padding-right: 16px;
}
ion-icon {
    font-size: 24px;
}
.toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
ion-toggle {
    opacity: 1;
}
</style>
