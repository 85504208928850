import { auth } from '../../firebase'
import firebase from 'firebase/app'
import 'firebase/auth'
import appService from '../services/appService'


class AuthAppleService {
    constructor() {}

    createCredential(appleResponse) {
        // Create a custom OAuth provider
        const provider = new firebase.auth.OAuthProvider('apple.com')

        // Create sign in credentials with our token
        return provider.credential({
            idToken: appleResponse.identityToken,
        })
    }

    async createCredentialFirebaseUser(credential) {
        // Call the sign in with our created credentials
        const userCredential = await auth.signInWithCredential(credential)
        console.log('apple login', userCredential)
        return userCredential
        // Update the user document in Firestore
        // this.updateUserData(userCredential.user, appleResponse.givenName, appleResponse.familyName);
    }

    async signInWithAppleInBrowser() {
        const provider = new firebase.auth.OAuthProvider('apple.com')
        provider.addScope('email')
        provider.addScope('name')
        try {
            const result = await auth.signInWithPopup(provider)
            console.log('apple web login', result) // logged-in Apple user
            return result.user
        } catch (err) {
            appService.dismissLoading()
        }
    }
}

const srv = new AuthAppleService()
export default srv
