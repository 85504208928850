import { Geolocation } from '@capacitor/geolocation'
import appSrv from '../services/appService'
import { Capacitor } from '@capacitor/core'
class GeoLocationService {
    constructor() {}

    initGPS() {
        if (Capacitor.getPlatform() !== 'web') {
            this.registerGPS()
        } else {
            appSrv.storeDefaultLocation()
        }
    }

    registerGPS() {
        Geolocation.requestPermissions().then(async permission => {
            if (permission.location == 'granted') {
                const currentGeo = await Geolocation.getCurrentPosition()
                appSrv.storeCurrentLocation(currentGeo.coords)
                return true
            } else {
                console.log('registerGPS cancel', permission)
                appSrv.storeDefaultLocation()
            }
        })
    }
}

const srv = new GeoLocationService()
export default srv
