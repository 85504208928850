<template>
    <ion-page>
        <ion-progress-bar type="indeterminate" v-if="loading"></ion-progress-bar>

        <simple-header-toolbar
            :titleName="$t('select-routes')"
            :showDoneBtn="false"
            :routeMode="routeMode"
            :backFunction="backToSearchOperator"
        ></simple-header-toolbar>
        <favorite-list-tracking
            isHiddenNavBtn
            isHiddenCheckBtn
            :mode="'routes'"
            :dataList="routes"
            :hideLoading="hideLoading"
            :routeMode="routeMode"
            :showLoading="showLoading"
            :operatorId="parseInt(operatorId)"
        ></favorite-list-tracking>
    </ion-page>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import FavoriteListTracking from './FavoriteListTracking'
import SimpleHeaderToolbar from '../toolbar/SimpleHeaderToolbar'
import routeApi from '../../util/apis/route'
import { modalController } from '@ionic/vue'
import { busOutline } from 'ionicons/icons'

export default {
    name: 'FavoriteRoutes',
    props: {
        routeMode: String,
        operatorId: [String, Number],
    },
    components: {
        FavoriteListTracking,
        SimpleHeaderToolbar,
    },
    data: function() {
        return {
            loading: false,
            showDoneBtn: false,
            routes: [],
            busOutline,
        }
    },
    mounted: function() {
        this.loading = true
        this.$nextTick(function() {
            if (this.$route.params && this.operatorId) {
                const fav_operator_id = this.operatorId
                return this.getCanBeTrackRoutes(fav_operator_id)
            } else {
                this.loading = false
                this.showDoneBtn = false
            }
        })
    },
    computed: {
        ...mapState({
            user: state => state.user,
            companies: state => state.companies,
        }),
    },
    methods: {
        sortTrackRoutes(routes) {
            function getSelectedRoutes(routes) {
                return routes.filter(r =>
                    this.user.favorite_routes.find(
                        item => item.operator_id === this.operatorId && item.route_id === r.id
                    )
                )
            }

            function getActiveRoutes(routes, selectedRoutes) {
                return routes.filter(r => !r.is_route_end && !selectedRoutes.find(item => item.id === r.id))
            }

            function getInactiveRoutes(routes, selectedRoutes) {
                return routes.filter(r => r.is_route_end && !selectedRoutes.find(item => item.id === r.id))
            }

            function getRouteGroup(groupInfo, routes) {
                return routes.length > 0
                    ? [
                          {
                              id: groupInfo.id,
                              type: 'header',
                              title: groupInfo.name,
                              icon: groupInfo.icon,
                              count: routes.length,
                          },
                          ...routes,
                          {
                              id: `divider-active`,
                              type: 'divider',
                          },
                      ]
                    : []
            }

            const selectedRoutes = getSelectedRoutes.call(this, routes)

            return [
                ...getRouteGroup({ id: 'selected-header', name: 'Selected routes', icon: '' }, selectedRoutes),
                ...getRouteGroup(
                    { id: 'active-header', name: 'Active routes', icon: this.busOutline },
                    getActiveRoutes(routes, selectedRoutes)
                ),
                ...getRouteGroup(
                    { id: 'inactive-header', name: 'Inactive routes', icon: this.busOutline },
                    getInactiveRoutes(routes, selectedRoutes)
                ),
            ]
        },
        sortFavRoutes(routes) {
            let sortRoutes = []
            const cloneRoutes = _.cloneDeep(routes)
            if (this.user && this.user.favorite_routes.length > 0) {
                const favRoutes = this.user.favorite_routes

                for (let i = 0; i < cloneRoutes.length; i++) {
                    if (favRoutes.includes(cloneRoutes[i].id)) {
                        sortRoutes.push(cloneRoutes[i])
                    }
                }
                _.remove(cloneRoutes, function(item) {
                    return favRoutes.includes(item.id)
                })
            }
            return sortRoutes.concat(cloneRoutes)
        },
        sortRoutes(routes) {
            if (this.routeMode === 'trackingBus') {
                return this.sortTrackRoutes(routes)
            } else {
                return this.sortFavRoutes(routes)
            }
        },
        getCanBeTrackRoutes(fav_operator_id) {
            return routeApi
                .getRoutesCanBeTrack({
                    fav_operator_id: parseInt(fav_operator_id),
                })
                .then(response => {
                    this.loading = false
                    if (response.data.canBeTrackRoutes && response.data.canBeTrackRoutes.length > 0) {
                        this.routes = this.sortRoutes(response.data.canBeTrackRoutes)
                        this.showDoneBtn = true
                        console.log(this.routes)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        backToSearchOperator() {
            modalController.dismiss()
        },
        hideLoading() {
            this.loading = false
        },
        showLoading() {
            this.loading = true
        },
    },
}
</script>
<style scope>
.content {
    position: relative;
    margin-bottom: 20px;
}

</style>
