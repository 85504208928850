import apolloClient from '../../apolloClient'
import {
    UPDATE_USER_NOTIFICATION_CONFIG,
    CREATE_NOTIFICATION_OPTION,
    SAVE_NOTIFICATION_OPTION,
    SAVE_NOTIFICATION_TIMEFRAME,
    SET_CARD_RECEIVE_NOTIFICATION,
    SET_SMARTCARD_LOW_BALANCE_RECEIVE_NOTI,
    SET_BUS_DELAYS_NOTIFICATION,
    READ_OPERATOR_MESSAGE,
} from '../../graphql/mutations/notification'

// import store from "../../store";

class NotificationApi {
    constructor() {}

    // getNotificationConfig() {
    //     return apolloClient.mutate({
    //         query: GET_NOTIFICATION,
    //         fetchPolicy: "no-cache",
    //     });
    // }
    updateUserNotificationConfig(params) {
        return apolloClient.mutate({
            mutation: UPDATE_USER_NOTIFICATION_CONFIG,
            fetchPolicy: 'no-cache',
            variables: {
                ...params,
            },
        })
    }
    createNotificationOption(params) {
        return apolloClient.mutate({
            mutation: CREATE_NOTIFICATION_OPTION,
            fetchPolicy: 'no-cache',
            variables: {
                ...params,
            },
        })
    }

    saveNotificationTimeframe(params) {
        return apolloClient.mutate({
            mutation: SAVE_NOTIFICATION_TIMEFRAME,
            fetchPolicy: 'no-cache',
            variables: {
                timeframesJson: JSON.stringify(params),
            },
        })
    }
    saveNotificationOption(params) {
        return apolloClient.mutate({
            mutation: SAVE_NOTIFICATION_OPTION,
            fetchPolicy: 'no-cache',
            variables: {
                ...params,
            },
        })
    }
    setAllowCardReceiveNotification(params) {
        return apolloClient.mutate({
            mutation: SET_CARD_RECEIVE_NOTIFICATION,
            fetchPolicy: 'no-cache',
            variables: {
                ...params,
            },
        })
    }
    setSmartcardLowBalanceReceiveNotification(params) {
        return apolloClient.mutate({
            mutation: SET_SMARTCARD_LOW_BALANCE_RECEIVE_NOTI,
            fetchPolicy: 'no-cache',
            variables: {
                receive_notification: params.receive_notification,
            },
        })
    }
    setBusDelayNotification(params) {
        return apolloClient.mutate({
            mutation: SET_BUS_DELAYS_NOTIFICATION,
            fetchPolicy: 'no-cache',
            variables: {
                receive_notification: params.receive_notification,
            },
        })
    }
    readOperatorMessage(params) {
        return apolloClient.mutate({
            mutation: READ_OPERATOR_MESSAGE,
            fetchPolicy: 'no-cache',
            variables: {
                operator_id: params.operator_id,
                message_id: params.message_id,
            },
        })
    }
}

const srv = new NotificationApi()
export default srv
