<template>
    <ion-page>
        <simple-header-toolbar :titleName="$t('top-up')" :backFunction="back"> </simple-header-toolbar>
        <ion-progress-bar type="indeterminate" v-if="loading || !user"></ion-progress-bar>
        <ion-content>
            <div class="ion-padding" v-if="user && stateCards.length > 0">
                <ion-row class="ion-align-items-center">
                    <ion-col size="8">
                        <ion-text class="text-body2-style ion-text-capitalize">{{ $t('auto-top-up-string') }}</ion-text>
                    </ion-col>
                    <ion-col size="4" class="toggle-container">
                        <ion-toggle
                            mode="md"
                            :checked="autoTopup"
                            @ionChange="onToggleAutoTopUpChange($event.target.checked)"
                            ref="toggleAutoTopUp"
                        ></ion-toggle>
                    </ion-col>
                </ion-row>

                <ion-row class="ion-align-items-center" v-if="autoTopup">
                    <ion-col size="8">
                        <ion-text class="text-body2-style ion-text-capitalize">{{ $t('all-smartcards') }}</ion-text>
                    </ion-col>
                    <ion-col size="4" class="toggle-container">
                        <ion-toggle
                            mode="md"
                            :checked="allCardAutoTopup"
                            @ionChange="onToggleAllCardsChange($event.target.checked)"
                            @input="inputForcus($event)"
                            ref="toggleAllSmartcard"
                        ></ion-toggle>
                    </ion-col>
                </ion-row>

                <p class="border-list-container" v-if="isHaveBorderBottom && autoTopup"></p>
                <smartcard-list-simple
                    v-if="!allCardAutoTopup && autoTopup && stateCards.length > 0"
                    v-on:save-config="handleSaveConfig"
                    :cards="stateCards"
                    :showLoading="showLoading"
                    :hideLoading="hideLoading"
                ></smartcard-list-simple>
                <smart-card-top-up-mode
                    v-if="user.all_card_auto_topup && autoTopup && firstCard && firstCard.auto_topup_config"
                    v-on:save-config="handleSaveConfig"
                    :card="firstCard"
                ></smart-card-top-up-mode>
            </div>
            <div v-else class="content-empty-screen">
                <add-smart-card></add-smart-card>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { mapState } from 'vuex';
import SimpleHeaderToolbar from '../../components/toolbar/SimpleHeaderToolbar'
import SmartcardListSimple from '../../components/smartcard/SmartcardListSimple'
import SmartCardTopUpMode from '../../views/SmartCard/SmartCardTopUpMode'
import autoTopupApi from '../../util/apis/autoTopup'
import stripeApi from '../../util/apis/stripe'
import { Capacitor } from '@capacitor/core'
import alertSrv from '../../util/services/alertService'
import appSrv from '../../util/services/appService'
import StripeDirectDebitModal from '../modal/StripeDirectDebitModal'
import smartcardService from '../../util/services/smartcardService'
import AddSmartCard from '../../components/card/AddSmartCard'
import { modalController } from '@ionic/vue'
import useNavigator from '@/composables/useNavigator'
import appService from '../../util/services/appService'
import authSrv from '../../util/services/authService'

export default {
    name: 'SmartCardAutoTopUp',
    components: {
        SimpleHeaderToolbar,
        SmartcardListSimple,
        SmartCardTopUpMode,
        AddSmartCard,
    },
    data: function() {
        return {
            loading: false,
            isHaveBorderBottom: false,
            backEventHandler: null,
            platform: Capacitor.getPlatform(),
        }
    },
    mounted: function() {
        this.$nextTick(async () => {
            this.showLoading()
            this.backEventHandler = await appService.addListenerBackBtn(this.back)
            try {
                const checkValidDirectDebit = await this.checkToOpenDirectDebitModal()
                if (checkValidDirectDebit) {
                    await smartcardService.prepareSmartcards()
                } else {
                    this.$router.replace({ name: 'smartCardList', force: true })
                }
            } finally {
                this.hideLoading()
            }
        })
    },
    async beforeUnmount() {
        if (this.backEventHandler) {
            await this.backEventHandler.remove()
        }
    },
    computed: {
        ...mapState({
            stateCards: state => state.smartcards,
            user: state => state.user,
            allCardAutoTopup: state => state.user.all_card_auto_topup,
            autoTopup: state => state.user.auto_topup,
        }),
        firstCard() {
            return this.$store.state.smartcards[0]
        },
    },
    methods: {
        async back() {
            if (!(await appService.shouldIgnoreBackHandler())) {
                useNavigator.replaceHome()
            }
        },
        showLoading() {
            this.loading = true
        },
        hideLoading() {
            this.loading = false
        },

        async onToggleAutoTopUpChange(checked) {
            if (this.isReseting) {
                this.isReseting = false
                return
            }

            try {
                this.showLoading()
                if (!checked) {
                    await this.turnOffAutoTopup(checked)
                } else {
                    await this.turnOnAutoTopup()
                }
            } finally {
                this.hideLoading()
            }
        },
        async turnOnAutoTopup() {
            try {
                this.$store.dispatch('setAutoTopup', true)
                await autoTopupApi.toggleAutoTopup({ is_all: true, isAutoTopup: true })
            } catch {
                this.isReseting = true
                this.$store.dispatch('setAutoTopup', false)
            }
        },
        async turnOffAutoTopup() {
            try {
                this.$store.dispatch('setAutoTopup', false)
                await autoTopupApi.toggleAutoTopup({ is_all: true, isAutoTopup: false })
            } catch {
                this.isReseting = true
                this.$store.dispatch('setAutoTopup', true)
            }
        },

        async onToggleAllCardsChange(checked) {
            if (this.isReseting) {
                this.isReseting = false
                return
            }

            try {
                this.showLoading()
                if (!checked) {
                    await this.turnOffAllCards()
                } else {
                    await this.turnOnAllCards()
                }
            } finally {
                this.hideLoading()
            }
        },
        async turnOnAllCards() {
            try {
                this.$store.dispatch('setAllCardAutoTopup', true)
                const configs = await autoTopupApi.turnOnAllCardAutoTopup()
                this.$store.dispatch('setCardConfigs', configs.data.turnOnAllCardTopUpConfig)
            } catch {
                this.isReseting = true
                this.$store.dispatch('setAllCardAutoTopup', false)
            }
        },
        async turnOffAllCards() {
            try {
                this.$store.dispatch('setAllCardAutoTopup', false)
                await autoTopupApi.turnOffAllCardAutoTopup({ is_all: false })
            } catch {
                this.isReseting = true
                this.$store.dispatch('setAllCardAutoTopup', true)
            }
        },
        async handleSaveConfig(config) {
            if (!this.verifyConfig(config)) {
                return
            }
            try {
                this.showLoading()

                // check to open stripe direct debit
                // const checkValidDirectDebit = await this.checkToOpenDirectDebitModal();
                // if (checkValidDirectDebit) {
                if (this.user.all_card_auto_topup) {
                    config.is_all = true
                }
                await autoTopupApi.saveAutoTopUpSmartcard(config)
                if (config.is_all) {
                    this.$store.dispatch('setConfigToAllCard', config)
                } else {
                    this.$store.dispatch('setAutoTopupConfig', config)
                }
                alertSrv.toastSuccess('Saved')
                // }
            } finally {
                this.hideLoading()
            }
        },
        verifyConfig(config) {
            if (!config.amount || !config.trigger_balance) {
                alertSrv.toastError('Please enter Top Up Amount and Trigger condition')
                return false
            } else if (config.trigger_balance < 5) {
                alertSrv.toastError('Trigger amount must be at least $5')
            } else if (config.amount < 10 || config.amount > 100) {
                alertSrv.toastError('Top Up amount must be at least $10 and maxium $100')
            } else {
                return true
            }
        },

        async checkToOpenDirectDebitModal() {
            const hasStripeDirectDebitSetup = await stripeApi.checkStripeDirectDebitInfor()
            if (hasStripeDirectDebitSetup) {
                if (!authSrv.checkAppleSignInWithoutEmail()) {
                    return false
                }
                return true
            }

            const rolePress = await appSrv.presentAlert({
                header: 'Auto Topup',
                message: 'Please setup your payment details to enable this feature.',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    {
                        text: 'OK',
                        role: 'ok',
                    },
                ],
            })
            if (rolePress === 'ok') {
                if (await this.openCheckoutModal()) {
                    return true
                }
            }

            return false
        },

        async openCheckoutModal() {
            let cssClass = 'direct-debit-modal-class'
            if (this.platform != 'ios' || this.platform != 'android') {
                cssClass = 'direct-debit-modal-class-web'
            }
            let modal = await modalController.create({
                component: StripeDirectDebitModal,
                cssClass,
                componentProps: {
                    openMode: 'openFromAutoTopup',
                },
            })

            // show the modal
            await modal.present()

            // wait to see if i get a response
            let modalResponse = await modal.onDidDismiss()

            // when dismissed by clicking outside of modal,
            // data is undefined so we do not handle it
            if (modalResponse.data && modalResponse.data.setupIntent) {
                await stripeApi.saveStripeSetupIntent({
                    setup_intent_id: modalResponse.data.setupIntent.id,
                })
                return true
            }

            return false
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
</style>
