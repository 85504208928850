class HelperSrv {
    constructor() { }

    
    getDayOfWeekNumber(dayString) {
        switch (dayString) {
            case 'monday':
                return 1
            case 'tuesday':
                return 2
            case 'wednesday':
                return 3
            case 'thursday':
                return 4
            case 'friday':
                return 5
            case 'saturday':
                return 6
            case 'sunday':
                return 7
            default:
                return 8
        }
    }
    getDayOfWeekString(dayNumber) {
        switch (dayNumber) {
            case 1:
                return 'monday'
            case 2:
                return 'tuesday'
            case 3:
                return 'wednesday'
            case 4:
                return 'thursday'
            case 5:
                return 'friday'
            case 6:
                return 'saturday'
            case 7:
                return 'sunday'
            default:
                return 'weekdays'
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    setElementAttribute(eleName, attrName, attrValue) {
        const element = document.getElementById(eleName);
        if (element != undefined) {
            element.setAttribute(attrName, attrValue);
        }
    }

    removeElementAttribute(eleName, attrName) {
        const element = document.getElementById(eleName);
        if (element != undefined) {
            element.removeAttribute(attrName);
        }
    }
}

const srv = new HelperSrv();
export default srv;
