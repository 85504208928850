<template>
    <ion-header>
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-button @click="goBack" aria-label="Go Back">
                    <ion-icon aria-hidden="true" name="chevron-back-outline" class="arrow-back-icon no-zoom-el"></ion-icon>
                </ion-button>
            </ion-buttons>
            <ion-title class="ion-text-capitalize no-zoom-el" ref="titleLabel" tabindex="-1" role="heading">{{ titleName }}</ion-title>
        </ion-toolbar>
        <ion-card>
            <ion-card-content>
                <button-single-select
                    :list="operatorList"
                    :selectedItem="selectedOperator"
                    :selectItem="selectOperator"
                    defaultPlaceholder="Select Operator"
                    class="top-space"
                    v-if="showingSelectOperatorHeader"
                ></button-single-select>
                <span id="qr-scan-instruction">Put your camera up to the QR Code on the smartcard.</span>
            </ion-card-content>
        </ion-card>
    </ion-header>
</template>

<script>
import companyApi from '../../util/apis/company'
import ButtonSingleSelect from '../../components/select/ButtonSingleSelect.vue'
export default {
    name: 'QrCodeHeader',
    props: {
        titleName: String,
        backFunction: Function,
    },
    components: {
        ButtonSingleSelect,
    },
    data: function() {
        return {
            operatorList: [],
        }
    },
    computed: {
        selectedOperator() {
            return this.$store.getters.user ? this.$store.getters.user.fav_operator_id : null
        },
        showingSelectOperatorHeader() {
            return this.$store.getters.showingSelectOperatorHeader
        },
    },
    mounted: async function() {
        this.$nextTick(async () => {
            const companies = await companyApi.getActiveCompanies()
            this.operatorList = companies.map(com => {
                return {
                    id: com.company_id,
                    value: com.company_id,
                    name: com.name,
                    timezone: com.timezone,
                    privacy_text: com.privacy_text,
                }
            })
            console.log('next tick')
            this.$nextTick(() => {
                console.log('settingfocus')
                this.$refs.titleLabel.$el.focus()
            })
        })
    },
    methods: {
        selectOperator(companyId) {
            this.$store.dispatch('setCompanyXId', companyId)
        },
        goBack() {
            if (this.backFunction) {
                this.backFunction()
            } else {
                this.$router.back()
            }
        },
        goHome() {
            this.$router.replace({ name: 'Home' })
        },
        onDoneClick() {
            this.done()
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ion-toolbar {
    --color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
}
.home-btn {
    margin-right: 8px;
}

ion-button .done-txt {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
}
ion-title {
    text-align: center;
}
ion-card {
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 0;
}
.arrow-back-icon {
    color: #ffffff;
}
ion-card-content .top-space {
    margin-top: 10px !important;
    margin-bottom: 14px;
}
ion-title:focus {
    outline: none;
}
</style>
