import { createRouter, createWebHistory } from '@ionic/vue-router'
// Views
import SignIn from '../views/SignIn.vue'
import SignUp from '../views/SignUp.vue'
import VerifyEmail from '../views/VerifyEmail.vue'
import Main from '../views/Main.vue'
import SmartCard from '../views/SmartCard/SmartCard'
import SmartCardHome from '../views/SmartCard/SmartCardHome'
import SmartCardAddNew from '../views/SmartCard/SmartCardAddNew'
import SmartCardUseUid from '../views/SmartCard/SmartCardUseUid'
import SmartCardLink from '../views/SmartCard/SmartCardLink'
import SmartCardForgetPassword from '../views/SmartCard/SmartcardForgetPassword'
import SmartCardList from '../views/SmartCard/SmartCardList'
import SmartCardTransactions from '../views/SmartCard/SmartCardTransactions'
import SmartCardAddFund from '../views/SmartCard/SmartCardAddFund'
import SmartCardAutoTopUp from '../views/SmartCard/SmartCardAutoTopUp'
import SmartCardNotification from '../views/SmartCard/SmartCardNotification'
// view
// import Favorite from "@/components/favorite/Favorite.vue";
import FavoriteRoutes from '@/components/favorite/FavoriteRoutes.vue'
// import Setttings from "@/components/settings/Settings.vue";
import Home from '@/components/home/Home.vue'
import authSrv from '../util/services/authService'
import store from '../store'

const isSmartCardsAdded = () => {
    const smartcards = store.getters.smartcards
    return smartcards && smartcards.length > 0
}

const routes = [
    // login, sign up
    { path: '/main', redirect: '/main/home' },
    {
        path: '/main',
        name: 'main',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '/main/home',
                name: 'Home',
                component: Home,
            },
            {
                path: '/main/favorite',
                name: 'Favorite',
                component: () => import('@/components/favorite/Favorite.vue'),
            },
            {
                path: '/main/favorite/fav-routes',
                name: 'FavoriteRoutes',
                component: FavoriteRoutes,
            },
            {
                path: '/main/settings',
                name: 'Settings',
                component: () => import('@/components/settings/Settings.vue'),
            },
            {
                path: '/main/help',
                name: 'Help',
                component: () => import('@/views/Help.vue'),
            },
        ],
    },
    {
        path: '/premium',
        name: 'Premium',
        meta: { requiresAuth: true },
        component: () => import('@/views/premium/Premium.vue'),
    },
    // live tracking
    {
        path: '/live-tracking',
        name: 'live-tracking',
        meta: { requiresAuth: true },
        component: () => import('@/views/LiveTracking.vue'),
    },
    {
        path: '/live-tracking/operator',
        name: 'searchTrackingOperator',
        meta: { requiresAuth: true },
        component: () => import('@/components/favorite/Favorite.vue'),
    },
    {
        path: '/live-tracking/routes',
        name: 'searchTrackingRoutes',
        meta: { requiresAuth: true },
        component: () => import('@/components/favorite/FavoriteRoutes.vue'),
    },
    // // smart card
    { path: '/smartcard', redirect: '/smartcard/smartCardList' },
    { path: '/smartcard/smartCardList/transactions', redirect: '/smartcard/smartCardList' },
    {
        path: '/smartcard',
        name: 'smartcard',
        component: SmartCard,
        meta: { requiresAuth: true },
        children: [
            {
                path: '/smartcard/linkSmartcard',
                name: 'smartCardLink',
                component: SmartCardLink,
                props: true,
            },
            {
                path: '/smartcard/addSmartCard/forgetPassword',
                name: 'smartcardForgetPassword',
                component: SmartCardForgetPassword,
            },
            {
                path: '/smartcard/smartCardList',
                name: 'smartCardList',
                component: SmartCardList,
            },
            {
                path: '/smartcard/transactions',
                name: 'smartCardTransactions',
                component: SmartCardTransactions,
            },
            {
                path: '/smartcard/addNewCard',
                name: 'smartCardAddNew',
                component: SmartCardAddNew,
                props: true,
            },
            {
                path: '/smartcard/useUid',
                name: 'smartCardUseUid',
                component: SmartCardUseUid,
                props: true,
            },
            {
                path: '/smartcard/addFund',
                name: 'smartCardAddFund',
                component: SmartCardAddFund,
            },
            {
                path: '/smartcard/smartCardAutoTopUp',
                name: 'smartCardAutoTopUp',
                component: isSmartCardsAdded ? SmartCardAutoTopUp : SmartCardHome,
            },
            {
                path: '/smartcard/smartCardNotification',
                name: 'smartCardNotification',
                component: isSmartCardsAdded ? SmartCardNotification : SmartCardHome,
            },
        ],
    },
    // Login
    {
        path: '/',
        name: 'SignIn',
        component: SignIn,
    },
    {
        path: "/auth",
        name: "FbAuthenticationHanlder",
        // route level code-splitting
        // this generates a separate chunk (auth.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "../views/FbAuthenticationHanlder.vue"
          ),
      },
    // name of the route have to === component name
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUp,
    },
  
    {
        path: '/verifyEmail',
        name: 'VerifyEmail',
        component: VerifyEmail,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(async (to, from, next) => {
    const isLoggedIn = await authSrv.hasCurrentLoggedInUser()
    if (!isLoggedIn || (to.params && to.params.force)) {
        if (to.name === 'SignIn' || to.name === 'SignUp' || to.name === 'VerifyEmail' || to.name == 'FbAuthenticationHanlder') {
            next()
        } else {
            next('/')
        }
    } else {
        const authCallback = async () => {
            if (to.name == 'SignIn') {
                // if user have fav operator move to Home else move to Favorite screen
                const jsonLocalUser = await store.getters.localUser
                const localUser = JSON.parse(jsonLocalUser)
                if (localUser && localUser.fav_operator_id) {
                    next({ name: 'Home' })
                } else {
                    next({ name: 'Favorite' })
                }
            } else {
                if (to.matched.some(record => record.meta.requiresAuth)) {
                    next()
                }
            }
        }
        await authCallback();
    }
})

export default router
