import gql from 'graphql-tag'

export const DEL_SMART_CARD = gql`
    mutation delSmartCardSaved($user_consumer_id: Int!) {
        delSmartCardSaved(user_consumer_id: $user_consumer_id) {
            message
            status
        }
    }
`

export const IS_SMARCARD_INACTIVE = gql`
    mutation isSmartcardInactive($cardNumber: String!) {
        isSmartcardInactive(cardNumber: $cardNumber)
    }
`


export const CHECK_SMARTCARD_REPLACE_STATUS = gql`
    mutation checkSmartcardReplaceStatus($cardNumber: String!) {
        checkSmartcardReplaceStatus(cardNumber: $cardNumber)
    }
`

export const REGISTER_CARD = gql`
    mutation registerCard($userName: String!, $password: String!, $cardNumber: String!) {
        registerCard(userName: $userName, password: $password, cardNumber: $cardNumber) {
            id
            card_number
            consumer {
                id
                cardholder_name
                user_name
            }
            balance
            amount
            allow_notifications
            smart_card_type
            expire_date
            notification_status
            company {
                company_id
                name
                timezone
            }
            auto_topup_config {
                id
                card_number
                amount
                trigger_balance
            }
            notification_timeframes {
                clock_type
                from
                to
                day_of_week
                card_number
            }
            notification_options {
                card_number
                notify_on_tapped
                notify_on_not_tapped
                notify_on_wrong_stop
            }
            is_smartcard_onboard
            boarding_route_id
            boarding_dbr_id
            user_consumer_id
        }
    }
`

export const REPLACE_CARD = gql`
    mutation replaceSmartcard($cardNumberNew: String!, $cardNumberOld: String!) {
        replaceSmartcard(cardNumberNew: $cardNumberNew, cardNumberOld: $cardNumberOld) {
           id
           card_number
        }
    }
`

export const CHANGE_PASSWORD = gql`
    mutation changeSmartcardPassword(
        $user_name: String!
        $verifyCode: String!
        $new_password: String!
        $confirm_password: String!
    ) {
        changeSmartcardPassword(
            verifyCode: $verifyCode
            user_name: $user_name
            new_password: $new_password
            confirm_password: $confirm_password
        ) {
            status
            message
        }
    }
`

export const SEND_VERIFY_CODE = gql`
    mutation sendVerifyCode($user_name: String!) {
        sendVerifyCode(user_name: $user_name) {
            status
            message
        }
    }
`

export const VERIFY_CODE = gql`
    mutation verifyChangeSmartcardPasswordCode($verify_code: String!, $user_name: String!) {
        verifyChangeSmartcardPasswordCode(verify_code: $verify_code, user_name: $user_name) {
            status
            error_code
            message
            token
        }
    }
`

export const AUTO_TOP_UP = gql`
    mutation saveAutoTopUpConfig(
        $amount: Float!
        $trigger_balance: Float!
        $is_all: Boolean!
        $user_consumer_id: Int
    ) {
        saveAutoTopUpConfig(
            amount: $amount
            trigger_balance: $trigger_balance
            is_all: $is_all
            user_consumer_id: $user_consumer_id
        )
    }
`

export const DEL_AUTO_TOP_UP = gql`
    mutation deleteAutoTopUpConfig {
        deleteAutoTopUpConfig
    }
`

export const PREPARE_STRIPE_SET_UP_INTENT = gql`
    mutation prepareSetupIntent {
        prepareSetupIntent
    }
`

export const SAVE_SET_UP_INTENT = gql`
    mutation saveSetupIntent($setup_intent_id: String!) {
        saveSetupIntent(setup_intent_id: $setup_intent_id) {
            message
            status
        }
    }
`

export const REMOVE_LINKED_CARD = gql`
    mutation removeLinkedCard {
        removeLinkedCard {
            message
            status
        }
    }
`

export const UPDATE_SET_UP_INTENT = gql`
    mutation updateSetupIntent($email: String!, $setup_intent_id: String!) {
        updateSetupIntent(email: $email, setup_intent_id: $setup_intent_id)
    }
`

export const CHARGE_DIRECT = gql`
    mutation chargeDirect($amount: Float!, $user_consumer_id: Int!, $del_payment_method: Boolean!) {
        chargeDirect(amount: $amount, user_consumer_id: $user_consumer_id, del_payment_method: $del_payment_method) {
            message
            status
        }
    }
`

export const DEL_SMARTCARD_AUTO_CONFIG = gql`
    mutation deleteSmartcardAutoTopUpConfig($user_consumer_id: Int) {
        deleteSmartcardAutoTopUpConfig(user_consumer_id: $user_consumer_id)
    }
`
