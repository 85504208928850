import router from '../router'
import Premium from '@/views/premium/Premium'
import { modalController } from '@ionic/vue'
import { Capacitor } from '@capacitor/core'

const isAndroid = Capacitor.getPlatform() == 'android'
const isIOS = Capacitor.getPlatform() == 'ios'

const goToLiveTracking = () => {
    router.push({ name: 'live-tracking' })
}

const replaceToLiveTracking = () => {
    router.replace({ name: 'live-tracking' })
}

const goToFavorite = params => {
    router.push({ name: 'Favorite', params })
}

const replaceToFavorite = params => {
    router.replace({ name: 'Favorite', params })
}

const goHome = () => {
    router.replace({
        name: 'Home',
    })
}

const goToSignUp = () => {
    router.replace({
        name: 'SignUp',
    })
}

const goSmartcardTracking = data => {
    const params = {
        openMode: 'SmartCardTracking',
        notificationType: data.notificationType,
        operatorId: parseInt(data.companyId),
        routeId: parseInt(data.routeId),
        dbrId: parseInt(data.dbrId),
    }

    if (data.cardNumber) {
        params.cardNumber = data.cardNumber
    }

    router.replace({
        name: 'live-tracking',
        params,
    })
}

const goVerifyEmail = () => {
    router.replace({
        name: 'VerifyEmail',
    })
}

const replaceHome = () => {
    router.replace({
        name: 'Home',
    })
}

const goToSetting = () => {
    router.replace({
        name: 'Settings',
    })
}

const replaceToHome = () => {
    router.replace('/')
}

const goBack = () => {
    router.back()
}

const goToSmartcard = () => {
    router.replace({ name: 'smartCardList', force: true })
}

const goToSmartcardTopUp = () => {
    router.replace({ name: 'smartCardAutoTopUp', force: true })
}

const goToSmartcardNotification = () => {
    router.replace({ name: 'smartCardNotification', force: true })
}
const goToPremiumUpgrade = async () => {
    let modal = await modalController.create({
        component: Premium,
        cssClass: 'premium-modal-class',
    })
    await modal.present()
    // wait to see if i get a response
    let modalResponse = await modal.onDidDismiss()
    return modalResponse.data
}

const goToSmartcardForgetPassword = () => {
    router.push({
        name: 'smartcardForgetPassword',
    })
}

const goToSmartcardTransactions = (cardNumber, timezone, cardholderName) => {
    router.replace({
        name: 'smartCardTransactions',
        params: {
            cardNumber,
            timezone,
            passenger: cardholderName,
        },
    })
}

const goToMobileStore = () => {
    isAndroid && goToPlayStore()
    isIOS && goToAppStore()
}
const goToPlayStore = () => {
    var url = 'https://play.google.com/store/apps/details?id=com.transportmeptyltd.transportmepassenger'
    window.open(url, '_system', 'location=yes')
}
const goToAppStore = () => {
    window.open('https://itunes.apple.com/us/app/transportme-passenger/id1024907105')
}

export default {
    goToLiveTracking,
    goToSmartcardTransactions,
    goToFavorite,
    goToSmartcard,
    goToSmartcardTopUp,
    goToSmartcardNotification,
    goToPremiumUpgrade,
    goHome,
    goToSignUp,
    goBack,
    replaceToLiveTracking,
    goVerifyEmail,
    replaceToFavorite,
    replaceHome,
    replaceToHome,
    goToSmartcardForgetPassword,
    goSmartcardTracking,
    goToMobileStore,
    goToSetting,
}
