<template>
    <ion-grid ref="content">
        <ion-row>
            <ion-col size="4">
                <ion-text class="text-body2-style">{{ transaction.displayDate }}</ion-text>
            </ion-col>
            <ion-col size="5">
                <div class="col-row-cont">
                    <ion-img :src="transactionImg" class="icon-desc-info"></ion-img>
                    <ion-text class="text-body2-style">{{ TransactionTypeLayOut[transaction.transaction_type] }}</ion-text>
                </div>
            </ion-col>
            <ion-col size="3" class="align-end">
                <ion-text class="text-body2-style ion-text-nowrap">{{ transaction.displayAmount }}</ion-text>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="4"></ion-col>
            <ion-col size="6">
                <div class="col-row-cont">
                    <ion-img :src="routeImg" class="icon-desc-info"></ion-img>
                    <ion-text class="text-body2-style">{{ transaction.route_name }}</ion-text>
                </div>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="4">
                <div class="col-row-cont">
                    <ion-icon :src="loginIcon" class="icon-outline"></ion-icon>
                    <ion-text class="text-body2-style">{{ transaction.displayTime }}</ion-text>
                </div>
            </ion-col>
            <ion-col size="6">
                <div class="col-row-cont">
                    <ion-img :src="boardingImg" class="icon-desc-info"></ion-img>
                    <ion-text class="text-body2-style">{{ transaction.boarding_name }}</ion-text>
                </div>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="4">
                <div class="col-row-cont">
                    <ion-icon :src="logoutIcon" class="icon-outline"></ion-icon>
                    <ion-text class="text-body2-style">{{ transaction.transactionTime }}</ion-text>
                </div>
            </ion-col>
            <ion-col size="6">
                <div class="col-row-cont">
                    <ion-img :src="deboardingImg" class="icon-desc-info"></ion-img>
                    <ion-text class="text-body2-style">No Tap Off</ion-text>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script>
import { TransactionTypeApi, TransactionTypeLayOut } from '../../util/constant'
export default {
    name: 'TransactionTypeDeduction',
    props: {
        transaction: Object,
    },
    data: function () {
        return {
            TransactionTypeApi,
            TransactionTypeLayOut,
            routeImg: require('../../assets/location_arrow.png'),
            boardingImg: require('../../assets/bus_stop.png'),
            deboardingImg: require('../../assets/bus_ac_wc.png'),
            transactionImg: require('../../assets/imoney-bag-48.png'),
            loginIcon: require('../../assets/log-in-outline.svg'),
            logoutIcon: require('../../assets/log-out-outline.svg'),
        }
    },
    methods: {},
}
</script>

<style scoped>
.col-row-cont {
    display: flex;
    flex-direction: row;
}
.icon-desc-info {
    min-width: 20px;
}
ion-img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.align-end {
    justify-content: flex-end;
    display: flex;
}
.align-center {
    justify-content: center;
    display: flex;
}
.icon-outline {
    font-size: 20px;
    margin-right: 5px;
}
</style>
