import { Purchases } from '@ionic-native/purchases'
import inAppPurchaseApi from '../apis/inAppPurchase'
import store from '../../store'
import localStorageSrv from '../services/storageService'
import appVariantSrv from '../services/appVariantService'

class FakeInAppPurchaseService {
    initPurchase(userId) {
        console.log('fakeInitPurchase', userId)
    }
    signOut() {
        console.log('fakeSignOut')
    }

    async reCheckUserSignedPremium() {
        return true
    }
}

class InAppPurchaseService {
    constructor() {
        this.userId = null
    }

    initPurchase(userId) {
        if (userId != this.userId) {
            console.log(`==========initPurchase for ${userId}=========`)
            this.userId = userId
            Purchases.setDebugLogsEnabled(true)
            Purchases.setup(process.env.VUE_APP_REVENUECAT_PUBLIC_KEY, userId)
        }
    }
    signOut() {
        Purchases.reset()
        this.userId = null
    }

    async reCheckUserSignedPremium() {
        let didSignedPremium = false
        const user = store.getters.user
        const didSetupPurchase = await localStorageSrv.getItem('didSetupPurchase')
        if (user && !user.is_premium && didSetupPurchase) {
            Purchases.getPurchaserInfo().then(async purchaseRes => {
                console.log('Setting purchase infor', purchaseRes)
                localStorageSrv.removeItem('didSetupPurchase')
                if (
                    purchaseRes.entitlements.active &&
                    purchaseRes.entitlements.active.premium &&
                    purchaseRes.entitlements.active.premium !== 'undefined'
                ) {
                    await inAppPurchaseApi.verifyUserPurchase()
                    didSignedPremium = true
                }
            })
        }
        return didSignedPremium
    }
}

const srv = appVariantSrv.isFeatureEnabled('subscription')? new InAppPurchaseService(): new FakeInAppPurchaseService()
export default srv
