<template>
    <ion-page>
        <ion-content>
            <div v-if="platform !== 'web'">
                <ion-card
                    v-for="pa in initPackages"
                    :key="pa.identifier"
                    :class="{
                        highlight: pa.packageType === 'ANNUAL',
                        normal: pa.packageType === 'MONTHLY',
                    }"
                >
                    <div class="column-content">
                        <ion-label class="card-title">{{
                            pa.packageType === 'ANNUAL' ? $t('yearly-plan') : $t('monthly-plan')
                        }}</ion-label>
                        <div class="row-content ion-margin-top">
                            <ion-label class="card-title"
                                >{{ pa.product.price_string }}/{{
                                    pa.packageType === 'ANNUAL' ? $t('year') : $t('month')
                                }}</ion-label
                            >
                            <ion-label v-if="pa.packageType === 'ANNUAL'"
                                >${{ calPricePerMonth(pa) }}/{{ $t('month') }}</ion-label
                            >
                        </div>
                        <div class="button-cont">
                            <ion-button
                                class="ion-margin-top ion-text-capitalize"
                                :class="{ 'btn-highlight': pa.packageType === 'ANNUAL' }"
                                @click="purchases(pa)"
                                >{{ $t('upgrade-pre') }}</ion-button
                            >
                        </div>
                    </div>
                    <ion-img :src="bestSaleIcon" v-if="pa.packageType === 'ANNUAL'" class="best-sale-icon"></ion-img>
                </ion-card>
            </div>
            <div v-else class="down-text-cont">
                <ion-text color="secondary" class="download-text">
                    <h4>Download our mobile app to subscribe for premium features</h4>
                </ion-text>
                <div class="img-cont">
                    <ion-img @click="gotoAppStore('ios')" :src="downloadAppleLogoImg" class="logo-img"></ion-img>
                    <ion-img @click="gotoAppStore('android')" :src="downloadAndroidLogoImg" class="logo-img"></ion-img>
                </div>
            </div>
            <div class="document-cont">
                <ion-label class="card-title-style ion-text-capatilize">{{ $t('pre-benefit') }}</ion-label>
                <ion-text class="text-body2-style">{{ $t('The extras you will get') }}</ion-text>
                <br>
                <ion-list class="list-content">
                    <ion-text class="text-body2-style feature-group">{{ $t('smartcard notifications for when') }}(*):</ion-text>
                    <div class="item">
                        <ion-icon :icon="ellipse" class="bullet"></ion-icon>
                        <ion-text class="text-body2-style">{{
                            $t('your child taps on or off a bus')
                        }}</ion-text>
                    </div>
                    <div class="item">
                        <ion-icon :icon="ellipse" class="bullet"></ion-icon>
                        <ion-text class="text-body2-style">{{
                            $t('your child does not tap on or off a bus')
                        }}</ion-text>
                    </div>
                    <div class="item">
                        <ion-icon :icon="ellipse" class="bullet"></ion-icon>
                        <ion-text class="text-body2-style">{{
                            $t('a registered smartcard has missed their bus')
                        }}</ion-text>
                    </div>
                    <div class="item">
                        <ion-icon :icon="ellipse" class="bullet"></ion-icon>
                        <ion-text class="text-body2-style">{{
                            $t('Boards a bus not assigned to their smartcard')
                        }}</ion-text>
                    </div>
                    <div class="item">
                        <ion-icon :icon="ellipse" class="bullet"></ion-icon>
                        <ion-text class="text-body2-style">{{ $t('Disembarks at a non-approved location') }}</ion-text>
                    </div>
                </ion-list>

                <ion-text class="text-body2-style feature-group">{{ $t('premium-more-features') }}</ion-text>
                <ion-list class="list-content">
                    <div class="item">
                        <ion-icon :icon="ellipse" class="bullet"></ion-icon>
                        <ion-text class="text-body2-style">{{
                            $t('Informs you where you child is in Live Time if tapped onto a route')
                        }}</ion-text>
                    </div>
                    <div class="item">
                        <ion-icon :icon="ellipse" class="bullet"></ion-icon>
                        <ion-text class="text-body2-style">{{
                            $t(
                                'Live Bus Load (number of people on the bus) Information (if your Operator allows) If user tracks the vehicle and presses on the pin it will also tell them the number of people on the bus'
                            )
                        }}</ion-text>
                    </div>
                    <div class="item">
                        <ion-icon :icon="ellipse" class="bullet"></ion-icon>
                        <ion-text class="text-body2-style">{{
                            $t('Route Map Overlay (if your Operator uses this feature)')
                        }}</ion-text>
                    </div>
                    <div class="item">
                        <ion-icon :icon="ellipse" class="bullet"></ion-icon>
                        <ion-text class="text-body2-style">{{
                            $t('Link to Bus Operators Media Page (if Operator has this setup)')
                        }}</ion-text>
                    </div>
                </ion-list>

                <ion-text class="text-body2-style feature-group">(*) {{ $t('premium-old-version-note') }}</ion-text>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { Purchases } from '@ionic-native/purchases'
import { Capacitor } from '@capacitor/core'
import { ellipse } from 'ionicons/icons'
import inAppPurchaseApi from '../../util/apis/inAppPurchase'
import appService from '../../util/services/appService'
import localStorageSrv from '../../util/services/storageService'
import alertSrv from '../../util/services/alertService'
import analyticSrv from '../../util/services/analyticSrv'
import store from '../../store'

export default {
    name: 'PremiumPackage',
    props: {
        packages: Array,
        onPurchased: Function,
    },
    data: function() {
        return {
            ellipse,
            bestSaleIcon: require('../../assets/best-premium-sale.png'),
            downloadAppleLogoImg: require('../../assets/download-apple.svg'),
            downloadAndroidLogoImg: require('../../assets/download-android.svg'),
            platform: Capacitor.getPlatform(),
        }
    },
    computed: {
        initPackages() {
            return this.packages
        },
    },
    methods: {
        calPricePerMonth(pack) {
            const packagePerMonth = pack.product.price / 12
            return Math.round(packagePerMonth * 100) / 100
        },
        gotoAppStore(type) {
            if (type == 'ios') {
                window.open('https://apps.apple.com/vn/app/transportme-passenger/id1024907105');
            } else {
                
                window.open('https://play.google.com/store/apps/details?id=com.transportmeptyltd.transportmepassenger');
            }
        },
        async purchases(pa) {
            try {
                await appService.presentLoading('Processing Order...')
                localStorageSrv.setItem('didSetupPurchase', 'true')
                const { purchaserInfo } = await Purchases.purchasePackage(pa)
                await appService.dismissLoading()

                if (
                    purchaserInfo.entitlements.active &&
                    purchaserInfo.entitlements.active.premium &&
                    purchaserInfo.entitlements.active.premium !== 'undefined'
                ) {
                    await appService.presentLoading('Verifying Payment...')
                    localStorageSrv.removeItem('didSetupPurchase')
                    if (await inAppPurchaseApi.verifyUserPurchase()) {
                        this.onPurchased()
                    }
                } else {
                    analyticSrv.logError(`IAP can't process purchase order`, {
                        userId: store.getters.user.user_hash_id,
                        purchaserInfo: purchaserInfo,
                    })
                    alertSrv.toastWarning('Appstore is unable to process your order. Please try again later')
                }
            } catch (e) {
                if (!e.userCancelled) {
                    console.error(e)
                    analyticSrv.logError(`IAP unknown error`, e)
                    appService.presentAlert({
                        header: 'Order Processing Error',
                        message: e.error.message,
                        buttons: ['CANCEL'],
                    })
                } else {
                    localStorageSrv.removeItem('didSetupPurchase')
                }
            } finally {
                appService.dismissLoading()
            }
        },
    },
}
</script>
<style scoped>
.column-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
}
ion-icon {
    font-size: 8px;
    margin-right: 10px;
    color: var(--ion-color-medium);
}

.list-content .item {
    display: flex;
    margin-bottom: 20px;
}

.row-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.document-cont {
    padding: 0 18px 18px 18px;
    display: flex;
    flex-direction: column;
}
.best-sale-icon {
    width: 66px;
    height: 64px;
    position: absolute;
    top: -20px;
    right: 10px;
}
.card-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
}
.normal {
    background: white;
    color: black;
}
.highlight {
    background: var(--ion-color-tertiary);
    color: white;
}
ion-card-content {
    padding: 0;
    z-index: -1;
}
ion-card {
    position: relative;
    overflow: visible;
}
ion-button {
    --background: none;
    border-radius: 4px;
    border: 1px solid var(--ion-color-tertiary);
    width: 50%;
    font-weight: 500;
    --color: var(--ion-color-tertiary);
    font-size: 14px;
    line-height: 16px;
}
.button-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.btn-highlight {
    color: #ffe600;
    border-color: #ffe600;
}
.bullet {
    min-width: 10px;
    align-self: flex-start;
    margin-top: 5px;
}
.feature-group {
    margin-top: 10px;
    font-weight: bold;
}
.down-text-cont {
    display: flex;
    flex-direction: row;
    padding: 16px;
}
.logo-img {
    width: 150px;
    height: 50px;
}
.img-cont {
    display: flex;
    flex-direction: column;
}
.download-text {
    padding: 16px;
}
ion-img {
    cursor: pointer;
}
</style>
