import useNavigator from '@/composables/useNavigator'
import store from '../../store'
import notificationApi from '../../util/apis/notification'
import appService from '../services/appService'

export const NotificationTypes = Object.freeze({
    BOARDING: 'boarding',
    DEBOARDING: 'deboarding',
    NOT_ALLOWED_STOP: 'notAllowedStop',
    ARRIVED_AT_STOP: 'stopArrived',
    BUS_LATE: 'busLate',
    CARD_TRANSACTION: 'transactions',
    MESSAGE_ANNOUNCEMENT: 'announcement',
})

class NotificationService {
    constructor() {}

    async onNotificationTapped(payload) {
        const { type } = payload
        if (type) {
            useNavigator.goHome()

            setTimeout(() => {
                if (
                    type === NotificationTypes.BOARDING ||
                    type === NotificationTypes.DEBOARDING ||
                    type === NotificationTypes.NOT_ALLOWED_STOP ||
                    type === NotificationTypes.ARRIVED_AT_STOP ||
                    type === NotificationTypes.BUS_LATE
                ) {
                    this.handleTypeLiveTracking(payload)
                }
    
                if (type === NotificationTypes.CARD_TRANSACTION) {
                    this.handleTypeCardTransaction(payload)
                }
    
                if (type === NotificationTypes.MESSAGE_ANNOUNCEMENT) {
                    this.handleTypeMessageAnnouncement(payload)
                }
            }, 1000)
        }
    }

    handleTypeLiveTracking(payload) {
        useNavigator.goSmartcardTracking({
            notificationType: payload.type,
            cardNumber: payload.card_number,
            companyId: payload.company_id,
            routeId: payload.route_id,
            dbrId: payload.dbr_id,
        })
    }

    handleTypeCardTransaction(payload) {
        store.dispatch('selectSmartCardNumber', payload.card_number)
        store.dispatch('setCompanyXId', payload.company_id)
        useNavigator.goToSmartcardTransactions(payload.card_number, payload.timezone, payload.cardholder_name)
    }

    async handleTypeMessageAnnouncement(payLoad) {
        // call api
        const message = await notificationApi.readOperatorMessage({
            operator_id: parseInt(payLoad.operator_id),
            message_id: payLoad.message_id,
        })
        if (message) {
            appService.presentAlert({
                header: 'Operator Message',
                message: message.data.readOperatorMessage.message,
                buttons: ['OK'],
            })
        }
    }
}

const srv = new NotificationService()
export default srv
