<template>
    <ion-page>
        <simple-header-toolbar :titleName="openMode == 'replace' ? $t('replace-card-uid') : $t('link-card-uid')" :backFunction="navBack"></simple-header-toolbar>
        <ion-content class="ion-padding">
            <button-single-select
                :list="operatorList"
                :selectedItem="selectedOperator"
                :selectItem="selectOperator"
                defaultPlaceholder="Select Operator"
                v-if="openMode !== 'replace'"
            ></button-single-select>

            <div class="item-container container-top">
                <ion-label
                    id="uid-label"
                    aria-hidden="true"
                    :class="
                        platform && platform === 'ios'
                            ? 'ion-text-capitalize label-absolute-top'
                            : 'ion-text-capitalize label-android-absolute-top'
                    "
                    color="medium"
                    position="floating"
                    >{{ $t('card-uid') }}</ion-label
                >
                <input type="text" aria-labelledby="uid-label" v-model="cardUid" v-on:keypress="onInputKeypress($event.key, 'uid')" />
            </div>
            <ion-button
                ref="linkBtn"
                :disabled="doesLoading || (openMode !== 'replace' && !selectedOperator) || !cardUid"
                expand="block"
                @click="addSmartcard"
                class="ion-text-capitalize ion-margin-top add-btn"
                >{{openMode && openMode == 'replace' ? 'Replace' : 'Link'}}</ion-button
            >
        </ion-content>
    </ion-page>
</template>

<script>
import SimpleHeaderToolbar from '../../components/toolbar/SimpleHeaderToolbar.vue'
import ButtonSingleSelect from '../../components/select/ButtonSingleSelect.vue'
import { Capacitor } from '@capacitor/core'
import companyApi from '../../util/apis/company'
import appService from '../../util/services/appService'
import smartcardService from '../../util/services/smartcardService'
import { Keyboard } from '@capacitor/keyboard'

export default {
    name: 'SmartCardUseUid',
    props: {
        openMode: String,
        replaceCard: String,
    },
    components: {
        SimpleHeaderToolbar,
        ButtonSingleSelect,
    },
    data: function () {
        return {
            cardUid: '',
            doesLoading: false,
            operatorList: [],
        }
    },
    computed: {
        platform() {
            return Capacitor.getPlatform()
        },
        selectedOperator() {
            return this.$store.getters.companyXId
                ? this.$store.getters.companyXId
                : this.$store.getters.user.fav_operator_id
        },
    },
    mounted: async function() {
        this.$nextTick(async function() {
            await this.getOperatorList()
        })
        this.deviceBackEventHandler = await appService.addListenerBackBtn(this.goBack)
    },
    async beforeUnmount() {
         await this.removeEventHandler()
    },
    methods: {
        async getOperatorList() {
            const companies = await companyApi.getActiveCompanies()
            this.operatorList = companies.map(com => {
                return {
                    id: com.company_id,
                    value: com.company_id,
                    name: com.name,
                    timezone: com.timezone,
                    privacy_text: com.privacy_text,
                }
            })
        },
        async removeEventHandler() {
            if (this.deviceBackEventHandler) {
                await this.deviceBackEventHandler.remove()
            }
        },
        selectOperator(companyId) {
            this.$store.dispatch('setCompanyXId', companyId)
        },
        navBack() {
            this.$router.go(-1)
        },
        onInputKeypress(key, type) {
            if (key === 'Enter') {
                if (type === 'uid') {
                    if (this.selectedOperator) {
                        this.$refs.linkBtn.$el.click()
                    }
                    Keyboard.hide()
                }
            }
        },
        async authenticationForm() {
            if (!this.cardUid || !this.cardUid.trim().length) {
                await appService.presentAlert({
                    header: 'Invalid card UID',
                    message: 'Please enter card UID',
                })
                return false
            } else {
                return true
            }
        },
        async addSmartcard() {
            try {
                this.doesLoading = true
                if (!(await this.authenticationForm())) {
                    return
                }
                if (this.openMode && this.replaceCard && this.openMode == 'replace') {
                    const card = JSON.parse(this.replaceCard)
                    smartcardService.replaceCard(this.cardUid, card)
                } else {
                    smartcardService.linkSmartcard(this.cardUid)
                }
            } catch (err) {
                console.log('link card UID error: ', err)
            } finally {
                this.doesLoading = false
            }
        },
    },
}
</script>
<style scoped>
.item-container {
    border: 1px solid #dfdfdf;
    position: relative;
    padding: 0 12px;
    margin-top: 24px;
    border-radius: 4px;
}
.main-logo {
    margin-top: 20px;
}
.add-btn {
    color: white;
    --border-radius: 4px;
}
.forget-text {
    font-family: Verdana;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.15px;
    color: #000000;
    text-align: right;
    cursor: pointer;
}

input {
    height: 60px;
    border: none;
    width: 100%;
    outline: none;
}
</style>
