import store from "../../store";
import smartcardApi from "../apis/smartcard";
import { modalController } from '@ionic/vue'
import CreateSmartcardPasswordModal from '../../views/modal/CreateSmartcardPasswordModal'
import router from '@/router'
import appService from '../services/appService'

class SmartcardService {
    constructor() {
    }

    setCardsToStore(cards) {
        const storeSmartcards = store.getters.smartcards;
        if (storeSmartcards && cards && storeSmartcards.length !== cards.length) {
            store.dispatch("setSmartCards", cards);
        }
    }


    prepareSmartcards(forceRefresh = false) {
        const smartcards = store.getters.smartcards;
        if (!smartcards || smartcards.length === 0 || forceRefresh) {
            return smartcardApi.getSmartCardSaved({ hasAutoTopupConfig: true, hasNotificationConfig: true }).then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.smartCards &&
                    response.data.smartCards.length > 0
                ) {
                    const cards = response.data.smartCards;
                    store.dispatch("setSmartCards", cards);
                }
            });
        }
    }

    async linkSmartcard(cardUid) {
        if (await smartcardApi.isSmartcardInactive(cardUid)) {
            const modal = await modalController.create({
                component: CreateSmartcardPasswordModal,
                cssClass: 'create-smartcard-password-class',
                componentProps: {
                    qrText: cardUid,
                },
            })
            return modal.present()
        } else {
            router.replace({
                name: 'smartCardAddNew',
                params: {
                    openMode: 'qrScan',
                    qrText: cardUid,
                },
            })
        }
    }

    async replaceCard(cardUid, card) {
        // set smartcard companyId to store
        store.dispatch('setCompanyXId', card.company.company_id)
        if ((await smartcardApi.checkSmartcardReplaceStatus(cardUid)) == 'unassigned') {
            smartcardApi
                .replaceSmartcard(cardUid, card)
                .then(async (res) => {
                    if (res.data && !res.loading) {
                        const replaceCard = {
                            id: res.data.replaceSmartcard.id,
                            card_number: cardUid,
                            user_consumer_id: card.user_consumer_id,
                        }
                        store.dispatch("replaceSmartcard", replaceCard);
                        router.go(-1)
                    }
                })
                .catch(err => {
                    appService.showAlert(err.message, 'Error')
                })
        } else {
            await appService.presentAlert({
                header: 'Error',
                message: 'The card has been assigned to another user. Please use an unassigned card',
            })
        }
    }
    async authenticationForm(form) {
        let cardValid = true
        if (!form.userName && !form.password) {
            await appService.presentAlert({
                header: 'Link Smartcard',
                message: 'Please enter smartcard username and password',
            })
            cardValid = false
        } else if (!form.userName) {
            await appService.presentAlert({
                header: 'Link Smartcard',
                message: 'Please enter smartcard username.',
            })
            cardValid = false
        } else if (!form.password) {
            await appService.presentAlert({
                header: 'Link Smartcard',
                message: 'Please enter smartcard password.',
            })
            cardValid = false
        }
        return cardValid
    }
}

const srv = new SmartcardService();
export default srv;
