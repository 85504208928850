import apolloClient from "../../apolloClient";
import { GET_COMPANIES } from "../../graphql/queries/company";



class CompanyApi {
    constructor() { }
    // queries 
   
    // mutations
    async getActiveCompanies() {
        return (await apolloClient.query({
            query: GET_COMPANIES,
            fetchPolicy: "no-cache",
        })).data.activeCompanies;
    }

}

const srv = new CompanyApi();
export default srv;
