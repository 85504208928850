<template>
    <ion-header>
        <ion-toolbar mode="ios">
            <ion-buttons slot="start" v-if="showBackBtn || backFunction">
                <ion-button @click="goBack" aria-label="Go Back">
                    <ion-icon aria-hidden="true" name="chevron-back-outline" class="no-zoom-el arrow-back-icon"></ion-icon>
                </ion-button>
            </ion-buttons>
            <ion-title class="ion-text-capitalize" v-if="titleName"> 
                <ion-text>
                    <span role="heading" class="no-zoom-el no-margin" id="top-header-title" tabindex="-1" ref="headerTitle">{{ titleName }}</span>
                </ion-text>
            </ion-title>
            <ion-buttons slot="end" v-if="showHomeBtn" class="home-btn">
                <ion-button @click="goHome" aria-label="go home">
                    <ion-icon name="home"></ion-icon>
                </ion-button>
            </ion-buttons>
            <ion-buttons slot="end" v-if="showDoneBtn" class="home-btn" @click="onDoneClick">
                <span class="done-txt no-zoom-el">Done</span>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
</template>

<script>
export default {
    name: 'SimpleHeaderToolbar',
    props: {
        titleName: String,
        showHomeBtn: Boolean,
        showDoneBtn: Boolean,
        showBackBtn: Boolean,
        skipDefaultFocus: Boolean,
        done: Function,
        backFunction: Function,
    },
    methods: {
        setDefaultFocus(callback) {
            setTimeout(() => {
                callback && callback();
                this.$refs.headerTitle.focus();
            }, 100)
        },
        goBack() {
            if (this.backFunction) {
                this.backFunction()
            } else {
                this.$router.back()
            }
        },
        goHome() {
            this.$router.replace({ name: 'Home' })
        },
        onDoneClick() {
            this.done()
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ion-toolbar {
    --color: white;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
}
.home-btn {
    margin-right: 8px;
    color: white;
}
ion-button .done-txt {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
}
ion-title {
    text-align: center;
}
ion-icon {
    cursor: pointer;
}
.arrow-back-icon {
    color: white;
}
#top-header-title:focus {
    outline: none;
}
</style>
