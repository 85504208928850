<template>
    <div class="fav-routes-container">
        <div class="item-container">
            <ion-searchbar
                ref="searchLiveTrackingText"
                spellcheck="false"
                role="search"
                type="text"
                :placeholder="'Search Routes'"
                v-model="searchTerm"
            ></ion-searchbar>
        </div>
        <ion-content class="list-container">
            <ion-list v-if="user && filterList.length > 0" class="list-routes">
                <RecycleScroller class="scroller no-zoom-el" :items="filterList" :item-size="47" key-field="id" v-slot="{ item }">
                    <ion-item
                        mode="ios"
                        :key="item.id"
                        v-if="item.type"
                        v-bind:class="{ 'divider-empty': item.type == 'divider', header: item.type == 'header' }"
                    >
                        <div class="item-divider-ctn" v-if="item.type == 'header'" slot="start">
                            <ion-label>{{ item.title }} ({{ item.count }})</ion-label>
                        </div>
                        <div class="item-divider-empty" v-if="item.type == 'divider'"></div>
                    </ion-item>
                    <ion-item
                        button
                        detail="false"
                        v-else
                        :key="item.id"
                        @click="onItemListClick($event, item)"
                        class="item-route"
                        :color="!checkBoldTextTrackingMode(item) ? 'normal' : 'tertiary'"
                    >
                        <div
                            class="active-route-content"
                            :style="{ maxWidth: `calc(100vw - ${getIconsWidth(item)}px)` }"
                        >
                            <ion-label
                                class="text-label"
                                v-bind:class="{
                                    'text-normal': !checkBoldTextTrackingMode(item),
                                    'text-white': checkBoldTextTrackingMode(item),
                                }"
                                :aria-label="checkBoldTextTrackingMode(item) ? `${item.name}. Selected.` : item.name"
                                >{{ item.name }}
                            </ion-label>
                            <ion-text v-if="item.bus_id" class="bus-text">Bus Number: {{ item.bus_number }}</ion-text>
                        </div>
                        <div slot="end">
                            <ion-icon
                                class="icon-ac-route"
                                v-if="item.is_route_end == false"
                                :src="busOutline"
                                aria-label="bus is running"
                            ></ion-icon>
                            <ion-icon
                                class="icon-ac-route"
                                v-if="item.wheelchair_check"
                                :src="wheelChair"
                                aria-label="wheel chair"
                            ></ion-icon>
                            <ion-icon
                                class="icon-ac-route"
                                v-if="item.ac_check"
                                :src="snowOutline"
                                aria-label="air conditioner"
                            ></ion-icon>
                            <ion-icon role="button" aria-hidden="true" slot="icon-only" :src="chevronForwardOutline"></ion-icon>
                        </div>
                    </ion-item>
                </RecycleScroller>
            </ion-list>
            <ion-text color="primary" v-else class="no-search-text" role="alert">
                <h2>No results found</h2>
            </ion-text>
        </ion-content>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import { snowOutline, busOutline, chevronForwardOutline } from 'ionicons/icons'
import { modalController } from '@ionic/vue'
import NotificationStops from '../../views/modal/NotificationStopsModal.vue'

export default {
    name: 'FavoriteListTracking',
    props: {
        isHiddenCheckBtn: Boolean,
        isHiddenNavBtn: Boolean,
        isHiddenSelectBtn: Boolean,
        mode: String,
        selectMode: String,
        dataList: Array,
        hideLoading: Function,
        showLoading: Function,
        routeMode: String,
        operatorId: Number,
    },
    data: function () {
        return {
            heartIcon: require('../../assets/heart.svg'),
            heartOutLineIcon: require('../../assets/heart-outline.svg'),
            checkmarkOutline: require('../../assets/checkmark-outline.svg'),
            wheelChair: require('../../assets/Wheelchair.svg'),
            searchTerm: '',
            labelRouteTextColor: 'normal',
            snowOutline,
            busOutline,
            chevronForwardOutline,
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        computedMode() {
            return this.mode
        },
        route_ids() {
            return this.user.favorite_routes
        },
        filterList() {
            const filterList = this.dataList.filter((route) => {
                return (
                    route.type == 'header' ||
                    route.type == 'divider' ||
                    route.name.toLowerCase().includes(this.searchTerm.toLowerCase())
                )
            })
            return this.checkEmptyList(filterList) ? [] : filterList
        },
    },
    methods: {
        async openNotificationStopModal(route) {
            let modal = await modalController.create({
                component: NotificationStops,
                cssClass: 'notification-stops-modal',
                componentProps: {
                    operatorId: this.operatorId,
                    routeId: route.id,
                },
            });

            // show the modal
            await modal.present()

            // wait to see if i get a response
            let modalResponse = await modal.onDidDismiss()

            if (modalResponse.data && modalResponse.data.backToRoot) {
                modalController.dismiss({
                    backToRoot: true,
                })
            }
        },

        checkEmptyList(list) {
            const foundItem = list.find(item => item.name)
            return Boolean(!foundItem)
        },
        getIconsWidth(item) {
            return ((!item.is_route_end ? 1 : 0) + (item.wheelchair_check ? 1 : 0) + (item.ac_check ? 1 : 0)) * 50 + 30 + 50
        },
        setForcus() {
            this.$refs.searchLiveTrackingText.focus()
        },
        checkBoldTextTrackingMode(fav) {
            const selectedRoute = this.route_ids.find(r => r.route_id == fav.id && r.operator_id == this.operatorId)
            return Boolean(selectedRoute)
        },
        getCheckMark(fav) {
            const trackingRoutes = this.user.favorite_routes
            const operator_id = parseInt(this.operatorId)
            return (
                trackingRoutes &&
                trackingRoutes.length > 0 &&
                this.checkExistTrackRoute({ route_id: fav.id, operator_id })
            )
        },
        checkExistTrackRoute(item) {
            return _.some(this.user.favorite_routes, {
                route_id: item.route_id,
                operator_id: item.operator_id,
            })
        },
        onItemListClick(event, route) {
            this.openNotificationStopModal(route)
        },
    },
}
</script>
<style scoped>
.fav-routes-container {
    position: relative;
    width: 100%;
    height: calc(100% - 44px - env(safe-area-inset-top));
}
.list-container {
    top: 70px;
    height: calc(100% - 70px - 44px);
}
.text-label {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}
ion-label {
    opacity: 0.87;
}
.icon-ac-route {
    margin-right: 5px;
}
.item-container {
    border: 1.5px solid #979797;
    border-radius: 6px;
    margin-left: 20px;
    width: calc(100% - 32px);
    display: flex;
    top: 12px;
    flex-direction: row;
    align-items: center;
    position: absolute;
    padding-bottom: 1px;
}
.item-container input {
    border: none;
    outline: none;
    margin-left: 12px;
    outline-width: 0;
    width: 100%;
    height: 24px;
}
.search-icon {
    font-size: 24px;
}
.note-text {
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    color: var(--ion-color-text-normal-dark);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0.6;
}
.text-normal {
    color: var(--ion-color-text-normal-dark);
}
.text-white {
    color: var(--ion-color-normal-contrast);
    font-weight: bold;
}

.item-route {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
    --min-height: 48px;
    border-bottom: 1px solid #1073ba;
    height: 47px;
}
.header {
    --background: rgba(60, 170, 222, 0.3);
    --inner-border-width: 0;
    color: #121212;
    font-weight: bold;
}
.header .bus-icon {
    color: #121212;
}
.divider-empty {
    --background: white;
    --border-style: none;
}
.bold-style {
    font-weight: bold;
}
.bus-icon {
    font-size: 26px;
}
.icon-heart {
    width: 32px;
    height: 24px;
    margin-right: 12px;
    color: #fd7c25;
}
.item-container input {
    outline: none;
    border: none;
}
ion-icon {
    min-width: 20px;
    min-height: 20px;
}
.opacity-icon {
    opacity: 0.3;
}
ion-searchbar {
    --border-radius: 4px;
    --box-shadow: none;
    padding: 0 0 0 0;
    width: 100%;
    max-height: 40px;
    --background: white;
}
ion-searchbar .searchbar-input-container .searchbar-clear-button {
    width: 20px;
}
.item-divider-ctn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.item-divider-empty {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 20px;
    background-color: white;
}
.scroller-container {
    height: 100%;
}
.list-routes {
    height: 100%;
}
.scroller {
    height: 100%;
}

.user {
    height: 32%;
    padding: 0 12px;
    display: flex;
    align-items: center;
}
.active-route-content {
    flex-direction: column;
    align-items: center;
    max-width: calc(100vw - 130px);
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>
