import apolloClient from '../../apolloClient'
import {
    GET_STRIPE_INFORMATION,
    CHECK_STRIPE_DIRECT_DEBIT,
    STRIPE_DIRECT_DEBIT_CONFIG,
    STRIPE_OPERATOR_CONFIG,
    GET_FRIST_STRIPE_DIRECT_DEBIT,
} from '../../graphql/queries/smartcard'
import {
    SAVE_SET_UP_INTENT,
    UPDATE_SET_UP_INTENT,
    CHARGE_DIRECT,
    PREPARE_STRIPE_SET_UP_INTENT,
} from '../../graphql/mutations/smartcard'
import { VERIFY_USER_PERCHASE, CREATE_USER_PURCHASES } from '../../graphql/mutations/user'

class StripeApi {
    constructor() {}

    getStripeInformation(userConsumerId) {
        return apolloClient.query({
            query: GET_STRIPE_INFORMATION,
            fetchPolicy: 'no-cache',
            variables: {
                userConsumerId,
            },
        })
    }

    async checkStripeDirectDebitInfor() {
        return (
            await apolloClient.query({
                query: CHECK_STRIPE_DIRECT_DEBIT,
                fetchPolicy: 'no-cache',
            })
        ).data.checkStripeDirectDebit
    }

    createUserPurchases(params) {
        return apolloClient.mutate({
            mutation: CREATE_USER_PURCHASES,
            variables: {
                data: params.data,
            },
        })
    }

    verifyUserPurchase() {
        return apolloClient.mutate({
            mutation: VERIFY_USER_PERCHASE,
        })
    }

    directCharge(params) {
        return apolloClient.mutate({
            mutation: CHARGE_DIRECT,
            variables: {
                amount: params.amount,
                user_consumer_id: params.userConsumerId,
                del_payment_method: params.del_payment_method
            },
        })
    }

    stripeDirectDebitConfig(params) {
        return apolloClient.query({
            query: STRIPE_DIRECT_DEBIT_CONFIG,
            fetchPolicy: 'no-cache',
            variables: {
                name: params.name,
                email: params.email,
                paxUserId: params.paxUserId,
            },
        })
    }

    getStripeOperatorConfig() {
        return apolloClient.query({
            query: STRIPE_OPERATOR_CONFIG,
            fetchPolicy: 'no-cache',
        })
    }

    prepareSetupIntent() {
        return apolloClient.mutate({
            mutation: PREPARE_STRIPE_SET_UP_INTENT,
        })
    }
    saveStripeSetupIntent(args) {
        return apolloClient.mutate({
            mutation: SAVE_SET_UP_INTENT,
            variables: {
                ...args,
            },
        })
    }

    updateStripeSetupIntent(args) {
        return apolloClient.mutate({
            mutation: UPDATE_SET_UP_INTENT,
            variables: {
                ...args,
            },
        })
    }
    getFirstStripeDirectDebit() {
        return apolloClient.query({
            query: GET_FRIST_STRIPE_DIRECT_DEBIT,
        })
    }
}

const srv = new StripeApi()
export default srv
