<template>
    <ion-page>
        <simple-header-toolbar :titleName="'Set Password'" :backFunction="goBack"></simple-header-toolbar>

        <ion-content class="ion-padding">
            <div class="item-container container-top">
                <ion-label
                    id="username-label"
                    aria-hidden="true"
                    :class="
                        platform && (platform === 'ios' || platform === 'web')
                            ? 'ion-text-capitalize label-absolute-top'
                            : 'ion-text-capitalize label-android-absolute-top'
                    "
                    color="medium"
                    position="floating"
                    >{{ $t('user-name') }}</ion-label
                >
                <input
                    type="text"
                    aria-labelledby="username-label"
                    v-model="form.userName"
                    disabled
                    v-on:keypress="onInputKeypress($event.key, 'username')"
                />
            </div>

            <div class="item-container">
                <ion-label
                    id="password-label"
                    aria-hidden="true"
                    color="medium"
                    :class="
                        (platform && platform === 'ios') || platform === 'web'
                            ? 'ion-text-capitalize label-absolute-top'
                            : 'ion-text-capitalize label-android-absolute-top'
                    "
                    position="floating"
                    >{{ $t('password') }}</ion-label
                >
                <input
                    aria-labelledby="password-label"
                    ref="userPass"
                    type="password"
                    v-model="form.password"
                    v-on:keypress="onInputKeypress($event.key, 'password')"
                />
            </div>

            <div class="item-container">
                <ion-label
                    id="confirm-pass-label"
                    aria-hidden="true"
                    color="medium"
                    :class="
                        (platform && platform === 'ios') || platform === 'web'
                            ? 'ion-text-capitalize label-absolute-top'
                            : 'ion-text-capitalize label-android-absolute-top'
                    "
                    position="floating"
                    >{{ $t('confirm-pass') }}</ion-label
                >
                <input
                    aria-labelledby="confirm-pass-label"
                    ref="userConfirmPass"
                    type="password"
                    v-model="form.confirmPassword"
                    v-on:keypress="onInputKeypress($event.key, 'confirmPassword')"
                />
            </div>

            <password-rule></password-rule>

            <ion-button
                ref="saveCardPasswordBtn"
                :disabled="doesLoading || disabledSaveButton"
                expand="block"
                @click="save"
                class="ion-text-capitalize ion-margin-top add-btn"
                >{{ $t('save') }}</ion-button
            >
        </ion-content>
    </ion-page>
</template>

<script>
import SimpleHeaderToolbar from '../../components/toolbar/SimpleHeaderToolbar'
import { Capacitor } from '@capacitor/core'
import smartcardApi from '../../util/apis/smartcard'
import appService from '../../util/services/appService'
import { chevronDownOutline } from 'ionicons/icons'
import { modalController } from '@ionic/vue'
import { Keyboard } from '@capacitor/keyboard'
import authSrv from '../../util/services/authService'
import PasswordRule from '../../components/rules/PasswordRule'

export default {
    name: 'CreateSmartcardPasswordModal',
    props: {
        qrText: String,
    },
    components: {
        SimpleHeaderToolbar,
        PasswordRule,
    },
    data: function () {
        return {
            form: {
                userName: '',
                password: '',
                confirmPassword: '',
            },
            smartcardLogo: require('../../assets/logo.png'),
            operator: '',
            chevronDownOutline,
            doesLoading: false,
        }
    },
    mounted: async function () {
        if (!this.qrText) return
        const consumerUserName = await smartcardApi.getSmartcardUserName(this.qrText)
        this.form.userName = consumerUserName.data.getCardUserName || ''
    },
    computed: {
        platform() {
            return Capacitor.getPlatform()
        },
        disabledSaveButton() {
            return !this.form.password || !this.form.confirmPassword
        },
    },
    methods: {
        async save() {
            try {
                this.doesLoading = true
                const password = this.form.password.trim()
                const confirmPassword = this.form.confirmPassword.trim()

                if (!password || !confirmPassword) {
                    await appService.presentAlert({
                        header: 'Error',
                        message: 'Please enter smatcard password and confirm password',
                    })
                    return
                }

                if (password !== confirmPassword) {
                    await appService.presentAlert({
                        header: 'Error',
                        message: 'The password and confirmation password do not match',
                    })
                    return
                }

                if (!authSrv.validatePassword(password)) {
                    appService.showAlert(
                        'Password must include at least 8 characters, 1 capitalize letter, 1 lowercase letter and 1 number',
                        'Invalid password'
                    )
                    return
                }
                const registerCard = await smartcardApi.registerCard({
                    userName: this.form.userName,
                    password: this.form.password,
                    cardNumber: this.qrText,
                })
                this.doesLoading = false
                if (registerCard && registerCard.data && registerCard.data.registerCard) {
                    const role = await appService.presentAlert({
                        header: 'Link Smartcard',
                        message: 'Smartcard was linked successfully!',
                        buttons: [
                            {
                                text: 'OK',
                                role: 'ok',
                            },
                        ],
                    })
                    if (role == 'ok') {
                        this.goBack()
                        this.$store.dispatch('addSmartCard', registerCard.data.registerCard)
                        this.$router.replace({ name: 'smartCardList' })
                    }
                } else {
                    await appService.presentAlert({
                        header: 'Warning',
                        message: 'Register smartcard failed',
                    })
                }
            } finally {
                this.doesLoading = false
            }
        },
        goBack() {
            modalController.dismiss()
        },
        onInputKeypress(key, type) {
            if (key === 'Enter') {
                if (type === 'username') {
                    this.$refs.userPass.focus()
                    this.$refs.userPass.select()
                } else if (type === 'password') {
                    this.$refs.userConfirmPass.focus()
                    this.$refs.userConfirmPass.select()
                } else if (type === 'confirmPassword') {
                    this.$refs.saveCardPasswordBtn.$el.click()
                    Keyboard.hide()
                }
            }
        },
        modalCloseHandler(comSelected) {
            this.operator = comSelected
            this.$store.dispatch('setCompanyXId', comSelected.company_id)
        },
    },
}
</script>
<style scoped>
.item-container {
    border: 1px solid #dfdfdf;
    position: relative;
    padding: 0 12px;
    margin-top: 24px;
    border-radius: 4px;
}
.operator-container {
    display: flex;
    flex-direction: row;
    height: 60px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #dfdfdf;
    justify-content: flex-start;
    align-items: center;
    padding: 0 12px;
    margin-top: 40px;
}
.container-top {
    margin-top: 10%;
}
.main-logo {
    margin-top: 40px;
}
.add-btn {
    color: white;
    --border-radius: 4px;
}
input {
    height: 60px;
    border: none;
    width: 100%;
    outline: none;
}
</style>
