import gql from 'graphql-tag'

export const UPDATE_USER_NOTIFICATION_CONFIG = gql`
    mutation updateUserNotificationConfig($all_card_send_notification: Boolean!, $notification_from_cards: Boolean!) {
        updateUserNotificationConfig(
            all_card_send_notification: $all_card_send_notification
            notification_from_cards: $notification_from_cards
        ) {
            id
            card_number
            consumer {
                id
                cardholder_name
                user_name
            }
            balance
            amount
            allow_notifications
            smart_card_type
            expire_date
            notification_status
            company {
                company_id
                name
                timezone
            }
            auto_topup_config {
                id
                amount
                trigger_balance
            }
            notification_timeframes {
                clock_type
                from
                to
                day_of_week
                card_number
            }
            notification_options {
                card_number
                notify_on_tapped
                notify_on_not_tapped
                notify_on_wrong_stop
            }
            is_smartcard_onboard
            boarding_route_id
            boarding_dbr_id
            user_consumer_id
        }
    }
`

export const CREATE_NOTIFICATION_OPTION = gql`
    mutation createNotificationOption($option_type: String!, $user_consumer_id: Int) {
        createNotificationOption(option_type: $option_type, user_consumer_id: $user_consumer_id) {
            message
            status
        }
    }
`

export const SAVE_NOTIFICATION_TIMEFRAME = gql`
    mutation saveNotificationTimeframe($timeframesJson: String!) {
        saveNotificationTimeframe(
            timeframesJson: $timeframesJson
        ) {
            message
            status
        }
    }
`

export const SAVE_NOTIFICATION_OPTION = gql`
    mutation saveNotificationOption(
        $notify_on_tapped: Boolean
        $notify_on_not_tapped: Boolean
        $notify_on_wrong_stop: Boolean
        $user_consumer_id: Int
    ) {
        saveNotificationOption(
            notify_on_tapped: $notify_on_tapped
            notify_on_not_tapped: $notify_on_not_tapped
            notify_on_wrong_stop: $notify_on_wrong_stop
            user_consumer_id: $user_consumer_id
        ) {
            message
            status
        }
    }
`

export const SET_CARD_RECEIVE_NOTIFICATION = gql`
    mutation setCardReceiveNotification($user_consumer_id: Int, $receive_notification: Boolean!) {
        setCardReceiveNotification(user_consumer_id: $user_consumer_id, receive_notification: $receive_notification) {
            message
            status
        }
    }
`

export const SET_SMARTCARD_LOW_BALANCE_RECEIVE_NOTI = gql`
    mutation setSmartcardLowBalanceReceiveNotification($receive_notification: Boolean!) {
        setSmartcardLowBalanceReceiveNotification(receive_notification: $receive_notification) {
            message
            status
        }
    }
`

export const READ_OPERATOR_MESSAGE = gql`
    mutation readOperatorMessage($operator_id: Int!, $message_id: Int!) {
        readOperatorMessage(operator_id: $operator_id, message_id: $message_id) {
            id
            message
            sent_on
            read_count
        }
    }
`

export const SET_BUS_DELAYS_NOTIFICATION = gql`
    mutation setBusDelayNotification($receive_notification: Boolean!) {
        setBusDelayNotification(receive_notification: $receive_notification) {
            message
            status
        }
   }
`
