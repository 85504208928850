<template>
    <ion-page>
        <!-- Listen to before and after tab change events -->
        <ion-content>
            <ion-tabs>
                <ion-tab-bar slot="bottom" v-if="!isKeyboarShowing">
                    <ion-tab-button ref="tabHome" tab="Home" href="/main/home" aria-labelledby="lblHome">
                        <ion-icon :icon="home"></ion-icon>
                        <ion-label id="lblHome">Home</ion-label>
                    </ion-tab-button>

                    <ion-tab-button ref="tabFavorite" tab="Favorite" href="/main/favorite" aria-labelledby="lblFavorite">
                        <ion-icon :icon="heart"></ion-icon>
                        <ion-label id="lblFavorite">Favourite</ion-label>
                    </ion-tab-button>

                    <ion-tab-button ref="tabSettings" tab="Settings" href="/main/settings" aria-labelledby="lblSettings">
                        <ion-icon :icon="settings"></ion-icon>
                        <ion-label id="lblSettings">Settings</ion-label>
                    </ion-tab-button>

                    <ion-tab-button ref="tabHelp" tab="Help" @click="openBeacon" aria-labelledby="lblHelp">
                        <ion-icon :icon="helpCircle" class="icon-help"></ion-icon>
                        <ion-label id="lblHelp">Help</ion-label>
                    </ion-tab-button>
                </ion-tab-bar>
            </ion-tabs>
        </ion-content>
    </ion-page>
</template>

<script>
import { defineComponent, onMounted, onBeforeUnmount, ref } from 'vue';

import { home, heart, settings, helpCircle } from 'ionicons/icons'
import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import appService from '../util/services/appService'
import wcagService from '../util/services/wcagService'

export default defineComponent({
    name: 'Main',
    setup() {
        const isKeyboarShowing = ref(false)
        const tabHome = ref(null);
        const tabFavorite = ref(null);
        const tabSettings = ref(null);
        const tabHelp = ref(null);
        let backBtnEventHandler = null
        const onKeyboardWillShow = info => {
            console.log('keyboard will show with height', info.keyboardHeight)
            isKeyboarShowing.value = true
        }

        const onKeyBoardDidHide = () => {
            console.log('keyboard did hide')
            isKeyboarShowing.value = false
        }

        const checkKeyboard = () => {
            if (Capacitor.getPlatform() == 'web') {
                return
            }
            Keyboard.addListener('keyboardWillShow', onKeyboardWillShow)
            Keyboard.addListener('keyboardDidHide', onKeyBoardDidHide)
        }

        onMounted(async () => {
            checkKeyboard()
            backBtnEventHandler = await appService.addListenerBackBtn(() => {
                window.Beacon('close')
            })
            setTimeout(() => {
                if (tabHome.value && tabHome.value.$el) {
                    wcagService.handleTabButton(tabHome.value.$el)
                    wcagService.handleTabButton(tabFavorite.value.$el)
                    wcagService.handleTabButton(tabSettings.value.$el)
                    wcagService.handleTabButton(tabHelp.value.$el)
                }
            }, 1000)
        })
        onBeforeUnmount(async () => {
            if (Capacitor.getPlatform() == 'web') {
                return
            }
            Keyboard.removeListener('keyboardWillShow', onKeyboardWillShow)
            Keyboard.removeListener('keyboardDidHide', onKeyBoardDidHide);
            if (backBtnEventHandler) {
                await backBtnEventHandler.remove()
            }
        })

        function openBeacon() {
            window.Beacon('open')
        }

        return {
            home,
            checkKeyboard,
            isKeyboarShowing,
            openBeacon,
            heart,
            settings,
            helpCircle,
            tabHome, tabFavorite, tabSettings, tabHelp
        }
    },
})
</script>
<style scoped>
ion-icon {
    width: 24px;
    height: 24px;
}
.icon-help {
    margin-top: 2px;
    margin-bottom: 0px;
    width: 30px;
    height: 30px;
}
</style>
