<template>
    <ion-page>
        <ion-progress-bar type="indeterminate" v-if="loading"></ion-progress-bar>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="backToSearchRoutes" aria-label="Go Back">
                        <ion-icon
                            aria-hidden="true"
                            slot="icon-only"
                            name="chevron-back-outline"
                            class="arrow-back-icon no-zoom-el"
                        ></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title class="ion-text-capitalize ion-text-center no-zoom-el"
                    ><span ref="headerTitle" role="heading">Select stops</span></ion-title
                >
                <ion-buttons slot="end">
                    <ion-button @click="showInstruction" aria-label="Open instruction">
                        <ion-icon
                            slot="icon-only"
                            :icon="informationCircleOutline"
                            class="no-zoom-el search-icon"
                            aria-hidden="true"
                        ></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <div class="fav-routes-container">
            <div class="item-container">
                <ion-searchbar
                    ref="searchLiveTrackingText"
                    spellcheck="false"
                    role="search"
                    type="text"
                    :placeholder="'Search Stops'"
                    v-model="searchTerm"
                ></ion-searchbar>
            </div>
            <ion-content class="list-container">
                <ion-list v-if="user && filterList.length > NUMBER_OF_MIN_LENGTH_LIST" class="list-routes" role="listbox" aria-multiselectable="true">
                    <RecycleScroller
                        class="scroller no-zoom-el"
                        :items="filterList"
                        :item-size="47"
                        key-field="route_stop_id"
                        v-slot="{ item }"
                    >
                        <ion-item
                            mode="ios"
                            :key="item.id"
                            v-if="item.type"
                            v-bind:class="{ 'divider-empty': item.type == 'divider', header: item.type == 'header' }"
                        >
                            <div class="item-divider-ctn" v-if="item.type == 'header'" slot="start">
                                <ion-label>{{ item.title }} ({{ item.count }})</ion-label>
                            </div>
                            <div aria-hidden="true" class="item-divider-empty" v-if="item.type == 'divider'"></div>
                        </ion-item>
                        <ion-item
                            role="option"
                            :aria-selected="item.tracked?'true': null"
                            @click="onClickStop($event, item)"
                            detail="false"
                            v-else
                            :key="item.id"
                            class="item-route"
                            lines="none"
                            :color="!checkBoldTextTrackingMode(item) ? 'normal' : 'tertiary'"
                        >
                            <div class="active-route-content">
                                <ion-label
                                    class="text-label"
                                    v-bind:class="{
                                        'text-normal': !checkBoldTextTrackingMode(item),
                                        'text-white': checkBoldTextTrackingMode(item),
                                    }"
                                    >{{ getStopName(item) }}
                                </ion-label>
                                <ion-text v-if="item.departure_time" class="bus-text">
                                    <span role="timer">Departure Time: {{ sliceTime(item.departure_time) }}</span>
                                </ion-text>
                            </div>
                        </ion-item>
                    </RecycleScroller>
                </ion-list>
                <ion-text color="primary" v-else class="no-search-text" role="alert">
                    <h2>No results found</h2>
                </ion-text>
            </ion-content>
        </div>
        <ion-button class="ion-text-capitalize track-btn" :disabled="loading" expand="full" @click="trackingTheBus">
            <ion-icon :src="locationIcon"></ion-icon>
            {{ $t('start-tracking') }}</ion-button
        >
    </ion-page>
</template>

<script>
import _ from 'lodash'
import routeApi from '../../util/apis/route'
import favApi from '../../util/apis/favorite'
import appService from '../../util/services/appService'
import { mapState } from 'vuex'
import { modalController } from '@ionic/vue'
import fcmService from '../../util/services/fcmService'
import useNavigator from '@/composables/useNavigator'
import {
  notificationsOutline,
  informationCircleOutline
} from 'ionicons/icons'

let localSelectedStops = null
let localOtherStops = null
const NUMBER_OF_MIN_LENGTH_LIST = 4
export default {
    name: 'NotificationStopsModal',
    props: {
        operatorId: [String, Number],
        routeId: [String, Number],
    },
    data: function () {
        return {
            notificationsOutline,
            trackingRoute: { name: 'Stops' },
            listStops: [],
            loading: false,
            NUMBER_OF_MIN_LENGTH_LIST,
            searchTerm: '',
            locationIcon: require('../../assets/location-icon.svg'),
            informationCircleOutline
        }
    },
    mounted: async function () {
        const operatorRoutes = await routeApi.getCustomTrackingRoutes({
            routes: [
                {
                    operator_id: parseInt(this.operatorId),
                    route_id: parseInt(this.routeId),
                },
            ],
        })
        this.trackingRoute = operatorRoutes ? operatorRoutes[0].trackable_routes[0] : null
        if (this.trackingRoute) {
            console.log('local 1', localSelectedStops)
            localSelectedStops = this.trackingRoute.trackable_stops.filter((s) => s.tracked)
            localOtherStops = this.trackingRoute.trackable_stops.filter((s) => !s.tracked)
            this.listStops = [
                {
                    id: 'header-group',
                    route_stop_id: 'header-group',
                    type: 'header',
                    title: 'Selected Stops',
                    count: localSelectedStops.length,
                },
                ...localSelectedStops,
                {
                    id: 'divider-group',
                    route_stop_id: 'divider-group',
                    type: 'divider',
                },
                {
                    id: 'other-group',
                    route_stop_id: 'other-group',
                    type: 'header',
                    title: 'Other Stops',
                    count: localOtherStops.length,
                },
                ...localOtherStops,
                {
                    id: 'divider-group',
                    route_stop_id: 'divider-group',
                    type: 'divider',
                },
            ]
        }
        console.log('tracking stop', this.listStops)
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        filterList() {
            const filterList = this.listStops.filter((stop) => {
                if (stop.display_name && stop.display_name.trim()) {
                    return (
                        stop.type == 'header' ||
                        stop.type == 'divider' ||
                        stop.display_name.toLowerCase().includes(this.searchTerm.toLowerCase())
                    )
                } else {
                    return (
                        stop.type == 'header' ||
                        stop.type == 'divider' ||
                        stop.name.toLowerCase().includes(this.searchTerm.toLowerCase())
                    ) 
                }
            })
            return filterList
        },
    },
    methods: {
        showInstruction() {
            return appService.presentAlert({
                header: 'Instruction',
                message: `Select one or more stops of route '${this.trackingRoute.name}' to get notified on bus arrival.`,
                buttons: ['OK'],
            })
        },
        sliceTime(time) {
            return time.slice(0, 5)
        },
        getStopName(stop) {
            const name = stop.display_name && stop.display_name.trim() ? stop.display_name : stop.name
            return name
        },
        getBellImg(stop) {
            return stop.tracked ? this.notificationBell : this.notificationBellNone
        },
        backToSearchRoutes() {
            modalController.dismiss()
        },
        dismissModal() {
            modalController.dismiss()
        },
        async onClickStop(event, stop) {
            const isStopBeingTracked = stop.tracked
            stop.tracked = !stop.tracked
            this.loading = true
            try {
                if (isStopBeingTracked) {
                    await this.delStop(stop)
                } else {
                    // Maximum user can track 5 routes
                    if (this.hasTrackedMaxNumberOfRoutes()) {
                        stop.tracked = false
                        await appService.alertSelectRouteTrack()
                        this.loading = false
                        return
                    }
                    // check enable notifications
                    if (await fcmService.registerPush(true)) {
                        await this.saveStops(stop)
                        await this.selectRouteTrack(this.routeId)
                    } else {
                        this.dismissModal()
                        useNavigator.goHome()
                    }
                }
                this.loading = false
            } catch (error) {
                console.log('Set stop notification error', error)
                this.loading = false
            }
        },
        checkBoldTextTrackingMode(stop) {
            return stop.tracked
        },
        async delStop(stop) {
            const delParams = {
                route_stops_id: stop.route_stop_id,
                operator_id: this.operatorId,
            }
            const delRouteStop = await routeApi.delSavedStop(delParams)
            if (delRouteStop && delRouteStop.data && delRouteStop.data.delSavedStop.status === 'Success') {
                stop.tracked = false
            } else {
                stop.tracked = true
            }
        },
        async saveStops(stop) {
            const apiParams = {
                route_stops_id: stop.route_stop_id,
                operator_id: this.operatorId,
            }
            const saveRouteStop = await routeApi.saveRouteStop(apiParams)
            if (saveRouteStop.data.saveRouteStops.status === 'Success') {
                stop.tracked = true
            } else {
                stop.tracked = false
            }
        },
        async saveRouteFavorite(operatorId, routeId) {
            await favApi.saveRouteFavorite({
                operator_id: operatorId,
                route_id: routeId,
            })
        },
        async deleteRouteFavorite(operatorId, routeId) {
            return await favApi.deleteRouteFavorite({
                operator_id: operatorId,
                route_id: routeId,
            })
        },
        async selectRouteTrack(routeId) {
            if (!routeId) {
                appService.presentAlert({
                    header: 'Warning',
                    message: 'Failed select route to live tracking',
                    buttons: ['OK'],
                })
                return
            }
            const cloneFavoriteRoute = _.cloneDeep(this.user.favorite_routes)
            // Double check Maximum user can track 5 routes
            if (this.user.favorite_routes.length > 5) {
                return
            }

            const operator_id = parseInt(this.operatorId)
            const params = {
                operator_id,
                route_id: routeId,
            }
            const routeAddedToFav = this.user.favorite_routes.find(
                (r) => r.operator_id == params.operator_id && r.route_id == params.route_id
            )
            if (!routeAddedToFav) {
                this.$store.dispatch('setRouteTrack', params)
            } 
            try {
                const selectedRoute = cloneFavoriteRoute.find(
                    r => r.operator_id == params.operator_id && r.route_id == params.route_id
                )
                if (!selectedRoute) {
                    await this.saveRouteFavorite(parseInt(this.operatorId), parseInt(routeId))
                }
            } catch (error) {
                console.log('selectRoutesTrack', error.message)
                this.$store.dispatch('setRouteTrack', params)
                appService.presentAlert({
                    header: 'Warning',
                    message: 'Failed select route to live tracking',
                    buttons: ['OK'],
                })
                throw error
            }
        },
        hasTrackedMaxNumberOfRoutes() {
            return this.user.favorite_routes.length > 5 ||
                    (this.user.favorite_routes.length == 5 && 
                    !this.user.favorite_routes.find(r => r.operator_id == this.operatorId && r.route_id == this.routeId))
        },
        async trackingTheBus() {
            // Maximum user can track 5 routes
            if (this.hasTrackedMaxNumberOfRoutes()) {
                appService.alertSelectRouteTrack()
                return
            }
            await this.selectRouteTrack(this.routeId)
            modalController.dismiss({
                backToRoot: true,
            })
        },
    },
}
</script>
<style scoped>
.fav-routes-container {
    position: relative;
    width: 100%;
    height: calc(100% - 44px - env(safe-area-inset-top));
}
.list-container {
    top: 70px;
    height: calc(100% - 70px - 44px);
}
.text-label {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}
ion-label {
    opacity: 0.87;
}
.icon-ac-route {
    margin-right: 5px;
}
.item-container {
    border: 1.5px solid #979797;
    border-radius: 6px;
    margin-left: 20px;
    width: calc(100% - 32px);
    display: flex;
    top: 12px;
    flex-direction: row;
    align-items: center;
    position: absolute;
    padding-bottom: 1px;
}
.item-container input {
    border: none;
    outline: none;
    margin-left: 12px;
    outline-width: 0;
    width: 100%;
    height: 24px;
}
.search-icon {
    font-size: 24px;
}
.note-text {
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    color: var(--ion-color-text-normal-dark);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0.6;
}
.text-normal {
    color: var(--ion-color-text-normal-dark);
}
.text-white {
    color: var(--ion-color-normal-contrast);
    font-weight: bold;
}

.item-route {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
    --min-height: 47px;
    border-bottom: 1px solid #1073ba;
    --max-height: 47px;
    height: 47px;
}
.header {
    --background: rgba(60, 170, 222, 0.3);
    --inner-border-width: 0;
    color: #121212;
    font-weight: bold;
}
.header .bus-icon {
    color: #121212;
}
.divider-empty {
    --background: white;
    --border-style: none;
}
.bold-style {
    font-weight: bold;
}
.bus-icon {
    font-size: 26px;
}
.icon-heart {
    width: 32px;
    height: 24px;
    margin-right: 12px;
    color: #fd7c25;
}
.item-container input {
    outline: none;
    border: none;
}
ion-icon {
    min-width: 20px;
    min-height: 20px;
}
.opacity-icon {
    opacity: 0.3;
}
ion-searchbar {
    --border-radius: 4px;
    --box-shadow: none;
    padding: 0 0 0 0;
    width: 100%;
    max-height: 40px;
    --background: white;
}
ion-searchbar .searchbar-input-container .searchbar-clear-button {
    width: 20px;
}
.item-divider-ctn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.item-divider-empty {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 20px;
    background-color: white;
}
.scroller-container {
    height: 100%;
}
.list-routes {
    height: 100%;
}
.scroller {
    height: 100%;
}

.user {
    height: 32%;
    padding: 0 12px;
    display: flex;
    align-items: center;
}
.active-route-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.track-btn {
    position: absolute;
    bottom: 0px;
    width: 100%;
    color: white;
    margin: 0;
    height: 44px;
}
</style>
