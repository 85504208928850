import apolloClient from "../../apolloClient";
import store from "../../store";
import { GET_SMART_CARD, GET_SMART_CARDS_SAVED, GET_SMART_CARD_TRANSACTIONS, CHECK_SMART_CARD_USERNAME, GET_CARD_USER_NAME } from "../../graphql/queries/smartcard";
import { DEL_SMART_CARD, REGISTER_CARD, REPLACE_CARD, CHECK_SMARTCARD_REPLACE_STATUS, IS_SMARCARD_INACTIVE, SEND_VERIFY_CODE, VERIFY_CODE, CHANGE_PASSWORD, REMOVE_LINKED_CARD } from "../../graphql/mutations/smartcard";

class SmartCardApi {

    constructor() { }

    getSmartCard(form) {
        return apolloClient.query({
            query: GET_SMART_CARD,
            fetchPolicy: "no-cache",
            variables: { userName: form.userName, password: form.password },
        })
    }

    getSmartcardUserName(cardNumber) {
        return apolloClient.query({
            query: GET_CARD_USER_NAME,
            fetchPolicy: "no-cache",
            variables: { cardNumber },
        })
    }

    registerCard(card) {
        return apolloClient.mutate({
            mutation: REGISTER_CARD,
            fetchPolicy: "no-cache",
            variables: {
                userName: card.userName,
                password: card.password,
                cardNumber: card.cardNumber,
            },
        })
    }

    async isSmartcardInactive(cardNumber) {
        return (await apolloClient.mutate({
            mutation: IS_SMARCARD_INACTIVE,
            fetchPolicy: "no-cache",
            variables: { cardNumber },
        })).data.isSmartcardInactive
    }

    async replaceSmartcard(cardNumber, cardReplace) {
        return apolloClient.mutate({
            mutation: REPLACE_CARD,
            fetchPolicy: "no-cache",
            variables: {
                cardNumberNew: cardNumber,
                cardNumberOld: cardReplace.card_number,
            },
        })
    }

    async checkSmartcardReplaceStatus(cardNumber) {
        return (await apolloClient.mutate({
            mutation: CHECK_SMARTCARD_REPLACE_STATUS,
            fetchPolicy: "no-cache",
            variables: { cardNumber },
        })).data.checkSmartcardReplaceStatus
    }

    async delSmartCardSaved(card) {
        await apolloClient.mutate({
            mutation: DEL_SMART_CARD,
            variables: {
                user_consumer_id: card.user_consumer_id,
            }
        });
        store.dispatch("delSmartCard", card.user_consumer_id);
    }

    getSmartCardSaved(params) {
        return apolloClient.query({
            query: GET_SMART_CARDS_SAVED,
            fetchPolicy: "no-cache",
            variables: {
                ...params
            }
        });
    }

    getSmartCardTransaction(params) {
        return apolloClient.query({
            query: GET_SMART_CARD_TRANSACTIONS,
            fetchPolicy: "no-cache",
            variables: {
                ...params
            }
        });
    }

    checkSmartcardUserName(params) {
        return apolloClient.query({
            query: CHECK_SMART_CARD_USERNAME,
            fetchPolicy: "no-cache",
            variables: {
                ...params
            }
        });
    }

    sendVerifyCode(params) {
        return apolloClient.mutate({
            mutation: SEND_VERIFY_CODE,
            fetchPolicy: "no-cache",
            variables: {
                ...params
            }
        });
    }

    verifyCode(params) {
        return apolloClient.mutate({
            mutation: VERIFY_CODE,
            variables: {
                verify_code: params.code,
                user_name: params.user_name
            }
        });
    }

    changePassword(params) {
        return apolloClient.mutate({
            mutation: CHANGE_PASSWORD,
            variables: {
                user_name: params.user_name,
                new_password: params.newPassword,
                confirm_password: params.confirmPassword,
                verifyCode: params.verifyCode
            }
        });
    }

    removeLinkedCard() {
        return apolloClient.mutate({
            mutation: REMOVE_LINKED_CARD,
            fetchPolicy: "no-cache",
        });
    }
}


const srv = new SmartCardApi();
export default srv;