import store from '../../store'
import appService from '../services/appService'

class QRScanService {
    constructor() { }

    subscribeToScanResult(callBack) {
        let cancelling = false
        window.QRScanner.scan((err, textFound) => {
            if (store.getters.showingSelectOperatorHeader && !store.getters.companyXId) {
                if (cancelling) {
                    return
                }
                cancelling = true
                window.QRScanner.cancelScan(() => {
                    appService.showAlert('Please select operator').then(() => {
                        this.subscribeToScanResult(callBack)
                    })
                })
            } else {
                this.exitScan()
                callBack(textFound)
            }
        })
    }

    // eslint-disable-next-line no-unused-vars
    async start(callBack, mode) {
        this.showScanScreen(mode)
        window.QRScanner.prepare(async (err, status) => {
            if (status.authorized) {
                await window.QRScanner.show()
                this.subscribeToScanResult(callBack)
            }
        })
    }

    async showScanScreen(mode) {
        document.getElementById('app-router-outlet').style.display = 'none'
        // change state to render qrcode header
        store.dispatch('setRenderQrcodeHeader', true)
        if (mode && mode == 'replace') {
            store.dispatch('setRenderSelectOperatorHeader', false)
        } else {
            store.dispatch('setRenderSelectOperatorHeader', true)
        }
    }

    async exitScan() {
        await window.QRScanner.hide()
        await window.QRScanner.destroy()
        // change state to render qrcode header
        store.dispatch('setRenderQrcodeHeader', false)
        // when close the qrcode scan screen show screen reader under
        document.getElementById('app-router-outlet').style.display = 'block'
    }
}

const srv = new QRScanService()
export default srv
