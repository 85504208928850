<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start" class="home-btn">
                    <ion-button @click="goBack" aria-label="Go Back">
                        <ion-icon aria-hidden="true" :icon="chevronBackOutline" class="back-btn"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title class="ion-text-capitalize">Smartcard password reset</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <div class="text-body2-style note-text" v-if="!codeVerified">
                {{ $t('card-pwd-reset-email-sent') }}
            </div>
            <div class="item-container" v-if="codeVerified">
                <ion-label
                    id="password-label"
                    aria-hidden="true"
                    :class="
                        platform && platform === 'ios'
                            ? 'ion-text-capitalize label-absolute-top'
                            : 'ion-text-capitalize label-android-absolute-top'
                    "
                    color="medium"
                    position="floating"
                    >{{ $t('new-password') }}</ion-label
                >
                <input type="password" aria-labelledby="password-label" v-model="form.newPassword" />
            </div>

            <div class="item-container" v-if="codeVerified">
                <ion-label
                    id="confirm-pass-label"
                    aria-hidden="true"
                    color="medium"
                    :class="
                        platform && platform === 'ios'
                            ? 'ion-text-capitalize label-absolute-top'
                            : 'ion-text-capitalize label-android-absolute-top'
                    "
                    position="floating"
                    >{{ $t('confirm-pass') }}</ion-label
                >
                <input type="password" aria-labelledby="confirm-pass-label" v-model="form.confirmPassword" />
            </div>

            <div class="item-container" v-if="!codeVerified">
                <ion-label
                    id="confirm-code-label"
                    aria-hidden="true"
                    color="medium"
                    :class="
                        platform && platform === 'ios'
                            ? 'ion-text-capitalize label-absolute-top'
                            : 'ion-text-capitalize label-android-absolute-top'
                    "
                    position="floating"
                    >{{ $t('verify-code') }}</ion-label
                >
                <input
                    aria-labelledby="confirm-code-label" type="number" placeholder="Code" inputmode="decimal" v-model="code" />
            </div>
            <password-rule v-if="codeVerified"></password-rule>
            <ion-button
                expand="block"
                v-if="codeVerified"
                :disabled="doesLoading"
                @click="changePassword"
                class="ion-text-capitalize ion-margin-top add-btn"
                >{{ $t('update-password') }}</ion-button
            >
            <ion-button expand="block" @click="verifyCode" v-else class="ion-text-capitalize ion-margin-top add-btn">{{
                $t('verify')
            }}</ion-button>
        </ion-content>
    </ion-page>
</template>

<script>
import { chevronBackOutline } from 'ionicons/icons'
import PasswordRule from '../../components/rules/PasswordRule'
import { Capacitor } from '@capacitor/core'
import smartcardApi from '../../util/apis/smartcard'
import appService from '../../util/services/appService'
import authSrv from '../../util/services/authService'
import useNavigator from '@/composables/useNavigator'
export default {
    name: 'SmartcardForgetPassword',
    components: {
        PasswordRule,
    },
    data: function () {
        return {
            form: {
                newPassword: '',
                confirmPassword: '',
            },
            code: '',
            codeVerified: false,
            doesLoading: false,
            chevronBackOutline: chevronBackOutline,
            smartcardLogo: require('../../assets/logo.png'),
            platform: Capacitor.getPlatform(),
        }
    },
    methods: {
        async changePassword() {
            const { user_name } = this.$route.params
            const newPassword = this.form.newPassword.trim()
            const confirmPassword = this.form.confirmPassword.trim()
            if (!user_name || !newPassword || !confirmPassword) {
                await appService.presentAlert({
                    header: 'Error',
                    message: 'Please enter and confirm new password',
                })
                return
            }
            if (newPassword !== confirmPassword) {
                await appService.presentAlert({
                    header: 'Error',
                    message: 'The password and confirmation password do not match',
                })
                return
            }

            if (!authSrv.validatePassword(newPassword)) {
                appService.showAlert(
                    'Password must include at least 8 characters, 1 capitalize letter, 1 lowercase letter and 1 number',
                    'Invalid password'
                )
                return
            }
            this.doesLoading = true
            appService.presentLoading()
            const changePassRes = await smartcardApi.changePassword({
                user_name: user_name,
                newPassword: newPassword,
                confirmPassword: confirmPassword,
                verifyCode: this.code,
            })

            if (
                changePassRes &&
                changePassRes.data &&
                changePassRes.data.changeSmartcardPassword.status === 'Success'
            ) {
                this.goBack()
                appService.showAlert(
                    'Smartcard password has been updated. Please link the smartcard with the new updated password.',
                    'Password Reset'
                )
            } else {
                await appService.presentAlert({
                    header: 'Error',
                    message: 'Change password failed',
                })
            }
            this.doesLoading = false
            appService.dismissLoading()
        },
        async verifyCode() {
            try {
                const { user_name } = this.$route.params
                const code = String(this.code)
                if (!code || !user_name) {
                    await appService.presentAlert({
                        header: 'Error',
                        message: 'Invalid verification code',
                    })
                    return
                }
                appService.presentLoading()
                const verifyRes = (await smartcardApi.verifyCode({
                    code: code.trim(),
                    user_name: user_name.trim(),
                })).data.verifyChangeSmartcardPasswordCode
                appService.dismissLoading()
                if (verifyRes.status === 'success') {
                    this.codeVerified = true
                    this.code = verifyRes.token
                } else {
                    this.codeVerified = false
                    await appService.presentAlert({
                        header: 'Error',
                        message: verifyRes.message,
                    })
                }
            } catch (err) {
                appService.dismissLoading()
                await appService.presentAlert({
                    header: 'Warning',
                    message: 'Invalid verification code',
                })
            }
        },
        goBack() {
            useNavigator.goToSmartcard()
        },
    },
}
</script>
<style scoped>
.item-container {
    border: 1px solid #dfdfdf;
    position: relative;
    padding: 0 12px;
    margin-top: 24px;
    border-radius: 4px;
}

.note-text {
    margin-top: 12px;
}
.home-btn {
    margin-left: 8px;
}
.add-btn {
    color: white;
    --border-radius: 4px;
}
.forget-text {
    font-family: Verdana;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.15px;
    color: #000000;
}

input {
    height: 60px;
    border: none;
    width: 100%;
    outline: none;
}
ion-title {
    padding-left: 50px;
    padding-right: 10px;
}
.back-btn {
    color: white;
}
</style>
